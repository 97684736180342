import {API_PATH} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {ComponentManagementSelector} from './selectors';
import {
  componentManagementStateActions,
  createExpenseTypeCreatorTypeName,
  editExpenseTypeCreatorTypeName,
  fetcExpenseCategoryCreatorTypeName,
  fetchAutoSuggestHqCreatorTypeName,
  fetchExpenseTypeActiveInactiveCreatorTypeName,
  fetchExpenseTypeAutoSuggestCreatorTypeName,
  fetchExpenseTypeCreatorTypeName,
  fetchExpenseTypeOptionsCreatorTypeName,
} from './slice';
import {strings} from '@app/common/strings';

/**
 * Employee Details Watcher
 */
export function* fetchExpenseTypeDataWatcher() {
  yield takeLatest(
    fetchExpenseTypeCreatorTypeName,
    fetchExpenseTypeDataWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* fetchExpenseTypeDataWatcherWorker(action: any): any {
  const isUserActive = yield select(
    ComponentManagementSelector.getIsActiveState(),
  );
  const searchData = yield select(
    ComponentManagementSelector.getExpenseTypeInAutoSuggestFilter(),
  );

  try {
    yield put(componentManagementStateActions.setExpenseTypeLoading(true));

    const url = `${API_PATH.componentMaster.expenseTypegrid}`;
    const expenseData = yield call(NetworkService.post, url, {
      isActive: isUserActive,
      searchData: searchData?.expenseName ?? '',
    });
    yield put(
      componentManagementStateActions.setExpenseType(expenseData?.data),
    );
    yield put(
      componentManagementStateActions.setExpenseTypeSnaShot(expenseData?.data),
    );

    yield put(componentManagementStateActions.setExpenseTypeLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      strings.componentManagement.fetchingDataMsg,
      ToastType.ERROR,
    );
  }
}
/**
 * Employee Details Watcher
 */
export function* fetchactiveInactiveExpenseTypeWatcher() {
  yield takeLatest(
    fetchExpenseTypeActiveInactiveCreatorTypeName,
    activeInactiveExpenseTypeWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* activeInactiveExpenseTypeWatcherWorker(action: any): any {
  try {
    const isActive = yield select(
      ComponentManagementSelector.getIsActiveState(),
    );

    const url = `${API_PATH.componentMaster.expenseTypeActiveInactive}`;
    const disableUser = yield call(NetworkService.post, url, {
      id: action?.payload?.expenseTypeID,
    });
    if (disableUser?.data === true) {
      !isActive &&
        ToasterService.showToaster(
          strings.componentManagement.activeExpenseType,
          ToastType.SUCCESS,
        );
      isActive &&
        ToasterService.showToaster(
          strings.componentManagement.inActiveExpenseType,
          ToastType.SUCCESS,
        );

      yield put({type: fetchExpenseTypeCreatorTypeName, payload: {}});
    }
  } catch (error) {
    ToasterService.showToaster(
      strings.componentManagement.activeInactiveExpenseTypeMsg,
      ToastType.ERROR,
    );
  }
}

/**
 * Employee Details Watcher
 */
export function* fetchExpenseCategoryWatcher() {
  yield takeLatest(
    fetcExpenseCategoryCreatorTypeName,
    fetchExpenseCategoryDropdownWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchExpenseCategoryDropdownWatcherWorker(action: any): any {
  try {
    let categoryType = 'ExpenseCategory';
    const url = `${API_PATH.componentMaster.expenseTypeOption}/${categoryType}`;
    const expenseCategory = yield call(NetworkService.get, url, {}, {});
    yield put(
      componentManagementStateActions.setExpenseCategory(expenseCategory?.data),
    );
  } catch (error) {}
}

/**
 * Employee Details Watcher
 */
export function* fetchExpenseTypeOptionsWatcher() {
  yield takeLatest(
    fetchExpenseTypeOptionsCreatorTypeName,
    fetchExpenseTypeOptionsWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchExpenseTypeOptionsWatcherWorker(): any {
  try {
    let categoryType = 'ExpenseTypeOption';
    const url = `${API_PATH.componentMaster.expenseTypeOption}/${categoryType}`;
    const categoryExpenseType = yield call(NetworkService.get, url, {}, {});
    yield put(
      componentManagementStateActions.setExpenseTypeOption(
        categoryExpenseType?.data,
      ),
    );
  } catch (error) {}
}

export function* fetchExpenseTypeAutoSuggestDataWatcher() {
  yield takeLatest(
    fetchExpenseTypeAutoSuggestCreatorTypeName,
    fetchExpenseTypeAutoSuggestDataWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchExpenseTypeAutoSuggestDataWatcherWorker(
  action: any,
): any {
  try {
    yield put(componentManagementStateActions.setExpenseTypeInAutoSuggest([]));

    const isUserActive = yield select(
      ComponentManagementSelector.getIsActiveState(),
    );

    const userSearchText = yield select(
      ComponentManagementSelector.getSearchText(),
    );

    if (userSearchText.length > 0) {
      const url = `${API_PATH.componentMaster.expenseTypeAutoSuggest}`;
      const AutosuggestData = yield call(NetworkService.post, url, {
        isActive: isUserActive,
        searchData: userSearchText,
      });
      yield put(
        componentManagementStateActions.setExpenseTypeInAutoSuggest(
          AutosuggestData?.data,
        ),
      );
    }
  } catch (error) {}
}

export function* fetchAutoSuggestHqDataWatcher() {
  yield takeLatest(
    fetchAutoSuggestHqCreatorTypeName,
    fetchAutoSuggestHqDataWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchAutoSuggestHqDataWatcherWorker(action: any): any {
  try {
    if (action.payload.length > 0) {
      const url = `${API_PATH.componentMaster.autoSuggestHq}`;
      const AutosuggestData = yield call(NetworkService.post, url, {
        searchText: action?.payload,
      });
      yield put(
        componentManagementStateActions.setAutoSuggestHq(AutosuggestData?.data),
      );
    }
  } catch (error) {}
}

/**
 * Employee Details Watcher
 */
export function* createExpenseTyperWatcher() {
  yield takeLatest(
    createExpenseTypeCreatorTypeName,
    createExpenseTypeWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* createExpenseTypeWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.componentMaster.addExpenseType}`;
    const addUser = yield call(NetworkService.post, url, action.payload);
    yield put(
      componentManagementStateActions.setaddExpenseTypePopupVisible(false),
    );
    yield put({type: fetchExpenseTypeCreatorTypeName, payload: {}});
    if (addUser?.data?.isCreated) {
      ToasterService.showToaster(addUser?.data?.message, ToastType.SUCCESS);
    } else {
      ToasterService.showToaster(addUser?.data?.message, ToastType.ERROR);
    }
  } catch (error) {
    ToasterService.showToaster(
      strings.componentManagement.addExpenseTypeMsg,
      ToastType.ERROR,
    );
  }
}

/**
 * Employee Details Watcher
 */
export function* editExpenseTypeWatcher() {
  yield takeLatest(
    editExpenseTypeCreatorTypeName,
    editExpenseTypeWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* editExpenseTypeWatcherWorker(action: any): any {
  try {
    const resetNavbarVersionExpenseType = yield select(
      ComponentManagementSelector.getResetNavbarVersion(),
    );
    const url = `${API_PATH.componentMaster.editExpenseType}`;
    const editData = yield call(NetworkService.post, url, {
      expenseTypeID: action?.payload?.expenseTypeID,
      expenseTypeName: action?.payload?.expenseTypeName,
      expenseType: action?.payload?.expenseType,
      expenseCategory: action?.payload?.expenseCategory,
      hqId: action?.payload?.hqId,
      startDate: new Date(action?.payload?.startDate)?.toLocaleDateString(
        'en-ca',
      ),
      endDate: new Date(action?.payload?.endDate)?.toLocaleDateString('en-ca'),
      isActive: action?.payload?.isActive,
      isBillRequired: action?.payload?.isBillRequired,
    });
    yield put(componentManagementStateActions.setExpenseTypeInAutoSuggest([]));
    yield put(
      componentManagementStateActions.setResetNavbarVersion(
        resetNavbarVersionExpenseType + 1,
      ),
    );
    yield put(
      componentManagementStateActions.setExpenseTypeInAutoSuggestFilter({}),
    );
    yield put(componentManagementStateActions.setSearchText(''));
    yield put(
      componentManagementStateActions.setEditExpenseTypePopupVisible(false),
    );
    yield put({type: fetchExpenseTypeCreatorTypeName, payload: {}});
    if (editData?.data) {
      ToasterService.showToaster(strings.updateSuccessfull, ToastType.SUCCESS);
    } else {
      ToasterService.showToaster(
        strings.componentManagement.updateExpenseTypeMsg,
        ToastType.ERROR,
      );
    }
  } catch (error) {
    yield put(
      componentManagementStateActions.setEditExpenseTypePopupVisible(false),
    );
    ToasterService.showToaster(
      strings.componentManagement.editExpenseTypeMsg,
      ToastType.ERROR,
    );
  }
}
