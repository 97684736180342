import {monthMock} from '@app/components/widgets/placeholder/multiselect-placeholder';
import {MONTH_OBJECT} from '@app/screens/module-specific-utilities/module-specific-utilities.types';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {fetchMonthlyExpenseCreator} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {TableRow, TableCell, Checkbox, IconButton} from '@mui/material';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

export const util: any = {
  handleClick: null,
  isRowSelected: null,
  handleSetOpen: null,
};

export const SelectableCollapsibleRow = (props: any) => {
  const dispatch = useDispatch();
  const {row, collapseIconPadding} = props;
  const [open, setOpen] = useState(false);
  const [iconPadding, setIconPadding] = useState(collapseIconPadding);
  const adminExpenseSelectedRows =
    useSelector(
      approveExpenseStateSelector.getSelectedExpenseApprovalsData(),
    ) ?? [];

  const handleClick = (_event: any, rowParam: any) => {
    const {sapCode, expenseMonth, expenseYear, staffPositionId} = rowParam;
    const selectedIndex = adminExpenseSelectedRows?.indexOf(rowParam);
    let newSelected: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(adminExpenseSelectedRows, rowParam);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(adminExpenseSelectedRows?.slice(1));
    } else if (selectedIndex === adminExpenseSelectedRows?.length - 1) {
      newSelected = newSelected.concat(adminExpenseSelectedRows?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        adminExpenseSelectedRows?.slice(0, selectedIndex),
        adminExpenseSelectedRows?.slice(selectedIndex + 1),
      );
    }
    dispatch(
      approveExpenseStateActions.setSelectedExpenseApprovalsData(newSelected),
    );
    const selectedUser = [
      {
        sapCode,
        year: expenseYear,
        month: expenseMonth,
        staffPositionId,
      },
    ];
    if (_event?.target?.checked && newSelected?.length < 2) {
      dispatch(fetchMonthlyExpenseCreator(selectedUser));
    }
  };

  const isRowSelected = (rowParam: any) => {
    let isSelected = false;
    isSelected = adminExpenseSelectedRows?.indexOf(rowParam) !== -1;

    return isSelected;
  };

  const isItemSelected = isRowSelected(row);

  const handleSetOpen = () => {
    setIconPadding(open ? iconPadding - 2 : iconPadding + 2);
    setOpen(!open);
  };

  util.handleClick = handleClick;
  util.isRowSelected = isRowSelected;
  util.handleSetOpen = handleSetOpen;

  return (
    <>
      {row !== null && (
        <>
          <TableRow key={row?.expenseHistoryId}>
            <TableCell padding="checkbox">
              <Checkbox
                data-testid="selectableCheckbox"
                onClick={(event: any) => handleClick(event, row)}
                color="primary"
                checked={isItemSelected}
              />
            </TableCell>
            <TableCell>
              {row?.subordinates?.length > 0 ? (
                <IconButton
                  size="small"
                  onClick={handleSetOpen}
                  sx={{pl: collapseIconPadding}}>
                  {open && <b>-</b>}
                  {!open && <b>+</b>}
                </IconButton>
              ) : (
                <></>
              )}
            </TableCell>
            <TableCell component="th" scope="row">
              {`${
                (monthMock() || [])
                  ?.find(
                    (month: MONTH_OBJECT) => month?.key === row?.expenseMonth,
                  )
                  ?.value?.substring(0, 3) ?? row?.expenseMonth
              },${row?.expenseYear}`}
            </TableCell>
            <TableCell>{row?.divisionName}</TableCell>
            <TableCell>{row?.adminComment}</TableCell>
            <TableCell>{row?.sapCode}</TableCell>
            <TableCell>{row?.staffName}</TableCell>
            <TableCell>{row?.designationName}</TableCell>
            <TableCell>{row?.hqName}</TableCell>
            <TableCell>{row?.stateName}</TableCell>
            <TableCell>{row?.adminApprovedTaAmount?.toFixed(0)}</TableCell>
            <TableCell>{row?.adminApprovedDaAmount?.toFixed(0)}</TableCell>
            <TableCell>{row?.adminApprovedMiscAmount?.toFixed(0)}</TableCell>
            <TableCell>{row?.dacustomTotalAmount?.toFixed(0)}</TableCell>
            <TableCell>{row?.tacustomTotalAmount?.toFixed(0)}</TableCell>
            <TableCell>{row?.miscCustomTotalAmount?.toFixed(0)}</TableCell>
            <TableCell>{row?.managerApprovedTaAmount?.toFixed(0)}</TableCell>
            <TableCell>{row?.managerApprovedDaAmount?.toFixed(0)}</TableCell>
            <TableCell>{row?.managerApprovedMiscAmount?.toFixed(0)}</TableCell>
            <TableCell>{row?.totalClaimedAmount?.toFixed(0)}</TableCell>
            <TableCell>{row?.managerApprovedTotalAmount?.toFixed(0)}</TableCell>
            <TableCell>{row?.adminApprovedTotalAmount?.toFixed(0)}</TableCell>
          </TableRow>
          {row?.subordinates &&
            row?.subordinates?.length > 0 &&
            open &&
            row?.subordinates !== null &&
            row?.subordinates?.map((subrows: any) => (
              <SelectableCollapsibleRow
                key={subrows?.expenseHistoryId}
                row={subrows}
                selectAll={props?.selectAll}
                collapseIconPadding={iconPadding}
              />
            ))}
        </>
      )}
    </>
  );
};
