import {Box, Stack} from '@mui/system';
import {useStyles} from './style';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {strings} from '@app/common/strings';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {
  fetchDownloadPodFileCreator,
  hoDashboardActions,
} from '../../../redux-api/slice';
import {useEffect, useState} from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';

const UploadPod = ({
  setSelectedFile,
  checkValidation,
  fileName,
  setFileName,
}: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const podData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.podForOrderIdData,
  );

  const fileData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.downloadPodFileData,
  );

  const handleFileChange = (file: any) => {
    if (
      file?.type === 'application/pdf' ||
      file?.type === 'image/png' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/jpg'
    ) {
      if (file.size / (1024 * 1024) > 6) {
        ToasterService.hideToaster();
        ToasterService.showToaster(
          'File size greater than 6 MB',
          ToastType.ERROR,
        );
      } else {
        setSelectedFile(file);
        setFileName(file?.name);
      }
    } else {
      ToasterService.hideToaster();
      ToasterService.showToaster('Invalid File type', ToastType.ERROR);
    }
  };

  const fileExtension: any = fileName?.split('.').pop()?.toLowerCase();

  const handleviewFile = () => {
    setOpen(true);
    podData?.podFileName &&
      dispatch(fetchDownloadPodFileCreator(podData?.podFileName));
  };

  const downloadFile = () => {
    const mimeTypeMatch = fileData.match(
      /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+)?(;base64)?,/,
    );
    const mimeType = mimeTypeMatch
      ? mimeTypeMatch[1]
      : 'application/octet-stream';
    const base64Content = fileData.split(',').pop();

    const byteCharacters = atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: mimeType});

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName || 'download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    setFileName(podData?.podFileName);
  }, [podData]);

  return (
    <Box className={`${classes.cellPadding} `}>
      <Box>
        <Typography
          fontSize="15.7px !important"
          sx={{
            '::after': {
              color: '#da3131',
              content: '"*"',
            },
          }}
          className={classes.podHeading}>
          {strings.zho.hoAdmin.pod.uploadPod}
        </Typography>
      </Box>
      <Stack
        marginTop={1.5}
        gap={1}
        direction="row"
        justifyContent="start"
        alignItems="center">
        <Box>
          <Box
            className={classes.podUploadTextBox}
            sx={{
              borderColor:
                checkValidation && fileName === null ? 'red !important' : '',
            }}>
            <input
              type="file"
              accept=".pdf,.png,.jpeg,.jpg"
              id="fileInput"
              style={{display: 'none'}}
              onChange={(e: any) => {
                handleFileChange(e.target.files[0]);
              }}
            />
            <Typography
              fontSize="11.7px"
              fontWeight="400"
              sx={{
                opacity: fileName ? 1 : 0.5,
                color: fileName ? '#322b7c' : '#000000',
              }}>
              {fileName
                ? fileName?.length > 60
                  ? fileName?.slice(0, 60) + '...'
                  : fileName
                : 'Supported format: .PDF, .jpeg, .jpg, .png Only'}
            </Typography>
            <Button
              sx={{minWidth: '30px', padding: '0px'}}
              data-testid="removefile-btn"
              onClick={() => {
                fileName
                  ? setFileName(null)
                  : document.getElementById('fileInput')?.click();
              }}>
              {fileName ? <ClearOutlinedIcon /> : <CloudUploadIcon />}
            </Button>
          </Box>
        </Box>
        {fileName &&
          fileName !== 'Select File' &&
          podData?.podFileName === fileName && (
            <Button
              sx={{minWidth: '30px', padding: '0px'}}
              data-testid="view-podFile-btn"
              onClick={handleviewFile}>
              <VisibilityIcon />
            </Button>
          )}
      </Stack>
      <Box>
        <Typography
          fontSize="11.7px !important"
          sx={{
            fontWeight: 400,
            color: '#000000',
            marginTop: '2px',
            '::before': {
              color: '#da3131',
              content: '"*"',
            },
          }}>
          {strings.zho.hoAdmin.dashboard.modalPopup.maxFileLimit}
        </Typography>
      </Box>

      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        sx={{
          height: '500',
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>
          <Box
            display={'flex'}
            justifyContent="space-between"
            alignItems="center">
            <Typography> {fileName}</Typography>
            <Stack direction="row" alignItems="center">
              <Button
                data-testid="donwload-pod-btn"
                onClick={downloadFile}
                disabled={!(fileData?.length > 0)}
                variant="contained"
                size="small"
                sx={{
                  fontSize: '12.7px',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  textTransform: 'capitalize',
                }}>
                {strings.zho.hoAdmin.dashboard.modalPopup.download}
              </Button>
              <Button
                data-testid="close-viewPod-popup"
                onClick={() => {
                  dispatch(hoDashboardActions.setDownloadPodFileData(''));
                  setOpen(false);
                }}>
                <ClearOutlinedIcon fontSize="large" />
              </Button>
            </Stack>
          </Box>
        </DialogTitle>
        <DialogContent>
          {!(fileData?.length > 0) ? (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress />
            </Box>
          ) : (
            <div
              style={{
                height: 'auto',
                marginTop: `${fileExtension === 'pdf' ? '-58px' : ''}`,
              }}>
              {fileExtension === 'pdf' && (
                <embed
                  style={{
                    width: '100%',
                  }}
                  height="480"
                  src={`data:application/pdf;base64,${fileData}`}
                />
              )}
              {fileExtension !== 'pdf' && fileExtension !== '' && (
                <img
                  style={{width: '100%'}}
                  src={`data:image;base64,${fileData}`}
                  alt={fileName || 'POD File'}
                />
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default UploadPod;
