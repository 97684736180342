import {Box} from '@mui/system';
import {updateTotalTradeDiscountStyles as styles} from '../styles';
import {Button, Typography} from '@mui/material';
import { useDispatch } from 'react-redux';
import {fetchDeleteProductTotalDiscountDataCreator} from '../../redux-api/slice';
import { strings } from '@app/common/strings';

interface ConfirmationModalProps {
  handleToggleModal: () => void;
  removeDeletedProducts: (id:number) => void;
  id: number | null;
  divisionId: number | null;
  productId: any;
  filterProductId:number | null
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  handleToggleModal,
  removeDeletedProducts,
  id,
  divisionId,
  productId,
  filterProductId
}) => {
  const dispatch = useDispatch();
  const handleDeleteClick = () => {
      const payload = {
          id,
          divisionId,
          productId,
        };
        dispatch(fetchDeleteProductTotalDiscountDataCreator(payload));
        removeDeletedProducts(filterProductId||0)
        handleToggleModal();
    };
    

  return (
    <>
      <Typography sx={[styles.modalSection, styles.modalHeader]}>
        {strings.zho.confirmAction}
      </Typography>
      <Box
        sx={{
          position: 'relative',
          minHeight: '80px',
          display: 'flex',
          alignItems: 'center',
        }}>
          {/* <Box sx={styles.loader}>
            <CircularProgress />
          </Box> */}
          <Typography
            sx={[styles.modalSection, {fontSize: '12.7px', color: '#3a3a3a',textAlign:"center"}]}>
            {strings.zho.deleteConfirmation}
          </Typography>
      </Box>
      <Box sx={[styles.modalSection, styles.modalFooter]}>
        <Button data-testid="cancel-delete" variant="outlined" sx={{fontSize:"12.7px", textTransform:"capitalize"}} fullWidth onClick={handleToggleModal}>
        {strings.zho.cancel}
        </Button>
        <Button
        data-testid="confirm-delete"
          variant="contained"
          fullWidth
          sx={styles.rejectionButtonRej}
          onClick={handleDeleteClick}>
          {strings.zho.delete}
        </Button>
      </Box>
    </>
  );
};
