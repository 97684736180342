import {useEffect} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {filterStateActions} from '@app/components/right-sidebar/redux';
import {
  navbarComponentName,
  routeStateActions,
} from '@app/router/redux/routeHandler';
import SubToggle from '@app/screens/zho/components/sub-toggle';
import {Box} from '@mui/material';
import AddProductDiscount from './addProductDiscount';
import {fetchDivisionDataCreator} from '../redux-api/slice';
import {setSubTabSwitch} from '../redux/tabName';
import {ProductListing} from './productsListing';
import {updateTotalTradeDiscountStyles as styles} from './styles';

export const UpdateTotalTradeDiscount = ({userLoginInfo}: any) => {
  const dispatch = useDispatch();
  const currentSubTab = useSelector(
    (state: RootStateOrAny) => state.tabNames?.value?.subTabSwitch,
  );

  useEffect(() => {
    dispatch(fetchDivisionDataCreator(userLoginInfo?.staffPositionId, ''));
    dispatch(filterStateActions.setFilterState({}));
    dispatch(
      routeStateActions.setNavbarComponentName(navbarComponentName?.fcAdmin),
    );
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(setSubTabSwitch(1));
    };
  }, []);
  return (
    <Box>
      <SubToggle title1="Add Product Discount" title2="Product Listing" />
      <Box sx={styles.wrapper}>
        {currentSubTab === 1 ? (
          <AddProductDiscount userLoginInfo={userLoginInfo} />
        ) : (
          <ProductListing userLoginInfo={userLoginInfo} />
        )}
      </Box>
    </Box>
  );
};
