import {makeStyles} from '@mui/styles';

export const styles = makeStyles({
  DialogContent: {
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  uploadSuccess: {
    justifySelf: 'flex-start',
    fontSize: 12,
    color: 'green',
  },
  billErrorMsg: {
    justifySelf: 'flex-start',
    fontSize: 12,
  },
  addNewBillBtn: {
    justifySelf: 'flex-start',
    fontSize: 14,
    marginTop: 16,
    marginBottom: 16,
    fontWeight: 'bolder',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    webkitFlexDirection: 'row',
    width: 'calc(100% + 50px)',
    marginBottom: '10px',
  },
  validateBtn: {
    fontSize: 10,
    minWidth: 60,
    height: 25,
    marginTop: 27,
    marginRight: 8,
    '& .css-jcxoq4-MuiButton-endIcon': {
      display: 'inherit',
      marginRight: '0px',
      marginLeft: '0px',
    },
  },
  uploadBillBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    webkitFlexDirection: 'row',
    width: 'calc(100% + 50px)',
  },
  uploadBillBtn: {
    fontSize: 7,
    height: 25,
    right: 16,
  },
  previewLink: {
    fontSize: 12,
    fontWeight: '600',
    marginBottom: 4,
    cursor: 'pointer',
    marginRight: 10,
    marginTop: 8,
    wordWrap: 'break-word',
    width: 313,
  },
  validationFailedMsg: {
    right: 16,
    marginTop: 5,
    marginBottom: -5,
    position: 'relative',
  },
  validationSuccessMsg: {
    right: 16,
    marginTop: 5,
    position: 'relative',
    color: 'green',
  },
  saveBtn: {
    fontSize: 12,
    minWidth: 61,
    height: 28,
    marginTop: 9.6,
    marginLeft: -16,
  },
  editableText: {
    color: '#000000',
    fontSize: 11,
    fontWeight: 'bolder',
    marginBottom: 4,
  },
  normalField: {
    borderColor: '#000',
    color: '#000',
    borderWidth: 0.5,
    fontFamily: 'Poppins-Regular',
    fontSize: 1,
    height: 10,
    width: '100%',
  },
  normalFieldInputProps: {
    paddingTop: 4.5,
    paddingBottom: 4.5,
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 11,
  },
  datePicker: {
    borderColor: '#000',
    color: '#000',
    borderWidth: 0.5,
    fontFamily: 'Poppins-Regular',
    height: 27,
    width: '100%',
    fontSize: 11,
  },
  viewBillTypo: {
    justifySelf: 'flex-start',
    fontSize: 12,
    color: 'green',
  },
  viewBillContainer: {
    width: '300px',
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    justifySelf: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 20,
    marginTop: 20,
  },
  uploadBillText: {
    justifySelf: 'flex-start',
    fontSize: 12,
    marginRight: 3,
    fontWeight: 'bolder',
  },
  billContainer: {
    border: 'solid',
    borderWidth: '0.5px',
    marginBottom: 10,
    width: 'calc(100% + 0px)',
  },
  billNameText: {
    fontWeight: 'bold',
    fontSize: 13,
  },
  viewBillPreview: {
    fontSize: 12,
    fontWeight: '600',
    marginBottom: 4,
    cursor: 'pointer',
    wordBreak: 'break-all',
  },
  viewSupDocPrev: {
    cursor: 'pointer',
    overflowWrap: 'break-word',
    fontSize: 12,
    marginLeft: 0,
  },
  noBillsContainer: {
    border: 'solid',
    borderWidth: '0.5px',
    marginBottom: 10,
    width: '500px',
    height: '200px',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewBillDialogContent: {
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  validateBtnCollapsible: {
    fontSize: 10,
    minWidth: 60,
    height: 25,
    marginTop: '20px',
    marginRight: 9,
    '& .css-jcxoq4-MuiButton-endIcon': {
      display: 'inherit',
      marginRight: '0px',
      marginLeft: '0px',
    },
  },
  remarksContainer: {
    display: 'inline-block',
    position: 'relative',
    left: 226,
    width: 150,
  },
  remarksText: {
    color: '#000000',
    fontSize: 11,
    fontWeight: 'bolder',
    marginBottom: 4,
  },
  remarksInput: {
    borderColor: '#000',
    color: '#000',
    borderWidth: 0.5,
    fontFamily: 'Poppins-Regular',
    fontSize: 1,
    height: 18,
  },
  supportDocInCollapsible: {
    color: '#000000',
    fontSize: 15,
    fontWeight: '600',
    marginBottom: 4,
    textDecoration: 'underline',
  },
  supDocPrevLink: {
    cursor: 'pointer',
    overflowWrap: 'break-word',
    fontSize: 12,
    marginLeft: 0,
  },
  noDataFound: {
    color: '#FF0000',
    fontSize: 14,
    fontWeight: '600',
  },
  errorMsg: {right: 16, marginTop: 1, position: 'relative'},
});

export const billBoxStyle = {
  suppDocBtn: {
    fontSize: 7,
    height: 28,
    marginLeft: 1,
    marginRight: 1,
    marginTop: 1.1,
  },
  cancelBtn: {
    fontSize: 12,
    minWidth: 61,
    height: 28,
    marginTop: 1.2,
  },
  textField: {
    borderColor: '#000',
    color: '#000',
    borderWidth: 0.5,
    fontFamily: 'Poppins-Regular',
    fontSize: 1,
    height: 10,
    width: '100%',
  },
  textFieldInputProps: {
    paddingTop: 4.5,
    paddingBottom: 4.5,
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 11,
  },
  datePickerInputProps: {
    borderColor: '#000',
    color: '#000',
    borderWidth: 0.5,
    fontFamily: 'Poppins-Regular',
    height: 27,
    width: '100%',
    fontSize: 11,
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 11,
    fontWeight: 'bold',
  },
  dialogContentDiv: {justifySelf: 'flex-start', fontSize: 12, marginBottom: 5},
};
