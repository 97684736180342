import {strings} from '@app/common/strings';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import {useStyles} from '../style';
import theme from '@app/themes';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {SwitchingNo} from '../../../fc-admin/redux/tabName';
import {setNavbarShow} from '../../redux/orderStore';
import moment from 'moment';
import {
  fetchDownloadPodFileCreator,
  fetchPodForOrderIdCreator,
  hoDashboardActions,
} from '../../redux-api/slice';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {useEffect, useState} from 'react';

const OrderSupplyPodFile = ({data, handleviewFile}: any) => {
  const LightTooltipNormal = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      // boxShadow: theme.shadows[1],
      fontSize: 11.7,
      border: '1px solid #dcdce0',
      borderRadius: '5px',
      minWidth: '500px !important',
      padding: '0.3rem',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    },
  }));
  const classes = useStyles();
  return (
    <Stack
      direction="row"
      gap={0.5}
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems="center">
      <Typography
        sx={{
          fontSize: '15.7px !important',
          marginBottom: '0px !important',
        }}
        className={`${classes.RfHeading}  `}
        variant="h6">
        {strings.zho.hoAdmin.dashboard.modalPopup.Attachment} :
      </Typography>

      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <LightTooltipNormal
          title={
            <Box>
              <Typography fontSize="11.7px" fontWeight="400" variant="caption">
                {data?.podFileName}
              </Typography>
            </Box>
          }>
          <Typography
            sx={{
              fontSize: '12.7px !important',
              marginBottom: '0px !important',
              fontWeight: '400',
              color: `${theme.colors.primary} !important`,
            }}
            variant="h6">
            {data?.podFileName?.length > 80
              ? `${data.podFileName.slice(0, 80)}...`
              : data?.podFileName}
          </Typography>
        </LightTooltipNormal>
        <svg
          onClick={handleviewFile}
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M25.6375 15.0656C25.54 14.9258 23.2148 11.6066 20.006 9.33777C18.3423 8.15931 16.2439 7.51074 14.0984 7.51074C11.9542 7.51074 9.85572 8.15931 8.18848 9.33777C4.97971 11.6066 2.65686 14.9258 2.55934 15.0656C2.2797 15.4686 2.2797 16.0032 2.55934 16.4062C2.65686 16.546 4.97971 19.8652 8.18848 22.1341C9.85572 23.3113 11.9542 23.9599 14.0984 23.9599C16.2439 23.9599 18.3423 23.3113 20.006 22.1329C23.2148 19.8641 25.54 16.5449 25.6375 16.405C25.9183 16.0032 25.9183 15.4674 25.6375 15.0656ZM14.0984 19.8476C11.8261 19.8476 9.98614 18.003 9.98614 15.7353C9.98614 13.463 11.8261 11.623 14.0984 11.623C16.3661 11.623 18.2107 13.463 18.2107 15.7353C18.2107 18.003 16.3661 19.8476 14.0984 19.8476Z"
            fill="#322B7C"
          />
          <path
            d="M16.4478 15.7351C16.4478 17.0299 15.3927 18.085 14.0979 18.085C12.7996 18.085 11.748 17.0299 11.748 15.7351C11.748 14.4368 12.7996 13.3853 14.0979 13.3853C15.3927 13.3853 16.4478 14.4368 16.4478 15.7351Z"
            fill="#322B7C"
          />
        </svg>
      </Stack>
    </Stack>
  );
};

const OrderSupplyPod = ({supplyPod, OpenModal}: any) => {
  const statusToggleNo = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.statusToggleNo,
  );

  const currentOrderId = useSelector(
    (state: RootStateOrAny) => state.hoOrderStore.value.currentOrderId,
  );

  const podData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.podForOrderIdData,
  );

  const fileData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.downloadPodFileData,
  );

  const [fileName, setFileName] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const fileExtension: any = fileName?.split('.').pop()?.toLowerCase();

  const dispatch = useDispatch();

  const handleShowViewPod = () => {
    OpenModal();
    dispatch(SwitchingNo(10));
    dispatch(setNavbarShow(false));
    dispatch(fetchPodForOrderIdCreator(currentOrderId));
  };
  const classes = useStyles();

  const borderBottomCellChecking = (
    leftParams: number,
    rightParams: any,
    radius: string,
  ) => {
    if (
      leftParams ===
      (rightParams.type === 'supplyPod'
        ? rightParams.supplyPod?.supplyPOD[0].podDetailsList?.length - 1
        : rightParams.data?.podDetailsList?.length - 1)
    ) {
      if (radius === 'radiusRight') {
        return `${classes.hoTableBorderBottom} ${classes.rowOneBbr} `;
      } else if (radius === 'radiusLeft') {
        return `${classes.hoTableBorderBottom} ${classes.rowOneBbl} `;
      } else {
        return classes.hoTableBorderBottom;
      }
    }
    return '';
  };

  const LastBorderTableChecking = (
    index: number,
    total: number,
    radius: string,
  ) => {
    if (index === total - 1) {
      return `${classes.hoTableBorderBottom} ${
        radius === 'bottomLeft' ? classes.rowOneBbl : ''
      } ${radius === 'bottomRight' ? classes.rowOneBbr : ''}  `;
    } else {
      return '';
    }
  };

  const handleviewFile = () => {
    setOpen(true);

    dispatch(fetchDownloadPodFileCreator(supplyPod?.podFileName));
  };

  const downloadFile = () => {
    const mimeTypeMatch = fileData.match(
      /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+)?(;base64)?,/,
    );
    const mimeType = mimeTypeMatch
      ? mimeTypeMatch[1]
      : 'application/octet-stream';
    const base64Content = fileData.split(',').pop();

    const byteCharacters = atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: mimeType});

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName || 'download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    setFileName(supplyPod?.podFileName);
  }, [supplyPod]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{
            marginBottom: '16px !important',
            marginTop: '16px !important',
            fontSize: '15.7px !important',
          }}
          className={`${classes.RfHeading}  `}
          variant="h6">
          {strings.zho.hoAdmin.dashboard.modalPopup.supplyPod}
        </Typography>

        {statusToggleNo === 1 && (
          <Button
            data-testid="button-view-pod"
            variant="outlined"
            size="large"
            className={classes.applyButton}
            onClick={handleShowViewPod}>
            {strings.zho.hoAdmin.dashboard.modalPopup.viewPod}
          </Button>
        )}
      </Stack>
      <Table
        sx={{
          marginBottom: '16px !important',
          borderCollapse: 'separate',
        }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{border: 'none'}}
              className={`${classes.defaultLightText} ${classes.textUpper} ${classes.cellPadding} `}>
              {strings.zho.hoAdmin.dashboard.tableHeading.InvoiceNo}
            </TableCell>
            <TableCell
              sx={{border: 'none'}}
              className={`${classes.defaultLightText} ${classes.textUpper} ${classes.cellPadding}`}>
              {strings.product}
            </TableCell>
            <TableCell
              sx={{border: 'none', minWidth: '80px'}}
              className={`${classes.defaultLightText} ${classes.textUpper} ${classes.cellPadding}`}>
              {strings.zho.hoAdmin.dashboard.modalPopup.invoiceQty}
            </TableCell>
            <TableCell
              sx={{border: 'none', minWidth: '80px'}}
              className={`${classes.defaultLightText} ${classes.textUpper} ${classes.cellPadding}`}>
              {strings.zho.hoAdmin.dashboard.modalPopup.podNo}
            </TableCell>

            <TableCell
              sx={{border: 'none', minWidth: '80px'}}
              className={`${classes.defaultLightText} ${classes.textUpper} ${classes.cellPadding}`}>
              {strings.zho.hoAdmin.dashboard.modalPopup.podQty}
            </TableCell>
            <TableCell
              sx={{border: 'none'}}
              className={`${classes.defaultLightText} ${classes.textUpper}  ${classes.cellPadding}`}>
              {strings.zho.hoAdmin.dashboard.modalPopup.podDate}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.supplyPodBodyTable}>
          {supplyPod?.supplyPOD?.map((data: any, index: number) => {
            return data.podDetailsList.map(
              (podDetail: any, podIndex: number) => {
                return (
                  <TableRow
                    className={`  ${
                      podIndex === 0
                        ? index === 0
                          ? data.podDetailsList.length == 1 &&
                            classes.supplyPodTableSeperationFirstTop
                          : classes.supplyPodTableSeperationSingle
                        : ''
                    }`}
                    sx={{
                      '& .MuiTableCell-body': {
                        borderBottom: 'none ',
                      },
                    }}
                    key={podIndex}>
                    {/* Only render the invoice number, product name, and invoice quantity for the first podDetail item */}
                    {podIndex === 0 ? (
                      <>
                        <TableCell
                          className={`${
                            classes.priDefaultFontSize
                          } ${borderBottomCellChecking(
                            podIndex,
                            {type: 'supplyPod', supplyPod},
                            `${
                              data?.podDetailsList?.length === 1
                                ? 'radiusLeft'
                                : ''
                            }`,
                          )} 
                            
                           ${
                             podIndex === data?.podDetailsList?.length - 1 &&
                             LastBorderTableChecking(
                               podIndex,
                               data.podDetailsList.length,
                               'bottomLeft',
                             )
                           }

                             ${classes.hoTableBorderLeft} ${
                            classes.rowOneBtl
                          }  ${classes.hoTableBorderTop}   `}>
                          {supplyPod.invoiceNumber}
                        </TableCell>
                        <TableCell
                          className={`${
                            classes.hoTableBorderTop
                          } ${borderBottomCellChecking(
                            podIndex,
                            {type: 'supplyPod', supplyPod},
                            '',
                          )}
              
                           ${
                             podIndex === data?.podDetailsList?.length - 1 &&
                             LastBorderTableChecking(
                               podIndex,
                               data.podDetailsList.length,
                               '',
                             )
                           }
                            `}>
                          <Stack
                            direction="row"
                            gap={0.5}
                            justifyContent="flex-start"
                            alignItems="center">
                            <Typography
                              className={`${classes.priDefaultFontSize}    `}>
                              {data.productName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell
                          className={`${classes.priDefaultFontSize} 
                           ${
                             podIndex === data?.podDetailsList?.length - 1 &&
                             LastBorderTableChecking(
                               podIndex,
                               data.podDetailsList.length,
                               '',
                             )
                           }
                            ${borderBottomCellChecking(
                              podIndex,
                              {type: 'supplyPod', supplyPod},
                              '',
                            )}  ${classes.hoTableBorderTop}  `}>
                          {data.invoiceQty}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        {/* Render empty cells for subsequent rows */}
                        <TableCell
                          className={`          
                          ${
                            podIndex === data.podDetailsList.length - 1 &&
                            LastBorderTableChecking(
                              podIndex,
                              supplyPod?.supplyPOD.length,
                              'bottomLeft',
                            )
                          }    ${LastBorderTableChecking(
                            podIndex,
                            data.podDetailsList.length,
                            '',
                          )}
                          
                           ${borderBottomCellChecking(
                             podIndex,
                             {type: 'data', data},
                             'radiusLeft',
                           )} 
                            ${classes.hoTableBorderLeft} `}></TableCell>
                        <TableCell
                          className={` ${
                            podIndex === data.podDetailsList.length - 1 &&
                            LastBorderTableChecking(
                              podIndex,
                              supplyPod?.supplyPOD.length,
                              '',
                            )
                          }  ${LastBorderTableChecking(
                            podIndex,
                            data.podDetailsList.length,
                            '',
                          )} ${borderBottomCellChecking(
                            podIndex,
                            {type: 'data', data},
                            '',
                          )} `}></TableCell>
                        <TableCell
                          className={` ${
                            podIndex === data.podDetailsList.length - 1 &&
                            LastBorderTableChecking(
                              podIndex,
                              supplyPod?.supplyPOD.length,
                              '',
                            )
                          } ${LastBorderTableChecking(
                            podIndex,
                            data.podDetailsList.length,
                            '',
                          )} ${borderBottomCellChecking(
                            podIndex,
                            {type: 'data', data},
                            '',
                          )}`}></TableCell>
                      </>
                    )}

                    {/* Always render the pod details */}
                    <TableCell
                      className={`  ${
                        supplyPod?.supplyPOD.length >= 2 &&
                        podIndex === data?.podDetailsList?.length - 1 &&
                        LastBorderTableChecking(
                          index,
                          supplyPod?.supplyPOD.length,
                          '',
                        )
                      } ${borderBottomCellChecking(
                        podIndex,
                        {type: 'data', data},
                        '',
                      )}
                        ${classes.priDefaultFontSize} ${
                        podIndex === 0 ? classes.hoTableBorderTop : ''
                      }   `}>
                      {podDetail.podNumber}
                    </TableCell>
                    <TableCell
                      className={` ${
                        supplyPod?.supplyPOD.length >= 2 &&
                        podIndex === data?.podDetailsList?.length - 1 &&
                        LastBorderTableChecking(
                          index,
                          supplyPod?.supplyPOD.length,
                          '',
                        )
                      } ${borderBottomCellChecking(
                        podIndex,
                        {type: 'data', data},
                        '',
                      )}  ${classes.priDefaultFontSize}  ${
                        podIndex === 0 ? classes.hoTableBorderTop : ''
                      }   `}>
                      {podDetail.podQty}
                    </TableCell>
                    <TableCell
                      className={`  ${
                        supplyPod?.supplyPOD.length >= 2 &&
                        podIndex === data?.podDetailsList?.length - 1 &&
                        LastBorderTableChecking(
                          index,
                          supplyPod?.supplyPOD.length,
                          'bottomRight',
                        )
                      } ${
                        classes.hoTableBorderRight
                      } ${borderBottomCellChecking(
                        podIndex,
                        {type: 'data', data},
                        'radiusRight',
                      )}   ${classes.priDefaultFontSize} ${classes.noWrap}  ${
                        podIndex === 0
                          ? `${classes.hoTableBorderTop}  ${classes.rowOneBtr} `
                          : ''
                      }   `}>
                      {podDetail.podDate
                        ? moment(podDetail.podDate).format('DD-MM-YYYY')
                        : null}
                    </TableCell>
                  </TableRow>
                );
              },
            );
          })}
        </TableBody>
      </Table>
      {supplyPod?.podFileName !== null && (
        <OrderSupplyPodFile data={supplyPod} handleviewFile={handleviewFile} />
      )}
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        sx={{
          height: '500',
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>
          <Box
            display={'flex'}
            justifyContent="space-between"
            alignItems="center">
            <Typography> {fileName}</Typography>
            <Stack direction="row" alignItems="center">
              <Button
                onClick={downloadFile}
                variant="contained"
                size="small"
                sx={{
                  fontSize: '12.7px',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  textTransform: 'capitalize',
                }}>
                Download
              </Button>
              <Button
                onClick={() => {
                  dispatch(hoDashboardActions.setDownloadPodFileData(''));
                  setOpen(false);
                }}>
                <ClearOutlinedIcon fontSize="large" />
              </Button>
            </Stack>
          </Box>
        </DialogTitle>
        <DialogContent>
          {!(fileData?.length > 0) ? (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress />
            </Box>
          ) : (
            <div
              style={{
                height: 'auto',
                marginTop: `${fileExtension === 'pdf' ? '-58px' : ''}`,
              }}>
              {fileExtension === 'pdf' && (
                <embed
                  style={{
                    width: '100%',
                  }}
                  height="480"
                  src={`data:application/pdf;base64,${fileData}`}
                />
              )}
              {fileExtension !== 'pdf' && fileExtension !== '' && (
                <img
                  style={{width: '100%'}}
                  src={`data:image;base64,${fileData}`}
                  alt={fileName}
                />
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OrderSupplyPod;
