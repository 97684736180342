import {createAction, createSlice} from '@reduxjs/toolkit';
export const expenseDAMasterInitialState = {
  isActiveState: true,
  pageNo: 0,
  rowPerPage: 100,
  totalCount: 0,
  expenseDAMasterGrid: [],
  expenseDAMasterNewData: [],
  expenseDAMasterSnaShot:[],
  addExpenseDAMasterPopupVisible: false,
  expenseDAMasterLoading: false,
  adminUsersError: false,
  adminUsersSuccess: false,
  isExpenseDaMaster: false,
  divisionDropDown: [],
  stateDropDown: [],
  accessGroupDropDown: [],
  designationsDropdown: [],
  HQDropdown: [],
  hqByDivisionDesignation:[],
  tIsActiveState: true,
  expenseDAMasterInAutoSuggest: {},
  searchText: '',
  expenseDAMasterInAutoSuggestFilter: {},
  editExpenseDAMasterPopupVisible: false,
  editExpenseDAMasterValue: [],
  resetNavbarVersion:0,
  hqType:[],
  designationArr:[]
};

export const fetchDesignationsDropdownCreator = createAction(
  'EXPENSE_DA_MASTER_HANDLER/FETCH_DESIGNATION_DROPDOWN',
);
export const fetchDesignationsDropdownCreatorTypeName =
  fetchDesignationsDropdownCreator().type;

export const fetchHQDropdownCreator = createAction(
  'EXPENSE_DA_MASTER_HANDLER/FETCH_HQ_DROPDOWN',
);
export const fetchHQDropdownCreatorTypeName = fetchHQDropdownCreator().type;

export const fetchExpenseDAMasterGridCreator = createAction<any>(
  'EXPENSE_DA_MASTER_HANDLER/FETCH_EXPENSE_DA_MASTER_GRID',
);
export const fetchExpenseDAMasterGridCreatorTypeName =
  fetchExpenseDAMasterGridCreator(null).type;

export const fetchExpenseDAMasterInAutoSuggestCreator = createAction<any>(
  'EXPENSE_DA_MASTER_HANDLER/FETCH_EXPENSE_DA_MASTER_AUTO_SUGGEST',
);
export const fetchExpenseDAMasterInAutoSuggestCreatorTypeName =
  fetchExpenseDAMasterInAutoSuggestCreator(null).type;

export const activeInactiveExpenseDAMasterCreator = createAction<any>(
  'EXPENSE_DA_MASTER_HANDLER/ACTIVE_INACTIVE',
);
export const fetchExpenseDAMasterDesignationByDivisionCreator = createAction<any>(
  'EXPENSE_DA_MASTER_HANDLER/DESIGNATION_DROPDOWN',
);
export const fetchExpenseDAMasterDesignationByDivisionCreatorTypeName =
fetchExpenseDAMasterDesignationByDivisionCreator(null).type;

export const activeInactiveExpenseDAMasterCreatorCreatorTypeName =
  activeInactiveExpenseDAMasterCreator({}).type;

export const fetchAddExpenseDAMasterCreator = createAction<any>(
  'EXPENSE_DA_MASTER_HANDLER/ADD_EXPENSE_DA_MASTER',
);
export const fetchAddExpenseDAMasterCreatorTypeName =
  fetchAddExpenseDAMasterCreator(null).type;

  export const AddExpenseValidationDAMasterCreator = createAction<any>(
    'EXPENSE_DA_MASTER_HANDLER/ADD_EXPENSE_VALIDATION_DA_MASTER',
  );
  export const AddExpenseValidationDAMasterCreatorTypeName =
  AddExpenseValidationDAMasterCreator(null).type;

export const editExpenseDAMasterCreator = createAction<any>(
  'EXPENSE_DA_MASTER_HANDLER/EDIT_EXPENSE_DA_MASTER',
);
export const editExpenseDAMasterCreatorTypeName = editExpenseDAMasterCreator({}).type;

export const fetchHQTypeDropdownCreator = createAction(
  'EXPENSE_DA_MASTER_HANDLER/HQ_DROPDOWN',
);
export const fetchHQTypeDropdownCreatorCreatorTypeName = fetchHQTypeDropdownCreator().type;

export const fetchHqByEdividionDesginationDAMasterCreator = createAction<any>(
  "EXPENSE_DA_MASTER_HANDLER/HQ_EXPENSE_DA_MASTER"
);

export const fetchHqByEdividionDesginationDAMasterCreatorTypeName =
fetchHqByEdividionDesginationDAMasterCreator({}).type;

export const fetchExportFileCreator = createAction<any>(
  "EXPENSE_DA_MASTER_HANDLER/EXPORT_FILE_EXPENSE_DA_MASTER"
);

export const fetchExportFileCreatorTypeName =
fetchExportFileCreator({}).type;

const expenseDAMasterStateHandler = createSlice({
  
  name: 'EXPENSE_DA_MASTER_HANDLER',
  initialState: expenseDAMasterInitialState,
  reducers: {
    setExpenseDAMasterStateKeyValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        [payload.key]: payload.value,
      };
    },
    setaddExpenseDAMasterPopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addExpenseDAMasterPopupVisible: payload,
      };
    },
    setExpenseDAMasterGrid: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseDAMasterGrid: payload,
      };
    },
    setExpenseDAMasterExistingData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseDAMasterExistingData: payload,
      };
    },
    setExpenseDAMasterNewData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseDAMasterNewData: payload,
      };
    },
    setPageNo: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        pageNo: payload,
      };
    },
    setTotalCount: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        totalCount: payload,
      };
    },
    setDesignationArr: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        designationArr: payload,
      };
    },
    setHqType: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        hqType: payload,
      };
    },
    setHqByDivisionDesignation: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        hqByDivisionDesignation: payload,
      };
    },
    setRowPerPage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        rowPerPage: payload,
      };
    },
    setExpenseDAMasterSnaShot: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseDAMasterSnaShot: payload,
      };
    },
    setExpenseDAMasterLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseDAMasterLoading: payload,
      };
    },
    setExpenseDAMasterInAutoSuggest: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseDAMasterInAutoSuggest: payload,
      };
    },
    setExpenseDAMasterInAutoSuggestFilter: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseDAMasterInAutoSuggestFilter: payload,
      };
    },
    setIsActiveState: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isActiveState: payload,
      };
    },
    setSearchText: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        searchText: payload,
      };
    },
   
    setEditExpenseDAMasterValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editExpenseDAMasterValue: payload,
      };
    },

    setEditExpenseDAMasterPopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editExpenseDAMasterPopupVisible: payload,
      };
    },
    setIsExpenseDaMaster: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isExpenseDaMaster: payload,
      };
    },

    setDesignationsDropdown: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        designationsDropdown: payload,
      };
    },
    setHQDropdown: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        HQDropdown: payload,
      };
    },
    setResetNavbarVersion: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        resetNavbarVersion: payload,
      };
    },
  },
});
export const expenseDAMasterStateActions = expenseDAMasterStateHandler.actions;
export const expenseDAMasterStateReducer = expenseDAMasterStateHandler.reducer;
