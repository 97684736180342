import {Box} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {makeStyles} from '@mui/styles';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {setSubTabSwitch} from '../../pages/fc-admin/redux/tabName';

const useStyles = makeStyles({
  customTabLabel: {
    textTransform: 'capitalize',
    fontSize: '12.7px',
  },
});

interface SubToggleProps {
  title1: string;
  title2: string;
  title3?: string;
}
const SubToggle: React.FC<SubToggleProps> = ({title1, title2, title3 = ''}) => {
  const dispatch = useDispatch();
  const currentSubTab = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.subTabSwitch,
  );

  const classes = useStyles();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSubTabSwitch(newValue));
  };

  return (
    <>
      <TabContext value={currentSubTab}>
        <Box sx={{marginLeft: '20px', marginBottom: '10px'}}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              sx={{
                fontWeight:
                  title3 !== '' && currentSubTab === 1
                    ? '600' // Apply '600' if title3 is not empty
                    : currentSubTab === 1
                    ? 'bolder' // Apply 'bolder' if currentSubTab is 1
                    : 'normal !important', // Default style for fontWeight
                fontSize: `${
                  title3 !== '' ? '16px !important' : '12.7px !important'
                }`,
                color: title3 !== '' ? '#322b7c' : '#00000099',
              }}
              label={title1}
              className={classes.customTabLabel}
              value={1}
              // onClick={()=>handleTabChange(1)}
            />
            <Tab
              sx={{
                fontWeight:
                  title3 !== '' && currentSubTab === 2
                    ? '600' // Apply '600' if title3 is not empty
                    : currentSubTab === 2
                    ? 'bolder' // Apply 'bolder' if currentSubTab is 1
                    : 'normal !important', // Default style for fontWeight
                fontSize: `${
                  title3 !== '' ? '16px !important' : '12.7px !important'
                }`,
                color: title3 !== '' ? '#322b7c' : '#00000099',
              }}
              label={title2}
              className={classes.customTabLabel}
              value={2}
              // onClick={()=>handleTabChange(2)}
            />
            {title3 && title3.length > 1 && (
              <Tab
                sx={{
                  fontWeight:
                    title3 !== '' && currentSubTab === 3
                      ? '600' // Apply '600' if title3 is not empty
                      : currentSubTab === 3
                      ? 'bolder' // Apply 'bolder' if currentSubTab is 1
                      : 'normal !important', // Default style for fontWeight
                  fontSize: `${title3 !== '' ? '16px !important' : '12.7px'}`,
                  color: title3 !== '' ? '#322b7c' : '#00000099',
                }}
                label={title3}
                className={classes.customTabLabel}
                value={3}
                // onClick={()=>handleTabChange(2)}
              />
            )}
          </TabList>
        </Box>
      </TabContext>
    </>
  );
};
export default SubToggle;
