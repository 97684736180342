import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Checkbox,
  Container,
  Button,
  Tooltip,
  tooltipClasses,
  CircularProgress,
  TablePagination,
  Chip,
  TooltipProps,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import {useStyles} from '../../style';
import {Fragment, useEffect, useRef, useState} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {Stack, styled} from '@mui/system';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import {strings} from '@app/common/strings';
import {ToastType} from '@app/services/toaster';
import {ToasterService} from '@app/services';
import dateUtils from '@app/screens/zho/utils/dateFormat';
import OrderProductDetails from '../orderProductDetails';
import {setStatusToggle} from '@app/screens/zho/pages/fc-admin/redux/tabName';
import {
  fetchOrderFormApprovalDataCreator,
  fetchOrderFormDataCreator,
  fetchOrderFormProductDataCreator,
  fetchOrderProductApprovalCreator,
  fetchOrderInnerFormDataCreator,
  fetchOrderSyncCreator,
  fetchErrorInfoMsgCreator,
} from '../../../redux-api/slice';
import {updateRequestPayloadOrder} from '../../../redux/orderStore';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {useLocation} from 'react-router-dom';
import theme from '@app/themes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface CheckBoxState {
  id: number;
  checked: boolean;
}

export const OrderFormTable = ({
  handleToggleModal,
  setOrderFormId,
  userLoginInfo,
  setIsSynced,
  setApproveModalOpen,
  approveModalOpen,
  setSyncApproveChecking,
  syncApproveChecking,
}: any) => {
  const classes = useStyles();
  const {DateFormat} = dateUtils;
  const [openCompetitor, setOpenCompetitor] = useState({id: 0, open: false});
  const [orderIds, setOrderIds] = useState<number[]>([]);
  const [openTooltipId, setOpenTooltipId] = useState<number | null>(null);
  const [page, setPage] = useState<number>(0);
  const [userTab, setUserTab] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const orderId = useRef('');
  const location = useLocation();
  const [isAllSync, setIsAllSync] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const dispatch = useDispatch();
  const tableData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormData,
  );

  const statusToggleNo = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.statusToggleNo,
  );

  const screenNo = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.screenNo,
  );

  const orderFormPayload = useSelector(
    (state: RootStateOrAny) => state.hoOrderStore.value.requestPayloadOrder,
  );

  const orderFormDataLoader = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormLoading,
  );

  const orderInnerFormData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderInnerFormData,
  );

  const orderInnerFromLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderInnerFormLoading,
  );

  const ApprovalOrderComplete = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderProductApprovalComplete,
  );

  const ToolTipErrorList = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderErrorInfoMsgData,
  );

  const ToolTipErrorListLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderErrorInfoMsgLoading,
  );

  const syncLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderSyncLoading,
  );

  const [checkBox, setCheckBox] = useState<CheckBoxState[]>([]);

  const toggleCheckBox = (type: any, id: number) => {
    setCheckBox((prevCheckBox: any) => {
      let updatedorderIds: number[] = [...orderIds];

      if (type === 'all') {
        const allChecked = prevCheckBox.every(
          (item: any) => item.checked === true,
        );
        const newCheckBoxState = prevCheckBox.map((item: any) => ({
          ...item,
          checked: !allChecked,
        }));

        // Update orderIds based on allChecked status
        updatedorderIds = allChecked
          ? []
          : tableData.orderList?.map((orderData: any) => orderData.orderId) ||
            [];

        setOrderIds(updatedorderIds);
        return newCheckBoxState;
      } else {
        const newCheckBoxState = prevCheckBox.map((item: any) => {
          if (item.id === type) {
            return {
              ...item,
              checked: !item.checked,
            };
          }
          return item;
        });

        // Update orderIds based on individual checkbox state
        const isChecked = newCheckBoxState.find(
          (item: any) => item.id === type,
        )?.checked;
        if (isChecked) {
          updatedorderIds.push(id);
        } else {
          updatedorderIds = updatedorderIds.filter(orderId => orderId !== id);
        }

        setOrderIds(updatedorderIds);
        return newCheckBoxState;
      }
    });
  };

  const LightTooltip = styled(({className, ...props}: any) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      // boxShadow: theme.shadows[1],
      fontSize: 11.7,
      border: '1px solid #dcdce0',
      borderRadius: '8px',
      position: 'fixed',
      padding: '1rem',
      paddingTop: ToolTipErrorList?.length > 2 ? '2.2rem' : '1rem',
      paddingRight: '0rem',
      left: '-280px',
      minWidth: '300px',
      zIndex: '9999 !important',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    },
  }));

  const LightTooltipNormal = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      // boxShadow: theme.shadows[1],
      fontSize: 11.7,
      border: '1px solid #dcdce0',
      borderRadius: '5px',
      padding: '0.3rem',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    },
  }));

  const handleTooltipClose = () => {
    document.body.style.overflow = 'auto';
    setOpenTooltipId(null);
  };

  const getStatusColor = (status: number) => {
    if (status === 0) {
      return '#ff7650';
    } else if (status === 1) {
      return '#01a685';
    } else if (status === 2 || status === 4) {
      return '#f45b60';
    } else if (status === 5) {
      return '#34b53a';
    }
  };

  const isRowHighlighted = (index: number, tableData: any): boolean => {
    return tableData?.orderList[index]?.isHighlighted || false;
  };

  const handleInnerClickData = (orderId: number, index: number) => {
    setOpenCompetitor({
      id: index,
      open: true,
    });
    dispatch(fetchOrderInnerFormDataCreator(orderId));
  };

  const handleApprovalOrderForm = () => {
    if (orderIds.length === 0) {
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'Please select a order to Approve!',
        ToastType.ERROR,
      );
    }
    let apiCall = false;
    let relevantOrders = [];

    if (tableData?.orderList && orderIds.length > 0) {
      // Filter the orders that match the selected orderIds
      relevantOrders = tableData.orderList.filter((data: any) => {
        if (!data.isSynched) {
          return orderIds.includes(data.orderId); // Check if orderId is in the selected orderIds array
        }
      });

      // Now check if every relevant order is synced
      const isAllSynced = relevantOrders.every((data: any) => data.isSynched);

      if (isAllSynced) {
        setIsAllSync(true);
        apiCall = true;
      } else {
        setIsAllSync(false); // Set to true if any are not synced
        apiCall = false;
      }
    }

    if (orderIds.length > 0) {
      if (location.pathname === '/ho-admin') {
        if (tableData?.orderList.length === orderIds.length) {
          setSyncApproveChecking((previousValue: any) => ({
            ...previousValue,
            isAllSelected: true,
            type: 'approve',
          }));
        } else {
          setSyncApproveChecking((previousValue: any) => ({
            ...previousValue,
            isAllSelected: false,
            type: 'approve',
          }));
        }
        if (apiCall) {
          dispatch(
            fetchOrderProductApprovalCreator(
              userLoginInfo?.staffPositionId,
              userLoginInfo?.userId,
              orderIds,
              'ho',
            ),
          );
          setSyncApproveChecking({
            isAllSelected: false,
            isAllSynced: false,
            approveCheckList: [],
            type: 'approve',
          });

          const initialCheckBoxState = tableData.orderList.map(
            (data: any, index: number) => ({
              id: index,
              checked: false,
            }),
          );
          setCheckBox(initialCheckBoxState);
          setOrderIds([]);
        } else {
          setSyncApproveChecking({
            ...syncApproveChecking,
            isAllSynced: false,
            approveCheckList: relevantOrders.map((order: any) => ({
              orderNumber: order.orderFormNo, // Get orderFormNo as orderNumber
              message: 'Order is not synced for today, please sync.', // Use sapOrderRemarks or a default message
            })),
          });
          const initialCheckBoxState = tableData.orderList.map(
            (data: any, index: number) => ({
              id: index,
              checked: false,
            }),
          );
          setCheckBox(initialCheckBoxState);
          setOrderIds([]);
        }
        setApproveModalOpen(!approveModalOpen);
      } else {
        dispatch(
          fetchOrderProductApprovalCreator(
            userLoginInfo?.staffPositionId,
            userLoginInfo?.userId,
            orderIds,
            'fc',
          ),
        );
      }
    }
  };

  const handleSyncOrder = (id: number | number[], type: string) => {
    if (orderIds.length === 0 && type !== 'single') {
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'Please select a order to Sync!',
        ToastType.ERROR,
      );
    } else {
      setSyncApproveChecking({
        isAllSelected: false,
        isAllSynced: false,
        approveCheckList: [],
        type: 'sync',
      });
      if (type === 'single') {
        dispatch(
          fetchOrderSyncCreator(
            [id],
            {
              ...orderFormPayload,
              currentStaffPositionId: userLoginInfo?.staffPositionId,
            },
            1,
            'ho',
          ),
        );
      } else {
        dispatch(
          fetchOrderSyncCreator(
            id,
            {
              ...orderFormPayload,
              currentStaffPositionId: userLoginInfo?.staffPositionId,
            },
            1,
            'ho',
          ),
        );
      }
      setApproveModalOpen(!approveModalOpen);
      const initialCheckBoxState = tableData.orderList.map(
        (data: any, index: number) => ({
          id: index,
          checked: false,
        }),
      );
      setCheckBox(initialCheckBoxState);
      setOrderIds([]);
    }
  };

  const showTooltipErrorMsg = (id: number) => {
    document.body.style.overflow = 'hidden';
    dispatch(fetchErrorInfoMsgCreator(id));
  };

  useEffect(() => {
    dispatch(
      updateRequestPayloadOrder({
        ...orderFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
      }),
    );
    if (location.pathname === '/ho-admin') {
      dispatch(
        fetchOrderFormDataCreator(
          {
            ...orderFormPayload,
            currentStaffPositionId: userLoginInfo?.staffPositionId,
            pageSize: rowsPerPage,
          },
          1,
          'ho',
        ),
      );
    } else {
      dispatch(
        updateRequestPayloadOrder({
          ...orderFormPayload,
          currentStaffPositionId: userLoginInfo?.staffPositionId,
          type: userTab,
        }),
      );
      dispatch(
        fetchOrderFormDataCreator(
          {
            ...orderFormPayload,
            currentStaffPositionId: userLoginInfo?.staffPositionId,
            pageSize: rowsPerPage,
            type: userTab,
          },
          1,
          'fc',
        ),
      );
    }

    if (tableData?.orderList) {
      if (tableData?.orderList) {
        // Filter the orders that match the selected orderIds
        const relevantOrders = tableData.orderList.filter((data: any) => {
          return orderIds.includes(data.orderId); // Check if orderId is in the selected orderIds array
        });

        // Now check if every relevant order is synced
        const isAllSynced = relevantOrders.every((data: any) => data.isSynched);

        if (isAllSynced) {
          setIsAllSync(false); // Set to false if all are synced
        } else {
          setIsAllSync(true); // Set to true if any are not synced
        }
      }

      const initialCheckBoxState = tableData.orderList.map(
        (data: any, index: number) => ({
          id: index,
          checked: false,
        }),
      );
      setCheckBox(initialCheckBoxState);
    }

    dispatch(setStatusToggle(1));
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (tableData?.orderList) {
      if (tableData?.orderList) {
        // Filter the orders that match the selected orderIds
        const relevantOrders = tableData.orderList.filter((data: any) => {
          return orderIds.includes(data.orderId); // Check if orderId is in the selected orderIds array
        });

        // Now check if every relevant order is synced
        const isAllSynced = relevantOrders.every((data: any) => data.isSynched);

        if (isAllSynced) {
          setIsAllSync(false); // Set to false if all are synced
        } else {
          setIsAllSync(true); // Set to true if any are not synced
        }
      }
      const initialCheckBoxState = tableData.orderList.map(
        (data: any, index: number) => ({
          id: index,
          checked: false,
        }),
      );
      setCheckBox(initialCheckBoxState);
    }
  }, [tableData]);

  useEffect(() => {
    if (isMounted) {
      if (location.pathname === '/ho-admin') {
        dispatch(
          fetchOrderFormDataCreator(
            {
              ...orderFormPayload,
              currentStaffPositionId: userLoginInfo?.staffPositionId,
            },
            1,
            'ho',
          ),
        );
      }
    }

    if (isMounted) {
      dispatch(
        fetchOrderFormDataCreator(
          {
            ...orderFormPayload,
            currentStaffPositionId: userLoginInfo?.staffPositionId,
            pageSize: rowsPerPage,
            tabType: statusToggleNo,
            type: userTab,
          },
          1,
          'fc',
        ),
      );
    }

    if (
      orderId.current !== undefined &&
      orderId.current !== null &&
      orderId.current !== ''
    ) {
      dispatch(fetchOrderFormProductDataCreator(orderId.current));
      dispatch(fetchOrderFormApprovalDataCreator(orderId.current));
    }

    if (tableData?.orderList) {
      const initialCheckBoxState = tableData.orderList.map(
        (data: any, index: number) => ({
          id: index,
          checked: false,
        }),
      );
      setCheckBox(initialCheckBoxState);
    }
    setOrderIds([]);
  }, [ApprovalOrderComplete]);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
    setUserTab(1);

    dispatch(
      updateRequestPayloadOrder({
        ...orderFormPayload,
        type: 1,
      }),
    );
    setOrderIds([]);
    setOpenCompetitor({id: 0, open: false});
  }, [statusToggleNo]);

  const handleOpenReviewPopup = (orderId: number) => {
    handleToggleModal();
    setOpenTooltipId(null);
    dispatch(fetchOrderFormProductDataCreator(orderId));
    dispatch(fetchOrderFormApprovalDataCreator(orderId));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);

    dispatch(
      updateRequestPayloadOrder({
        ...orderFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
        tabType: statusToggleNo,
        pageSize: rowsPerPage,
        type: userTab,
        pageNumber: newPage, // API expects one-based page number
      }),
    );

    if (location.pathname === '/ho-admin') {
      dispatch(
        fetchOrderFormDataCreator(
          {
            ...orderFormPayload,
            currentStaffPositionId: userLoginInfo?.staffPositionId,
            pageNumber: newPage,
            pageSize: rowsPerPage,
          },
          statusToggleNo,
          'ho',
        ),
      );
    } else {
      dispatch(
        fetchOrderFormDataCreator(
          {
            ...orderFormPayload,
            currentStaffPositionId: userLoginInfo?.staffPositionId,
            pageSize: rowsPerPage,
            pageNumber: newPage,
            tabType: statusToggleNo,
            type: userTab,
          },
          1,
          'fc',
        ),
      );
    }
  };

  const handleUserTab = (id: number) => {
    setUserTab(id);
    dispatch(
      updateRequestPayloadOrder({
        ...orderFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
        type: id,
      }),
    );
    if (id !== userTab) {
      dispatch(
        fetchOrderFormDataCreator(
          {
            ...orderFormPayload,
            currentStaffPositionId: userLoginInfo?.staffPositionId,
            pageSize: rowsPerPage,
            tabType: statusToggleNo,
            type: id,
          },
          statusToggleNo,
          'fc',
        ),
      );
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newPageSize = parseInt(event.target.value, 10);
    setRowsPerPage(newPageSize);
    setPage(0); // Reset to the first page (zero-based for Material-UI)

    if (location.pathname === '/ho-admin') {
      dispatch(
        fetchOrderFormDataCreator(
          {
            ...orderFormPayload,
            currentStaffPositionId: userLoginInfo?.staffPositionId,
            pageNumber: 0,
            pageSize: newPageSize,
          },
          statusToggleNo,
          'ho',
        ),
      );
    } else {
      dispatch(
        fetchOrderFormDataCreator(
          {
            ...orderFormPayload,
            currentStaffPositionId: userLoginInfo?.staffPositionId,
            pageNumber: 0,
            pageSize: newPageSize,
            tabType: statusToggleNo,
            type: userTab,
          },
          statusToggleNo,
          'fc',
        ),
      );
    }

    dispatch(
      updateRequestPayloadOrder({
        ...orderFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
        pageSize: newPageSize,
        tabType: statusToggleNo,
        type: 1,
        pageNumber: 0, // API expects one-based page number
      }),
    );
  };

  return (
    <Container
      sx={{
        marginTop: '10px',
        // position: 'relative',
        maxWidth: '100% !important',
        padding: '0px  !important',
      }}>
      <Box
        sx={{display: openTooltipId !== null ? 'block' : 'none'}}
        className={classes.tooltipOverlay}></Box>
      <Stack
        marginBottom="12px"
        direction="row"
        justifyContent={screenNo === 1 ? 'space-between' : 'flex-end'}
        alignItems="center">
        {screenNo === 1 && statusToggleNo === 1 && (
          <Stack direction="row" gap={2}>
            <Chip
              sx={{
                display: 'flex',
                minWidth: '85px',
                justifyContent: 'space-evenly',
                fontSize: '11.7px',
                height: '32px',
              }}
              data-testid="product-chip"
              label={strings.moduleSpecificUtilities.all}
              color="primary"
              variant={userTab === 0 ? 'filled' : 'outlined'}
              onClick={() => handleUserTab(0)}
              onDelete={() => handleUserTab(0)}
              deleteIcon={
                userTab === 0 ? (
                  <CheckCircleIcon
                    sx={{
                      fill: '#ffffff',
                      borderRadius: '3000px',
                      backgroundColor: `${theme.colors.primary}`,
                      height: '16px',
                      width: '16px',
                    }}
                  />
                ) : (
                  <></>
                )
              }
            />

            <Chip
              sx={{
                display: 'flex',
                minWidth: '85px',
                justifyContent: 'space-evenly',
                fontSize: '11.7px',
                height: '32px',
              }}
              data-testid="product-chip"
              label={strings.zho.self}
              color="primary"
              variant={userTab === 1 ? 'filled' : 'outlined'}
              onClick={() => handleUserTab(1)}
              onDelete={() => handleUserTab(1)}
              deleteIcon={
                userTab === 1 ? (
                  <CheckCircleIcon
                    sx={{
                      fill: '#ffffff',
                      borderRadius: '3000px',
                      backgroundColor: `${theme.colors.primary}`,
                      height: '16px',
                      width: '16px',
                    }}
                  />
                ) : (
                  <></>
                )
              }
            />
            <Chip
              sx={{
                display: 'flex',
                minWidth: '85px',
                justifyContent: 'space-evenly',
                fontSize: '11.7px',
                height: '32px',
              }}
              data-testid="product-chip"
              label={strings.zho.other}
              color="primary"
              variant={userTab === 2 ? 'filled' : 'outlined'}
              onClick={() => handleUserTab(2)}
              onDelete={() => handleUserTab(2)}
              deleteIcon={
                userTab === 2 ? (
                  <CheckCircleIcon
                    sx={{
                      fill: '#ffffff',
                      borderRadius: '3000px',
                      backgroundColor: `${theme.colors.primary}`,
                      height: '16px',
                      width: '16px',
                    }}
                  />
                ) : (
                  <></>
                )
              }
            />
          </Stack>
        )}

        {screenNo === 2 && statusToggleNo === 1 && (
          <Box>
            <Button
              data-testid="button-approve"
              variant="contained"
              className={classes.syncButton}
              onClick={() => {
                if (!syncLoading) {
                  handleSyncOrder(orderIds, 'all');
                }
              }}>
              {strings.zho.hoAdmin.dashboard.sync}
            </Button>
            <Button
              data-testid="button-approve"
              variant="contained"
              className={classes.applyButton}
              onClick={handleApprovalOrderForm}>
              {strings.approve}
            </Button>
          </Box>
        )}

        {screenNo === 1 && statusToggleNo === 1 && userTab === 1 && (
          <Box>
            <Button
              data-testid="button-approve"
              variant="contained"
              className={classes.applyButton}
              onClick={handleApprovalOrderForm}>
              {strings.approve}
            </Button>
          </Box>
        )}
      </Stack>

      {!orderFormDataLoader ? (
        <>
          <TableContainer className={`${classes.noBoxShd}`} component={Paper}>
            <Table sx={{borderCollapse: 'separate', borderSpacing: '0px 1px'}}>
              <TableHead>
                <TableRow className={`${classes.requestformTableHeading}   `}>
                  {statusToggleNo === 1 &&
                    tableData?.orderList?.length > 0 &&
                    location.pathname === '/fc-admin' &&
                    userTab === 1 && (
                      <TableCell
                        sx={{
                          border: 'none',
                          padding: '0px !important',
                          // position: 'sticky',
                          // left: '0px',
                          // background: '#f4f4f4',
                        }}>
                        <Checkbox
                          sx={{color: '#322b7c !important'}}
                          data-testid="check-all-requestform"
                          checked={
                            checkBox?.length > 0 &&
                            checkBox.every(
                              (data: any) => data?.checked === true,
                            )
                          }
                          onClick={() => toggleCheckBox('all', 0)}
                        />
                      </TableCell>
                    )}

                  {statusToggleNo === 1 &&
                    tableData?.orderList?.length > 0 &&
                    location.pathname === '/ho-admin' && (
                      <TableCell
                        sx={{
                          border: 'none',
                          padding: '0px !important',
                          // position: 'sticky',
                          // left: '0px',
                          // background: '#f4f4f4',
                        }}>
                        <Checkbox
                          // disabled={isAllSync}
                          // sx={{
                          //   color: `${
                          //     isAllSync
                          //       ? '#bebebf !important'
                          //       : '#322b7c !important'
                          //   }`,
                          //   cursor: 'pointer !important',
                          //   pointerEvents: 'auto !important',
                          // }}
                          sx={{color: '#322b7c !important'}}
                          data-testid="check-all-requestform"
                          checked={
                            checkBox?.length > 0 &&
                            checkBox.every(
                              (data: any) => data?.checked === true,
                            )
                          }
                          onClick={() => toggleCheckBox('all', 0)}
                        />
                      </TableCell>
                    )}
                  {/* {statusToggleNo !== 1 && (
                    <TableCell
                      sx={{
                        border: 'none',
                        padding: '0px !important',
                        position: 'sticky',
                        left: '0px',
                        background: '#f4f4f4',
                      }}
                    />
                  )} */}
                  <TableCell
                    sx={{
                      border: 'none',
                      minWidth: '160px !important',
                      paddingLeft: '44px !important',
                      // position: 'sticky',
                      // left: statusToggleNo === 1 ? '40px' : '0px',
                      // background: '#f4f4f4',
                    }}
                    className={`${classes.requestformTableHeading}  ${classes.cellPadding} `}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.orderNo}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      minWidth: '160px !important',
                      paddingLeft:
                        statusToggleNo === 1 ? '' : '10px !important',
                    }}
                    className={`${classes.requestformTableHeading}  ${classes.cellPadding}`}>
                    {strings.zho.fcAdmin.dashboard.tableHeading.requestFormNo}
                  </TableCell>

                  <TableCell
                    sx={{border: 'none', padding: '0px', minWidth: '90px'}}
                    className={`${classes.requestformTableHeading}`}>
                    {strings.zho.creationDate}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} ${classes.requestFormHeadingWidth} `}>
                    {strings.zho.candf}
                  </TableCell>

                  <TableCell
                    sx={{
                      border: 'none',
                      minWidth: '80px',
                      padding: '0px',
                      paddingLeft: '10px',
                    }}
                    className={`${classes.requestformTableHeading}`}>
                    {strings.zho.customer}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} ${classes.requestFormHeadingWidth} `}>
                    {strings.hq}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} ${classes.requestFormHeadingWidth} `}>
                    {strings.status}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', textWrap: 'nowrap'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} ${classes.requestFormHeadingWidth} `}>
                    {strings.zho.fcAdmin.dashboard.tableHeading.chemistandDis}
                  </TableCell>

                  {statusToggleNo === 2 && (
                    <TableCell
                      sx={{border: 'none', padding: '0px'}}
                      className={`${classes.requestformTableHeading} ${classes.requestFormHeadingLgWidth} `}>
                      {strings.zho.hoAdmin.dashboard.tableHeading.SapOrderNo}
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      border: 'none',
                      minWidth: '100px',
                      padding: '0px',
                    }}
                    className={classes.requestformTableHeading}>
                    {strings.zho.mrName}
                  </TableCell>

                  {location.pathname === '/ho-admin' && (
                    <TableCell
                      sx={{border: 'none', minWidth: '130px'}}
                      className={`${classes.requestformTableHeading} ${classes.cellPadding} `}>
                      {strings.zho.hoAdmin.dashboard.tableHeading.schemeStatus}
                    </TableCell>
                  )}

                  <TableCell
                    sx={{border: 'none', padding: '0px'}}
                    className={`${classes.requestformTableHeading} `}>
                    {strings.actions}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.orderList?.length > 0 ? (
                  tableData?.orderList?.map((data: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <TableRow className={classes.requestformTbleBoxSdw}>
                          {statusToggleNo === 1 &&
                            location.pathname === '/ho-admin' && (
                              <TableCell
                                className={classes.checkBoxShd}
                                sx={{
                                  border: 'none !important',
                                  padding: '0px !important',
                                  // position: 'sticky',
                                  // left: '0px',
                                  // background: '#f4f4f4',
                                }}>
                                {data?.isSynched ? (
                                  <Checkbox
                                    // disabled={data?.isSynched ? false : true}
                                    // sx={{
                                    //   color: `${
                                    //     data?.isSynched
                                    //       ? '#322b7c !important'
                                    //       : '#bebebf !important'
                                    //   }`,
                                    //   cursor: 'pointer !important',
                                    //   pointerEvents: 'auto !important',
                                    // }}
                                    sx={{color: '#322b7c !important'}}
                                    data-testid="check-requestform"
                                    checked={checkBox[index]?.checked || false}
                                    onClick={() =>
                                      toggleCheckBox(index, data?.orderId)
                                    }
                                  />
                                ) : (
                                  // <LightTooltipNormal
                                  //   placement="top"
                                  //   title={
                                  //     <Box>
                                  //       <Typography
                                  //         fontSize="11.7px"
                                  //         fontWeight="400"
                                  //         variant="caption">
                                  //         {
                                  //           strings.zho.hoAdmin.dashboard
                                  //             .tooltipSync
                                  //         }
                                  //       </Typography>
                                  //     </Box>
                                  //   }>
                                  //   <Checkbox
                                  //     disabled={data?.isSynched ? false : true}
                                  //     sx={{
                                  //       color: `${
                                  //         data?.isSynched
                                  //           ? '#322b7c !important'
                                  //           : '#bebebf !important'
                                  //       }`,
                                  //       cursor: 'pointer !important',
                                  //       pointerEvents: 'auto !important',
                                  //     }}

                                  //     data-testid="check-requestform"
                                  //     checked={
                                  //       checkBox[index]?.checked || false
                                  //     }
                                  //     onClick={() =>
                                  //       toggleCheckBox(index, data?.orderId)
                                  //     }
                                  //   />
                                  // </LightTooltipNormal>
                                  <Checkbox
                                    sx={{color: '#322b7c !important'}}
                                    data-testid="check-requestform"
                                    checked={checkBox[index]?.checked || false}
                                    onClick={() =>
                                      toggleCheckBox(index, data?.orderId)
                                    }
                                  />
                                )}
                              </TableCell>
                            )}

                          {statusToggleNo === 1 &&
                            location.pathname === '/fc-admin' &&
                            userTab === 1 && (
                              <TableCell
                                className={classes.checkBoxShd}
                                sx={{
                                  border: 'none !important',
                                  padding: '0px !important',
                                  // position: 'sticky',
                                  // left: '0px',
                                  // background: '#f4f4f4',
                                }}>
                                <Checkbox
                                  sx={{color: '#322b7c !important'}}
                                  data-testid="check-requestform"
                                  checked={checkBox[index]?.checked || false}
                                  onClick={() =>
                                    toggleCheckBox(index, data?.orderId)
                                  }
                                />
                              </TableCell>
                            )}
                          {/* {statusToggleNo !== 1 && (
                            <TableCell
                              sx={{
                                border: 'none',
                                minWidth: '40px',
                                padding: '0px !important',
                                position: 'sticky',
                                left: '0px',
                                background: '#f4f4f4',
                              }}></TableCell>
                          )} */}
                          <TableCell
                            sx={{
                              padding: '0px 6px !important',
                              minWidth: '160px',
                              // position: 'sticky',
                              // left: statusToggleNo === 1 ? '40px' : '0px',
                              // background: '#f4f4f4',
                            }}
                            className={` ${classes.checkBoxShd} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }  ${classes.requestFormTableFont} ${
                              classes.productTableLeftBorder
                            } ${classes.borderTopLeft} `}>
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center">
                              <Box width="30px" display="inline-block">
                                <IconButton
                                  data-testid="show-prod-details-wrapper"
                                  onClick={e => {
                                    e.stopPropagation();
                                  }}>
                                  {/* <ReplayIcon color="primary" /> */}
                                  {openCompetitor.open &&
                                  openCompetitor.id === index ? (
                                    <RemoveCircleOutlineOutlinedIcon
                                      sx={{
                                        height: '18px',
                                        width: '18px',
                                      }}
                                      color="primary"
                                      data-testid="hide-prod-details"
                                      onClick={() =>
                                        setOpenCompetitor({
                                          id: index,
                                          open: false,
                                        })
                                      }
                                    />
                                  ) : (
                                    <AddCircleOutlineOutlinedIcon
                                      sx={{height: '18px', width: '18px'}}
                                      color="primary"
                                      data-testid="show-prod-details"
                                      onClick={() =>
                                        handleInnerClickData(
                                          data?.orderId,
                                          index,
                                        )
                                      }
                                    />
                                  )}
                                </IconButton>
                              </Box>
                              <Box
                                data-testid="request-form-no-action-btn"
                                onClick={() => {
                                  handleOpenReviewPopup(data?.orderId);
                                  {
                                    location.pathname === '/ho-admin'
                                      ? setIsSynced(data?.isSynched)
                                      : '';
                                  }

                                  setOrderFormId(data?.orderId);
                                  orderId.current = data?.orderId;
                                }}
                                sx={{
                                  fontWeight: '500',
                                  textDecoration: 'underline',
                                  color: theme.colors.primary,
                                  cursor: 'pointer',
                                }}
                                fontSize="11.7px"
                                minWidth="180px"
                                display="inline-block">
                                {data?.orderFormNo}
                              </Box>
                            </Stack>
                          </TableCell>

                          <TableCell
                            sx={{
                              wordBreak: 'break-all !important',
                              minWidth: '180px',
                              paddingLeft:
                                statusToggleNo === 1 ? '' : '10px !important',
                            }}
                            className={`${classes.checkBoxShd}  ${
                              classes.cellPadding
                            } ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }   ${classes.requestFormTableFont}`}>
                            {data?.requestFormNo}
                          </TableCell>

                          <TableCell
                            sx={{padding: '0px'}}
                            className={` ${classes.checkBoxShd} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }   ${classes.requestFormTableFont}`}>
                            {DateFormat(data?.createdOn)}
                          </TableCell>

                          <TableCell
                            className={` ${classes.cellPadding} ${
                              classes.checkBoxShd
                            } ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }   ${classes.requestFormTableFont}`}>
                            {data?.cAndFId}
                          </TableCell>

                          <TableCell
                            style={{
                              minWidth: '80px',
                              padding: '0px',
                              paddingLeft: '10px',
                            }}
                            className={` ${classes.checkBoxShd}  ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }   ${classes.requestFormTableFont}`}>
                            {data?.customer}
                          </TableCell>
                          <TableCell
                            className={` ${classes.checkBoxShd} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }   ${classes.requestFormTableFont} ${
                              classes.cellPadding
                            } `}>
                            {data?.hq}
                          </TableCell>
                          <TableCell
                            style={{
                              color: getStatusColor(data?.statusCode),
                              fontWeight: '600',
                              padding: '10px !important',
                              minWidth: '90px',
                            }}
                            className={` ${classes.cellPadding}  ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            } ${classes.requestFormTableFont}`}>
                            <Stack direction="row" alignItems="center" gap={1}>
                              <Typography
                                className={classes.requestFormTableFont}
                                style={{
                                  color: getStatusColor(data?.statusCode),
                                  padding: '10px !important',
                                }}>
                                {' '}
                                {data?.status}
                              </Typography>

                              {data?.statusCode === 2 &&
                                location.pathname === '/ho-admin' && (
                                  <Stack
                                    alignItems="center"
                                    justifyContent="center">
                                    {data?.status !== 'Rejected by SAP' ? (
                                      <LightTooltipNormal
                                        title={
                                          <>
                                            <Typography
                                              fontSize="11.7px"
                                              fontWeight="400"
                                              variant="caption"
                                              key={index}>
                                              {data?.pendingWith}
                                            </Typography>
                                          </>
                                        }>
                                        <InfoOutlinedIcon
                                          data-testid="show-customers"
                                          sx={{
                                            height: '15px',
                                            width: '15px',
                                            color: '#da3131',
                                          }}
                                          color="primary"
                                        />
                                      </LightTooltipNormal>
                                    ) : null}
                                  </Stack>
                                )}

                              {data?.statusCode === 4 &&
                                location.pathname === '/fc-admin' && (
                                  <Stack
                                    alignItems="center"
                                    justifyContent="center">
                                    <LightTooltipNormal
                                      title={
                                        <>
                                          <Typography
                                            fontSize="11.7px"
                                            fontWeight="400"
                                            variant="caption"
                                            key={index}>
                                            {data?.pendingAt}
                                          </Typography>
                                        </>
                                      }>
                                      <InfoOutlinedIcon
                                        data-testid="show-customers"
                                        sx={{height: '15px', width: '15px'}}
                                        color="primary"
                                      />
                                    </LightTooltipNormal>
                                  </Stack>
                                )}
                            </Stack>
                          </TableCell>
                          <TableCell
                            className={` ${classes.checkBoxShd}  ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }  ${classes.requestFormTableFont} ${
                              classes.cellPadding
                            }`}>
                            {data?.chemist}
                          </TableCell>

                          {statusToggleNo === 2 && (
                            <TableCell
                              sx={{padding: '0px !important'}}
                              className={`  ${classes.requestFormTableFont}  ${
                                isRowHighlighted(index, tableData)
                                  ? classes.highlightBorderwithStatus
                                  : ''
                              } `}>
                              {data?.sapOrderNo === null
                                ? '---'
                                : data?.sapOrderNo}
                            </TableCell>
                          )}

                          <TableCell
                            sx={{padding: '0px !important'}}
                            className={` ${classes.checkBoxShd} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }  ${classes.requestFormTableFont}`}>
                            {data?.mrName}
                          </TableCell>
                          {location.pathname === '/ho-admin' && (
                            <TableCell
                              sx={{
                                minWidth: '130px',
                                color:
                                  data?.schemeStatus === 'Active'
                                    ? '#01a685'
                                    : '#f45b60',
                                fontWeight: '600',
                              }}
                              className={` ${classes.checkBoxShd} ${
                                isRowHighlighted(index, tableData)
                                  ? classes.highlightBorderwithStatus
                                  : ''
                              }  ${classes.requestFormTableFont}`}>
                              {data?.schemeStatus}
                            </TableCell>
                          )}

                          <TableCell
                            sx={{
                              paddingLeft: '0px !important',

                              '& .MuiTooltip-tooltip': {
                                marginTop: `${
                                  ToolTipErrorList.length > 2
                                    ? '-123px !important'
                                    : '14px !important'
                                }`,
                              },
                              // position: 'relative',
                            }}
                            className={` ${classes.checkBoxShd}  ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }
                              ${classes.requestFormTableFont}  ${
                              classes.borderTopRight
                            } ${classes.productTableRightBorder}`}>
                            {isRowHighlighted(index, tableData) && (
                              <Box
                                sx={{
                                  '& .base-Popper-root': {
                                    zIndex: '9999',
                                  },

                                  '& .MuiTooltip-tooltip': {
                                    marginTop: `${
                                      ToolTipErrorList.length > 2
                                        ? '-123px !important'
                                        : '12px !important'
                                    }`,
                                  },
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  // position: 'absolute',
                                  // right: '12px',
                                  // top: '3px',
                                }}>
                                <ClickAwayListener
                                  onClickAway={handleTooltipClose}>
                                  <Box
                                    sx={{
                                      '& .MuiTooltip-popper': {
                                        zIndex: '9999',
                                      },
                                    }}
                                    onClick={event => {
                                      event.stopPropagation();
                                      if (openTooltipId !== data.orderId) {
                                        setTimeout(() => {
                                          setOpenTooltipId(
                                            openTooltipId === data.orderId
                                              ? null
                                              : data.orderId,
                                          );
                                        }, 100);
                                        setOpenTooltipId(null);
                                      }
                                    }}>
                                    <LightTooltip
                                      PopperProps={{
                                        disablePortal: true,
                                        modifiers: [
                                          {
                                            name: 'offset',
                                            options: {
                                              offset: [0, -140], // Adjust the second value to move the tooltip up
                                            },
                                          },
                                        ],
                                      }}
                                      onClose={handleTooltipClose}
                                      open={openTooltipId === data?.orderId}
                                      disableFocusListener
                                      disableHoverListener
                                      disableTouchListener
                                      title={
                                        <>
                                          {openTooltipId !== null && (
                                            <>
                                              <Box
                                                sx={{
                                                  height: 'auto',
                                                  maxHeight: '250px',
                                                  minHeight: '70px',
                                                  overflowY: 'auto',
                                                  paddingRight: '1.2rem',
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  justifyContent: 'center',
                                                  alignItems: 'flex-start',
                                                }}
                                                position="relative">
                                                {!ToolTipErrorListLoading ? (
                                                  ToolTipErrorList.map(
                                                    (
                                                      data: any,
                                                      index: number,
                                                    ) => {
                                                      return (
                                                        <Stack
                                                          borderBottom={1}
                                                          borderColor={
                                                            'lightgray'
                                                          }
                                                          marginTop={2}
                                                          marginBottom={0.4}
                                                          key={index}
                                                          direction="column"
                                                          gap={0.2}>
                                                          <Typography
                                                            fontSize="11.7px"
                                                            color="primary"
                                                            fontWeight="400"
                                                            variant="caption">
                                                            {data}
                                                          </Typography>
                                                        </Stack>
                                                      );
                                                    },
                                                  )
                                                ) : (
                                                  <Box
                                                    className={classes.loader}
                                                    sx={[
                                                      {
                                                        display: 'flex',
                                                        justifyContent:
                                                          'center',
                                                        mt: 3,
                                                      },
                                                    ]}>
                                                    <CircularProgress />
                                                  </Box>
                                                )}
                                              </Box>
                                              <svg
                                                onClick={handleTooltipClose}
                                                style={{
                                                  position: 'absolute',
                                                  top: '6px',
                                                  right: '6px',
                                                }}
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <rect
                                                  width="24"
                                                  height="24"
                                                  rx="12"
                                                  fill="#E5E5E5"
                                                />
                                                <path
                                                  d="M12 10.8891L15.8891 7L17 8.11094L13.1109 12L17 15.8891L15.8891 17L12 13.1109L8.11094 17L7 15.8891L10.8891 12L7 8.11094L8.11094 7L12 10.8891Z"
                                                  fill="#4F4F4F"
                                                />
                                              </svg>
                                            </>
                                          )}
                                        </>
                                      }>
                                      <svg
                                        onClick={() =>
                                          showTooltipErrorMsg(data?.orderId)
                                        }
                                        style={{cursor: 'pointer'}}
                                        // style={{
                                        //   transform: 'translate(57px,-5px)',
                                        // }}
                                        width="13"
                                        height="13"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_222_5448)">
                                          <path
                                            d="M7.87112 13.6799C6.24338 13.6799 4.6823 13.0333 3.53131 11.8823C2.38032 10.7313 1.73371 9.17025 1.73371 7.5425C1.73371 5.91476 2.38032 4.35369 3.53131 3.2027C4.6823 2.05171 6.24338 1.40509 7.87112 1.40509C9.49886 1.40509 11.0599 2.05171 12.2109 3.2027C13.3619 4.35369 14.0085 5.91476 14.0085 7.5425C14.0085 9.17025 13.3619 10.7313 12.2109 11.8823C11.0599 13.0333 9.49886 13.6799 7.87112 13.6799ZM7.87112 14.5567C9.7314 14.5567 11.5155 13.8177 12.8309 12.5023C14.1463 11.1869 14.8853 9.40278 14.8853 7.5425C14.8853 5.68223 14.1463 3.89814 12.8309 2.58273C11.5155 1.26731 9.7314 0.52832 7.87112 0.52832C6.01084 0.52832 4.22676 1.26731 2.91134 2.58273C1.59593 3.89814 0.856934 5.68223 0.856934 7.5425C0.856934 9.40278 1.59593 11.1869 2.91134 12.5023C4.22676 13.8177 6.01084 14.5567 7.87112 14.5567Z"
                                            fill="#DA3131"
                                          />
                                          <path
                                            d="M8.68667 6.30464L6.67886 6.55628L6.60696 6.88945L7.00151 6.96222C7.25928 7.0236 7.31014 7.11654 7.25402 7.37343L6.60696 10.4141C6.43687 11.2005 6.69902 11.5705 7.3154 11.5705C7.79324 11.5705 8.34824 11.3496 8.59987 11.0462L8.67702 10.6815C8.50167 10.8358 8.24565 10.8972 8.07556 10.8972C7.83445 10.8972 7.74677 10.728 7.80902 10.4299L8.68667 6.30464ZM8.74804 4.47394C8.74804 4.70648 8.65567 4.92949 8.49124 5.09391C8.32682 5.25834 8.1038 5.35071 7.87127 5.35071C7.63874 5.35071 7.41572 5.25834 7.2513 5.09391C7.08687 4.92949 6.9945 4.70648 6.9945 4.47394C6.9945 4.24141 7.08687 4.0184 7.2513 3.85397C7.41572 3.68954 7.63874 3.59717 7.87127 3.59717C8.1038 3.59717 8.32682 3.68954 8.49124 3.85397C8.65567 4.0184 8.74804 4.24141 8.74804 4.47394Z"
                                            fill="#DA3131"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_222_5448">
                                            <rect
                                              width="14.0284"
                                              height="14.0284"
                                              fill="white"
                                              transform="translate(0.856934 0.52832)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </LightTooltip>
                                  </Box>
                                </ClickAwayListener>
                              </Box>
                            )}
                            <Stack
                              height={20}
                              direction="row"
                              justifyContent="center"
                              alignItems="center">
                              {statusToggleNo === 1 &&
                                location.pathname === '/ho-admin' && (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                    textAlign="center"
                                    paddingLeft={0.3}
                                    paddingRight={0.3}
                                    className={`${classes.orderIconBordeLeft}`}
                                    onClick={() => {
                                      if (!syncLoading) {
                                        handleSyncOrder(
                                          data?.orderId,
                                          'single',
                                        );
                                      }
                                    }}>
                                    <LightTooltipNormal
                                      title={
                                        <Box>
                                          <Typography
                                            fontSize="11.7px"
                                            fontWeight="400"
                                            variant="caption">
                                            Sync
                                          </Typography>
                                        </Box>
                                      }>
                                      <svg
                                        style={{
                                          cursor: !syncLoading
                                            ? 'pointer'
                                            : 'not-allowed',
                                        }}
                                        width="18"
                                        height="20"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M19.9644 12.877C19.9644 10.9793 19.3623 9.56765 17.9517 8.158C17.5708 7.77806 16.9551 7.77806 16.5742 8.158C16.1933 8.53891 16.1933 9.15557 16.5742 9.53648C17.6253 10.5867 18.016 11.4917 18.016 12.877C18.016 14.3081 17.4587 15.6534 16.4466 16.6646C15.4685 17.6418 14.4222 18.1279 13.107 18.2165L14.3209 17.0017C14.7018 16.6208 14.7018 16.0051 14.3209 15.6242C13.94 15.2433 13.3243 15.2433 12.9434 15.6242L9.33203 19.2355L12.9434 22.8469C13.1333 23.0368 13.3827 23.1323 13.6321 23.1323C13.8815 23.1323 14.1309 23.0368 14.3209 22.8469C14.7018 22.466 14.7018 21.8503 14.3209 21.4694L13.0184 20.1678C14.8917 20.088 16.469 19.3963 17.8241 18.0431C19.2045 16.6637 19.9644 14.8293 19.9644 12.877Z"
                                          fill="#322B7C"
                                        />
                                        <path
                                          d="M7.30044 12.9031C7.30044 11.472 7.85767 10.1266 8.86986 9.11446C9.85282 8.1315 10.9079 7.64538 12.2376 7.56159L10.9955 8.80369C10.6146 9.1846 10.6146 9.80029 10.9955 10.1812C11.1855 10.3721 11.4349 10.4676 11.6843 10.4676C11.9337 10.4676 12.1831 10.3721 12.3731 10.1822L15.9844 6.57084L12.3731 2.95951C11.9921 2.5786 11.3765 2.5786 10.9955 2.95951C10.6146 3.34042 10.6146 3.95611 10.9955 4.33702L12.2727 5.61418C10.4081 5.69796 8.83868 6.38964 7.49235 7.73695C6.11192 9.11641 5.35205 10.9508 5.35205 12.9031C5.35205 14.8008 5.9541 16.2124 7.36473 17.6221C7.5547 17.812 7.80409 17.9065 8.05349 17.9065C8.30288 17.9065 8.55227 17.8111 8.74224 17.6211C9.12315 17.2402 9.12315 16.6235 8.74224 16.2426C7.69109 15.1934 7.30044 14.2884 7.30044 12.9031Z"
                                          fill="#322B7C"
                                        />
                                      </svg>
                                    </LightTooltipNormal>
                                  </Box>
                                )}
                              <Stack
                                height={16}
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Box
                                  paddingLeft={0.3}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                  textAlign="center"></Box>

                                <Typography
                                  onClick={() => {
                                    handleOpenReviewPopup(data?.orderId);
                                    {
                                      location.pathname === '/ho-admin'
                                        ? setIsSynced(data?.isSynched)
                                        : '';
                                    }
                                    setOrderFormId(data?.orderId);
                                    orderId.current = data?.orderId;
                                  }}
                                  data-testid="review-request"
                                  sx={{fontSize: '11.7px', cursor: 'pointer'}}
                                  variant="subtitle2"
                                  color="primary">
                                  {strings.zho.review}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                        </TableRow>
                        {openCompetitor.open && openCompetitor.id === index && (
                          <TableRow sx={{position: 'relative'}}>
                            {!orderInnerFromLoading ? (
                              <TableCell
                                sx={{
                                  padding:
                                    statusToggleNo === 1
                                      ? '0px 0px 8px 40px'
                                      : '0px 0px 8px 0px',
                                  border: 'none !important',
                                }}
                                colSpan={7}>
                                <OrderProductDetails
                                  orderInnerFormData={orderInnerFormData}
                                  hide={true}
                                />
                              </TableCell>
                            ) : (
                              <TableCell sx={{minHeight: '50px'}}>
                                <Box
                                  className={classes.loader}
                                  sx={[
                                    {
                                      display: 'flex',
                                      justifyContent: 'center',
                                    },
                                  ]}>
                                  <CircularProgress />
                                </Box>
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                      </Fragment>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell sx={{textAlign: 'center'}} colSpan={11}>
                      Currently there are no Orders!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={
              location.pathname === '/ho-admin' && statusToggleNo === 1
                ? [10]
                : [10, 20, 50, 100]
            }
            count={+tableData?.totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={
              location.pathname === '/ho-admin' && statusToggleNo === 1
                ? 10
                : rowsPerPage
            }
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <TableContainer sx={{height: '90px'}}>
          <Table>
            <TableBody>
              <TableRow>
                <Box
                  // className={classes.loader}
                  sx={[
                    {
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 3,
                    },
                  ]}>
                  <CircularProgress />
                </Box>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default OrderFormTable;
