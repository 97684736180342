import { useEffect, useState } from "react";
import TASummaryReport from "@app/screens/module-specific-utilities/components/expense-reports/TA-summary/TASummaryReport";
import TASummaryReportHeader from "@app/screens/module-specific-utilities/components/expense-reports/TA-summary/TASummaryReportHeader";
import { Button, Container } from "@mui/material";
import {useDispatch} from 'react-redux';
import {useStyles} from '@app/screens/Styles/style';
import {routeStateActions} from '@app/router/redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { strings } from "@app/common/strings";

const TASummaryReportContent: React.FC<{setReportBack: any}> = ({setReportBack}) => {
  const classes = useStyles();
    const [report, setReport] = useState(false);
  const dispatch = useDispatch();


    useEffect(() => {
      dispatch(
        routeStateActions.setNavbarComponentHeading(
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <Button
              onClick={() => setReportBack('')}
              style={{alignSelf: 'flex-start'}}>
              <ArrowBackIosIcon />
            </Button>
            <div
              className={classes.navbarComponentHeadingEmpty}
              style={{padding: 4}}>
              {strings.ModuleSpecificUtilitie.expenseReportPage.TA_Summary}
            </div>
          </div>,
        ),
      );
      return () => {
        dispatch(
          routeStateActions.setNavbarComponentHeading(
            <div className={classes.navbarComponentHeadingEmpty}>
               {strings.ModuleSpecificUtilitie.expenseReportPage.expenseReport}
            </div>,
          ),
        );
      };
    }, []);
    return (
      <Container maxWidth={false} style={{width: '100%'}}>
        <TASummaryReportHeader setReport={setReport} />
        {report && <TASummaryReport />}
      </Container>
    );
}

export default TASummaryReportContent;