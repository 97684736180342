import {strings} from '@app/common/strings';
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import {useStyles} from '../styles';
import {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import {
  fetchManageProductDeleteCreator,
  fetchManageProductPopupDetailsCreator,
  fetchManageProductTableCreator,
} from '../../redux-api/slice';
import dateUtils from '@app/screens/zho/utils/dateFormat';
import {updateRequestPayload} from '../../redux/tabName';
const ManageProductView = ({
  userLoginInfo,
  handlePageChange,
  pageNo,
  setPageNo,
}: any) => {
  const [isUnblockModelOpen, setIsUnblockModelOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<null | number>(null);
  const [openTooltipId, setOpenTooltipId] = useState<number | null>(null);
  const {DateFormat} = dateUtils;
  const handleTooltipClose = () => {
    setOpenTooltipId(null);
  };

  const requestPayload = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.requestPayload,
  );

  // const handleTooltipOpen = (id: number) => {
  //   setOpenTooltipId(id);
  // };

  const classes = useStyles();
  const dispatch = useDispatch();
  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };
  const enableScroll = () => {
    document.body.style.overflow = 'auto';
  };

  const MangeProductData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageProductTableData,
  );

  const manageproductLoader = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageProductTableLoading,
  );

  const popupData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.manageProductPopupData,
  );

  const mangeProductTableCount = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.mangeProductTableCount,
  );

  const LightTooltip = styled(({className, ...props}: any) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      // boxShadow: theme.shadows[1],
      fontSize: 11.7,
      border: '1px solid #dcdce0',
      borderRadius: '8px',
      padding: '1rem',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    },
  }));

  useEffect(() => {
    dispatch(
      fetchManageProductTableCreator({
        currentStaffPositionId: userLoginInfo?.staffPositionId,
        pageSize: 10,
        pageNumber: requestPayload.pageNumber,
      }),
    );

    return () => {
      setIsUnblockModelOpen(false);
    };
  }, []);

  // setTotalCount(Math.ceil(MangeProductData?.totalCount / 10));

  useEffect(() => {
    dispatch(
      fetchManageProductTableCreator({
        currentStaffPositionId: userLoginInfo?.staffPositionId,
        pageSize: 10,
        pageNumber: requestPayload.pageNumber,
      }),
    );
  }, [requestPayload]);

  const handleToggleModal = (id: number | null, type: string) => {
    if (type === 'call') {
      dispatch(
        updateRequestPayload({
          ...requestPayload,
          pageNumber: 0,
        }),
      );

      dispatch(
        fetchManageProductDeleteCreator(
          id,
          {
            currentStaffPositionId: userLoginInfo?.staffPositionId,
            pageSize: 10,
            pageNumber: 0,
          },
          userLoginInfo?.userId,
        ),
      );
      if (MangeProductData?.list?.length === 1) {
        setPageNo(0);
      }
    }
    setIsUnblockModelOpen(!isUnblockModelOpen);
  };

  const ManageProductDataView = () => {
    return (
      <>
        {MangeProductData?.list?.map((data: any, index: number) => (
          <TableRow
            key={index}
            sx={{
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
            }}
            className={`${
              (index + 1) % 2 == 0
                ? classes.manageProTableEven
                : classes.manageProTableOdd
            }`}>
            <TableCell
              sx={{paddingLeft: '15px !important'}}
              className={`${classes.manageProTableBodyCell}  `}>
              <Typography
                marginBottom="0px"
                paragraph={true}
                display="inline-block"
                fontWeight="400"
                color="#1c1939"
                fontSize="12.7px"
                variant="subtitle2">
                {data?.divisionName}
              </Typography>
            </TableCell>
            <TableCell className={classes.manageProTableBodyCell}>
              <Typography
                marginBottom="0px"
                paragraph={true}
                display="inline-block"
                fontWeight="400"
                color="#1c1939"
                fontSize="12.7px"
                variant="subtitle2">
                {data?.productName}
              </Typography>
            </TableCell>
            <TableCell className={classes.manageProTableBodyCell}>
              <Typography
                marginBottom="0px"
                paragraph={true}
                display="inline-block"
                fontWeight="400"
                color="#1c1939"
                fontSize="12.7px"
                variant="subtitle2">
                {data?.cAndFName}
              </Typography>
            </TableCell>
            <TableCell className={classes.manageProTableBodyCell}>
              <Typography
                marginBottom="0px"
                paragraph={true}
                display="inline-block"
                fontWeight="400"
                color="#1c1939"
                fontSize="12.7px"
                variant="subtitle2">
                {data?.hqName}
              </Typography>
            </TableCell>

            <TableCell className={classes.manageProTableBodyCell}>
              <Typography
                marginBottom="0px"
                paragraph={true}
                display="inline-block"
                fontWeight="400"
                color="#1c1939"
                fontSize="12.7px"
                variant="subtitle2">
                {data?.stockistName}
              </Typography>
            </TableCell>
            <TableCell sx={{padding: '8px !important'}}>
              <Typography
                marginBottom="0px"
                paragraph={true}
                display="inline-block"
                fontWeight="400"
                color="#1c1939"
                fontSize="12.7px"
                variant="subtitle2">
                {data?.partyName}
              </Typography>
            </TableCell>
            <TableCell sx={{padding: '8px !important'}}>
              <Stack direction="row" columnGap={2}>
                <svg
                  data-testid="delete-icon"
                  onClick={() => {
                    setDeleteId(data?.id);
                    handleToggleModal(data?.id, '');
                  }}
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect
                    width="26.64"
                    height="26.64"
                    rx="2.96"
                    fill="#D52929"
                    fill-opacity="0.09"
                  />
                  <path
                    d="M19.2399 8.1409C19.4362 8.1409 19.6244 8.21886 19.7632 8.35764C19.9019 8.49642 19.9799 8.68464 19.9799 8.8809C19.9799 9.07716 19.9019 9.26538 19.7632 9.40416C19.6244 9.54293 19.4362 9.6209 19.2399 9.6209H18.4999L18.4977 9.67344L17.8073 19.346C17.7807 19.7194 17.6136 20.0688 17.3397 20.3239C17.0657 20.5791 16.7053 20.7209 16.331 20.7209H10.3081C9.93378 20.7209 9.57334 20.5791 9.29941 20.3239C9.02547 20.0688 8.85839 19.7194 8.83181 19.346L8.14139 9.67418L8.13991 9.6209H7.39991C7.20365 9.6209 7.01543 9.54293 6.87665 9.40416C6.73788 9.26538 6.65991 9.07716 6.65991 8.8809C6.65991 8.68464 6.73788 8.49642 6.87665 8.35764C7.01543 8.21886 7.20365 8.1409 7.39991 8.1409H19.2399ZM14.7999 5.9209C14.9962 5.9209 15.1844 5.99886 15.3232 6.13764C15.4619 6.27642 15.5399 6.46464 15.5399 6.6609C15.5399 6.85716 15.4619 7.04538 15.3232 7.18416C15.1844 7.32293 14.9962 7.4009 14.7999 7.4009H11.8399C11.6437 7.4009 11.4554 7.32293 11.3167 7.18416C11.1779 7.04538 11.0999 6.85716 11.0999 6.6609C11.0999 6.46464 11.1779 6.27642 11.3167 6.13764C11.4554 5.99886 11.6437 5.9209 11.8399 5.9209H14.7999Z"
                    fill="#D52929"
                  />
                </svg>

                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div
                    data-testid="show-tooltip"
                    onClick={event => {
                      event.stopPropagation();
                      if (openTooltipId !== data.id) {
                        setTimeout(() => {
                          setOpenTooltipId(
                            openTooltipId === data.id ? null : data.id,
                          );
                        }, 100);
                        setOpenTooltipId(null);
                      }
                      setDeleteId(data?.id);
                      dispatch(fetchManageProductPopupDetailsCreator(data?.id));
                    }}>
                    <LightTooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={openTooltipId === data?.id}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={
                        <>
                          {openTooltipId !== null && (
                            <>
                              {popupData?.length !== 0 && (
                                <Box>
                                  <Stack direction="row" gap={0.5}>
                                    <Typography
                                      fontSize="11.7px"
                                      color="primary"
                                      fontWeight="600"
                                      variant="caption">
                                      Created By:
                                    </Typography>
                                    <Typography
                                      fontSize="11.7px"
                                      fontWeight="400"
                                      variant="caption">
                                      {` ${popupData?.createdBy} (${popupData?.id})`}
                                    </Typography>
                                  </Stack>
                                  <Stack direction="row" gap={0.5}>
                                    <Typography
                                      fontSize="11.7px"
                                      color="primary"
                                      fontWeight="600"
                                      variant="caption">
                                      Created On:
                                    </Typography>
                                    <Typography
                                      fontSize="11.7px"
                                      fontWeight="400"
                                      variant="caption">
                                      {` ${DateFormat(popupData?.createdOn)}`}
                                    </Typography>
                                  </Stack>
                                </Box>
                              )}
                            </>
                          )}
                        </>
                      }>
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect
                          width="26.64"
                          height="26.64"
                          rx="2.96"
                          fill="#3427BD"
                          fill-opacity="0.11"
                        />
                        <g clip-path="url(#clip0_616_1387)">
                          <path
                            d="M13.3199 15.5406C14.5459 15.5406 15.5399 14.5467 15.5399 13.3206C15.5399 12.0945 14.5459 11.1006 13.3199 11.1006C12.0938 11.1006 11.0999 12.0945 11.0999 13.3206C11.0999 14.5467 12.0938 15.5406 13.3199 15.5406Z"
                            fill="#322b7c"
                          />
                          <path
                            d="M21.6116 13.1321C20.9588 11.4436 19.8256 9.98339 18.352 8.93193C16.8783 7.88046 15.1289 7.28385 13.3199 7.21582C11.5109 7.28385 9.76144 7.88046 8.28781 8.93193C6.81418 9.98339 5.68095 11.4436 5.02818 13.1321C4.9841 13.2541 4.9841 13.3876 5.02818 13.5095C5.68095 15.198 6.81418 16.6582 8.28781 17.7097C9.76144 18.7612 11.5109 19.3578 13.3199 19.4258C15.1289 19.3578 16.8783 18.7612 18.352 17.7097C19.8256 16.6582 20.9588 15.198 21.6116 13.5095C21.6557 13.3876 21.6557 13.2541 21.6116 13.1321ZM13.3199 16.9283C12.6064 16.9283 11.9089 16.7167 11.3157 16.3203C10.7224 15.924 10.26 15.3605 9.98699 14.7014C9.71394 14.0422 9.6425 13.3168 9.7817 12.617C9.92089 11.9172 10.2645 11.2745 10.769 10.7699C11.2735 10.2654 11.9163 9.92183 12.6161 9.78264C13.3159 9.64344 14.0412 9.71488 14.7004 9.98793C15.3596 10.261 15.923 10.7234 16.3194 11.3166C16.7158 11.9099 16.9274 12.6073 16.9274 13.3208C16.9259 14.2771 16.5454 15.1939 15.8691 15.8701C15.1929 16.5463 14.2762 16.9269 13.3199 16.9283Z"
                            fill="#322b7c"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_616_1387">
                            <rect
                              width="17.76"
                              height="17.76"
                              fill="white"
                              transform="translate(4.43994 4.44043)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </LightTooltip>
                  </div>
                </ClickAwayListener>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <Box paddingTop="6px !important" padding="17px">
      <div>
        <Modal
          isOpen={isUnblockModelOpen}
          onAfterOpen={disableScroll}
          onAfterClose={enableScroll}
          style={{
            content: {
              width: '330px',
              height: 'fit-content',
              border: '25px',
              margin: 'auto',

              right: 0,
              backgroundColor: '#ffffff',
              boxShadow: ' 0 4 #000000 26.7 0 25%',
              borderRadius: '20px',
            },
            overlay: {zIndex: 1200, backgroundColor: '#0000003D'},
          }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography fontSize="18.7px" color="#1c1939" fontWeight="600">
              {strings.zho.fcAdmin.manageProductStatus.confirmation}
            </Typography>
            <IconButton
              data-testid="close-modal"
              onClick={() => handleToggleModal(null, '')}
              color="primary">
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Stack>

          <Stack marginTop={1}>
            <Box>
              <Typography
                textAlign="center"
                fontSize="12.7px"
                color="primary"
                fontWeight="400">
                {strings.zho.fcAdmin.manageProductStatus.modelMessage}
              </Typography>
              <Box
                marginTop="10px"
                sx={{borderBottom: '1px solid #ececec '}}></Box>
            </Box>
            <Box borderTop="1px solid #ececec ">
              <Stack direction="row" marginTop={3}>
                <Button
                  data-testid="button-cancel"
                  variant="outlined"
                  size="large"
                  sx={{textTransform: 'capitalize'}}
                  onClick={() => handleToggleModal(null, '')}
                  className={`${classes.paperButton} ${classes.rejectionButtonCancel}`}>
                  {strings.cancel}
                </Button>
                <Button
                  data-testid="button-unblock"
                  variant="contained"
                  sx={{textTransform: 'capitalize'}}
                  onClick={() => handleToggleModal(deleteId, 'call')}
                  className={`${classes.applyButton} ${classes.rejectionButtonRej} `}>
                  {strings.zho.UnBlock}
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Modal>
      </div>

      {/* heading of Manage Product */}
      <TableContainer>
        <Table
          sx={{
            borderCollapse: 'separate',
            position: 'relative',
          }}
          className={classes.manageProTableBorder}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderTopLeftRadius: '8px !important',
                  paddingLeft: '15px !important',
                }}
                className={classes.manageProTableHeadingCell}>
                <Typography
                  whiteSpace="nowrap"
                  marginBottom="0px"
                  paragraph={true}
                  display="inline-block"
                  fontWeight="600"
                  color="#322b7c"
                  fontSize="12.7px"
                  variant="subtitle2">
                  {strings.zho.division}
                </Typography>
              </TableCell>
              <TableCell className={classes.manageProTableHeadingCell}>
                <Typography
                  whiteSpace="nowrap"
                  marginBottom="0px"
                  paragraph={true}
                  display="inline-block"
                  fontWeight="600"
                  color="#322b7c"
                  fontSize="12.7px"
                  variant="subtitle2">
                  {strings.product}
                </Typography>
              </TableCell>

              <TableCell className={classes.manageProTableHeadingCell}>
                <Typography
                  whiteSpace="nowrap"
                  marginBottom="0px"
                  paragraph={true}
                  display="inline-block"
                  fontWeight="600"
                  color="#322b7c"
                  fontSize="12.7px"
                  variant="subtitle2">
                  {strings.zho.candf}
                </Typography>
              </TableCell>

              <TableCell className={classes.manageProTableHeadingCell}>
                <Typography
                  whiteSpace="nowrap"
                  marginBottom="0px"
                  paragraph={true}
                  display="inline-block"
                  fontWeight="600"
                  color="#322b7c"
                  fontSize="12.7px"
                  variant="subtitle2">
                  {strings.hq}
                </Typography>
              </TableCell>

              <TableCell className={classes.manageProTableHeadingCell}>
                <Typography
                  whiteSpace="nowrap"
                  marginBottom="0px"
                  paragraph={true}
                  display="inline-block"
                  fontWeight="600"
                  color="#322b7c"
                  fontSize="12.7px"
                  variant="subtitle2">
                  {strings.zho.customer}
                </Typography>
              </TableCell>

              <TableCell className={classes.manageProTableHeadingCell}>
                <Typography
                  whiteSpace="nowrap"
                  marginBottom="0px"
                  paragraph={true}
                  display="inline-block"
                  fontWeight="600"
                  color="#322b7c"
                  fontSize="12.7px"
                  variant="subtitle2">
                  {strings.zho.fcAdmin.dashboard.tableHeading.chemistandDis}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderTopRightRadius: '8px !important',
                  minWidth: '110px !important',
                  maxWidth: '110px !important',
                }}
                className={classes.manageProTableHeadingCell}>
                <Typography
                  whiteSpace="nowrap"
                  marginBottom="0px"
                  paragraph={true}
                  display="inline-block"
                  fontWeight="600"
                  color="#322b7c"
                  fontSize="12.7px"
                  variant="subtitle2">
                  {strings.actions}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!manageproductLoader ? (
              ManageProductDataView()
            ) : (
              <Box
                className={classes.loader}
                sx={[{display: 'flex', justifyContent: 'center', mt: 3}]}>
                <CircularProgress />
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* pagination */}

      <Stack
        marginTop={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Box>
          <svg
            data-testid="prev-page-btn"
            onClick={() => {
              if (MangeProductData?.list?.length !== 0) {
                handlePageChange('dec');
              }
            }}
            style={{
              verticalAlign: 'middle',
              cursor: requestPayload.pageNumber > 0 ? 'pointer' : 'default',
            }}
            width="23"
            height="23"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle
              cx="12.625"
              cy="13.0781"
              r="12.625"
              fill={`${requestPayload.pageNumber > 0 ? '#322b7c' : '#F1F1F8'}`}
            />
            <path
              d="M14.5137 8.41602L9.85156 13.0781L14.5137 17.7402"
              stroke={`${
                requestPayload.pageNumber > 0 ? '#ffffff' : '#9291A5'
              }`}
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <Typography
            marginLeft="0.5rem"
            paragraph={true}
            display="inline-block"
            fontWeight="400"
            color="primary"
            fontSize="12.7px"
            variant="subtitle2">
            {strings.zho.fcAdmin.manageProductStatus.previous}
          </Typography>
        </Box>

        <Box>
          <Typography
            paragraph={true}
            fontWeight="500"
            color="#1c1939"
            fontSize="12.7px"
            variant="subtitle2">
            Showing {requestPayload.pageNumber + 1} of {mangeProductTableCount}{' '}
            Pages
          </Typography>
        </Box>

        <Box>
          <Typography
            marginRight="0.5rem"
            paragraph={true}
            display="inline-block"
            fontWeight="400"
            color="primary"
            fontSize="12.7px"
            variant="subtitle2">
            {strings.zho.fcAdmin.manageProductStatus.next}
          </Typography>
          <svg
            data-testid="next-page-btn"
            onClick={() => {
              if (MangeProductData?.list?.length !== 0) {
                if (requestPayload.pageNumber + 1 !== mangeProductTableCount) {
                  handlePageChange('inc');
                }
              }
            }}
            style={{
              verticalAlign: 'middle',
              cursor:
                requestPayload.pageNumber + 1 !== mangeProductTableCount
                  ? 'pointer'
                  : 'default',
            }}
            width="23"
            height="23"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle
              cx="13.375"
              cy="13.0781"
              r="12.625"
              transform="rotate(-180 13.375 13.0781)"
              fill={`${
                requestPayload.pageNumber + 1 !== mangeProductTableCount
                  ? '#322b7c'
                  : '#F1F1F8'
              }`}
            />
            <path
              d="M11.4863 17.7402L16.1484 13.0781L11.4863 8.41602"
              stroke={`${
                requestPayload.pageNumber + 1 !== mangeProductTableCount
                  ? '#ffffff'
                  : '#9291A5'
              }`}
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Box>
      </Stack>
    </Box>
  );
};

export default ManageProductView;
