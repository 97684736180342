import {Box, Container, Stack} from '@mui/system';
import {useStyles} from './style';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {strings} from '@app/common/strings';
import UploadPod from './uploadPod';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {
  setScreenNo,
  SwitchingNo,
} from '@app/screens/zho/pages/fc-admin/redux/tabName';
import {setNavbarShow, setOrderReviewPopup} from '../../../redux/orderStore';
import {useCallback, useEffect, useState} from 'react';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  fetchEditPodForOrderCreator,
  fetchUploadPodDocumentCreator,
} from '../../../redux-api/slice';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';

interface PodList {
  podNumber: string;
  podQty: number;
  podDate: string | null;
}

interface Product {
  productId: number;
  productName: string;
  invoiceQty: number;
  totalTradePercentage: number;
  podDetailsList: PodList[];
}

const ViewPodContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<Array<Product>>([]);
  const [checkValidation, setCheckValidation] = useState<boolean>(false);
  const [isDispatched, setIsDispatched] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const podData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.podForOrderIdData,
  );
  const productData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormProductData.products,
  );
  const userLoginInfo = useSelector(
    (state: RootStateOrAny) => state.appState.userInfo,
  );
  const currentOrderId = useSelector(
    (state: RootStateOrAny) => state.hoOrderStore.value.currentOrderId,
  );
  const uploadLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.editPodForOrderLoading,
  );

  const preventDefaultHandleBill = useCallback(e => e.preventDefault(), []);
  const renderInputBill = useCallback(function (params: any) {
    return (
      <TextField
        {...params}
        helperText={null}
        onKeyDown={preventDefaultHandleBill}
        size="small"
        className={classes.dateInput}
      />
    );
  }, []);

  const formatDate = (date: Date): string => {
    const year = new Date(date).getFullYear();
    const month = (new Date(date).getMonth() + 1).toString().padStart(2, '0');
    const day = new Date(date).getDate().toString().padStart(2, '0');
    return `${day}/${month}/${year}`;
  };

  const addPod = (productId: number) => {
    setCheckValidation(false);
    const newList = tableData?.map(product => {
      if (product.productId === productId) {
        return {
          ...product,
          podDetailsList: [
            ...product.podDetailsList,
            {
              podNumber: '',
              podQty: 0,
              podDate: new Date().toDateString(),
            } as PodList,
          ],
        };
      }
      return product;
    });
    setTableData(newList);
  };

  // const removePod = (productId: number, podIndex: number) => {
  //   const newList = tableData?.map(product => {
  //     if (product.productId === productId) {
  //       return {
  //         ...product,
  //         podDetailsList: product.podDetailsList.filter(
  //           (_, index) => index !== podIndex,
  //         ),
  //       };
  //     }
  //     return product;
  //   });
  //   setTableData(newList);
  // };

  const handleInputChange = <K extends keyof PodList>(
    productId: number,
    index: number,
    key: K,
    value: PodList[K],
  ) => {
    setTableData(prevTableData => {
      return prevTableData?.map(product => {
        if (product.productId === productId) {
          const updatedPodDetailsList = product.podDetailsList?.map(
            (pod, podIndex) => {
              return podIndex === index ? {...pod, [key]: value} : pod;
            },
          );
          return {...product, podDetailsList: updatedPodDetailsList};
        }
        return product;
      });
    });
  };

  const checkPodQuantity = (podQty: any, totalTradeQuantity: string) => {
    const num = totalTradeQuantity
      .split('+')
      .reduce((sum, num) => sum + Number(num), 0);
    return podQty % num !== 0;
  };

  const handleSaveClick = () => {
    setCheckValidation(true);
    const validFields = tableData.every(product =>
      product.podDetailsList.every(
        pod =>
          pod.podNumber !== undefined &&
          pod.podNumber !== null &&
          pod.podNumber !== '' &&
          pod.podQty !== undefined &&
          pod.podQty !== null &&
          pod.podQty > 0 &&
          pod.podDate !== undefined &&
          pod.podDate !== null &&
          pod.podDate !== '',
      ),
    );

    const arePodNumbersUnique = (() => {
      const allPodNumbers = tableData.flatMap(product =>
        product.podDetailsList.map(pod =>
          pod.podNumber.trim().toLocaleLowerCase(),
        ),
      );
      return allPodNumbers.length === new Set(allPodNumbers).size;
    })();
    const isValidMultipleForAllProducts = tableData.every(data =>
      data.podDetailsList.every((pod, index) => {
        const matchingProduct = productData.find(
          (p: any) => p.productId === data.productId,
        );
        return matchingProduct
          ? !checkPodQuantity(pod.podQty, matchingProduct.totalTradeQuantity)
          : true;
      }),
    );

    const validQty = tableData.every(product => {
      const totalPodQty = product.podDetailsList.reduce(
        (sum, pod) => sum + (pod.podQty || 0),
        0,
      );
      return totalPodQty === product.invoiceQty;
    });

    if (
      validFields &&
      validQty &&
      isValidMultipleForAllProducts &&
      arePodNumbersUnique
    ) {
      const payload = {
        orderId: currentOrderId,
        productsList: tableData,
        currentUserId: userLoginInfo?.userId,
      };
      if (fileName) {
        setCheckValidation(false);
        setIsDispatched(true);
        dispatch(fetchEditPodForOrderCreator(payload));
        if (selectedFile) {
          dispatch(
            fetchUploadPodDocumentCreator({
              orderId: currentOrderId,
              file: selectedFile,
            }),
          );
        }
      }
    } else {
      ToasterService.hideToaster();
      if (!validQty) {
        ToasterService.showToaster(
          'Invoice quantity does not match the sum of POD quantities',
          ToastType.ERROR,
        );
      } else if (!arePodNumbersUnique) {
        ToasterService.showToaster(
          'POD number should be unique',
          ToastType.ERROR,
        );
      } else if (!isValidMultipleForAllProducts || !validFields) {
        ToasterService.showToaster(
          'Invoice Qty is invalid',
          ToastType.ERROR,
        );
      }
    }
  };

  useEffect(() => {
    if (isDispatched && !uploadLoading) {
      handeBackToOrderPopup();
    }
  }, [uploadLoading]);

  useEffect(() => {
    const updatedProductsList = podData?.productsList?.map(
      (product: Product) => {
        if (product.podDetailsList.length === 0) {
          addPod(product.productId);
        }
        return product;
      },
    );

    setTableData(updatedProductsList);
  }, [podData]);

  useEffect(() => {
    return () => {
      dispatch(SwitchingNo(1));
      dispatch(setNavbarShow(true));
    };
  }, []);

  const handeBackToOrderPopup = () => {
    dispatch(
      routeStateActions.setNavbarComponentName(navbarComponentName.fcAdmin),
    );
    dispatch(SwitchingNo(1));
    dispatch(setOrderReviewPopup());
    dispatch(setScreenNo(1));
    dispatch(setNavbarShow(true));
  };

  return (
    <Box className={`${classes.PodContainer} ${classes.podWidthDimension} `}>
      <Stack justifyContent="space-between" alignItems="center" direction="row">
        <Stack
          gap={2}
          justifyContent="flex-start"
          alignItems="center"
          direction="row">
          <Box
            data-testid="back-btn"
            onClick={handeBackToOrderPopup}
            className={classes.viewPodBackBtn}>
            <ArrowBackIosOutlinedIcon fontSize="small" color="primary" />
          </Box>
          <Typography className={classes.podHeading}>
            {strings.zho.hoAdmin.pod.viewPod}
          </Typography>
        </Stack>
        <Box>
          <Button
            onClick={handeBackToOrderPopup}
            data-testid="cancel-btn"
            variant="outlined"
            size="large"
            className={classes.paperButton}>
            {strings.cancel}
          </Button>
          <Button
            onClick={handleSaveClick}
            data-testid="button-save"
            variant="contained"
            disabled={uploadLoading || !(tableData?.length > 0)}
            className={classes.applyButton}>
            {strings.save}
          </Button>
        </Box>
      </Stack>
      <Box className={classes.viewContainer}>
        <Container
          sx={{
            maxWidth: '100% !important',
            padding: '0px !important',
          }}>
          <Stack direction="row" justifyContent={'space-between'}>
            <Stack className={`${classes.cellPadding} `}>
              <Typography className={classes.priDefaultFontSize}>
                {strings.zho.hoAdmin.dashboard.tableHeading.InvoiceNo}
              </Typography>
              <Typography fontSize="12.7px" className={classes.fontBold}>
                {podData?.invoiceNumber}
              </Typography>
            </Stack>
            <Stack className={`${classes.cellPadding} `}>
              <Typography className={classes.priDefaultFontSize}>
                {strings.zho.hoAdmin.dashboard.tableHeading.invoiceDate}
              </Typography>
              <Typography fontSize="12.7px" className={classes.fontBold}>
                {podData?.invoiceDate && formatDate(podData?.invoiceDate)}
              </Typography>
            </Stack>
          </Stack>
          <TableContainer sx={{minHeight: '100px', position: 'relative'}}>
            <Table
              sx={{
                borderCollapse: 'separate',
                borderSpacing: '0px 5px',
                marginTop: '10px',
                '& .MuiTableCell-root': {
                  borderBottom: 'none',
                },
              }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={`${classes.fontBold} ${classes.cellPadding} `}
                    sx={{minWidth: '220px'}}>
                    {strings.product}
                  </TableCell>
                  <TableCell
                    className={`${classes.fontBold} ${classes.cellPadding} `}
                    sx={{minWidth: '75px'}}>
                    {strings.zho.hoAdmin.dashboard.modalPopup.invoiceQty}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: '120px',
                      '::after': {
                        color: '#da3131',
                        content: '"*"',
                      },
                    }}
                    className={`${classes.fontBold} ${classes.cellPadding}`}>
                    {strings.zho.hoAdmin.dashboard.modalPopup.podNo}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: '60px',
                      maxWidth: '80px',
                      '::after': {
                        color: '#da3131',
                        content: '"*"',
                      },
                    }}
                    className={`${classes.fontBold} ${classes.cellPadding} `}>
                    {strings.zho.hoAdmin.dashboard.modalPopup.podQty}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: '110px',
                      '::after': {
                        color: '#da3131',
                        content: '"*"',
                      },
                    }}
                    className={`${classes.fontBold} ${classes.cellPadding} `}>
                    {strings.zho.hoAdmin.dashboard.modalPopup.podDate}
                  </TableCell>
                  {/* <TableCell></TableCell> */}
                </TableRow>
              </TableHead>
              {tableData?.length > 0 && !uploadLoading ? (
                <TableBody>
                  {tableData?.map(data =>
                    data?.podDetailsList?.map((pod, index) => {
                      const matchingProduct = productData?.find(
                        (p: any) => p.productId === data.productId,
                      );
                      const isError = matchingProduct
                        ? checkPodQuantity(
                            pod.podQty,
                            matchingProduct.totalTradeQuantity,
                          )
                        : false;
                      return (
                        <TableRow key={`${data.productId}-${index}`}>
                          {index === 0 ? (
                            <>
                              <TableCell className={` ${classes.cellPadding} ${index === data?.podDetailsList?.length-1 && classes.separation}`}>
                                <Typography
                                  className={`${classes.podTableText} ${classes.podTableFieldShadow}`}>
                                  {data?.productName}
                                </Typography>
                              </TableCell>
                              <TableCell className={`${classes.cellPadding} ${index === data?.podDetailsList?.length-1 && classes.separation}`}>
                                <Typography
                                  className={`${classes.podTableText} ${classes.podTableFieldShadow}`}>
                                  {data?.invoiceQty}
                                </Typography>
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell
                                className={`${index === data?.podDetailsList?.length-1 && classes.separation} ${classes.cellPadding}`}></TableCell>
                              <TableCell
                                className={`${index === data?.podDetailsList?.length-1 && classes.separation} ${classes.cellPadding}`}></TableCell>
                            </>
                          )}

                          <TableCell
                            className={`${index === data?.podDetailsList?.length-1 && classes.separation} ${
                              classes.cellPadding
                            }`}>
                            <TextField
                              error={
                                checkValidation &&
                                (pod.podNumber === undefined ||
                                  pod.podNumber === null ||
                                  pod.podNumber === '')
                              }
                              placeholder="Pod No."
                              data-testid="pod-no-input"
                              value={pod.podNumber}
                              onChange={e =>
                                handleInputChange(
                                  data?.productId,
                                  index,
                                  'podNumber',
                                  e.target.value.slice(0, 25),
                                )
                              }
                              sx={{
                                background: '#ffffff',
                                outline: 'none',
                                borderRadius: '5px',
                                '& .MuiInputBase-input': {
                                  height: '35px',
                                  fontSize: '11.7px',
                                  padding: '0px 10px !important',
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell
                            sx={{maxWidth: '100px !important'}}
                            className={`${index === data?.podDetailsList?.length-1 && classes.separation} ${
                              classes.cellPadding
                            }`}>
                            <TextField
                              // disabled={true}
                              error={
                                checkValidation &&
                                (isError ||
                                  pod.podQty === undefined ||
                                  pod.podQty === null ||
                                  pod.podQty <= 0)
                              }
                              data-testid="edit-pod-qty"
                              type="number"
                              className={`${classes.listingInput} ${classes.hideSpinButton}`}
                              InputProps={{
                                style: {
                                  minWidth: '60px',
                                  maxWidth: '100px',
                                  height: '35px',
                                  fontSize: '12.7px',
                                },
                              }}
                              value={pod.podQty}
                              onInput={(e: any) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value),
                                )
                                  .toString()
                                  .slice(0, 4);
                              }}
                              onChange={e =>
                                handleInputChange(
                                  data?.productId,
                                  index,
                                  'podQty',
                                  e.target.value !== ''
                                    ? Number(e.target.value)
                                    : 0,
                                )
                              }
                            />
                          </TableCell>
                          <TableCell
                            className={`${index === data?.podDetailsList?.length-1 && classes.separation} ${
                              classes.cellPadding
                            }`}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                inputFormat="DD/MM/YYYY"
                                views={['year', 'month', 'day']}
                                value={pod?.podDate ? dayjs(pod.podDate) : null}
                                onChange={date => {
                                  handleInputChange(
                                    data?.productId,
                                    index,
                                    'podDate',
                                    date?.format('YYYY-MM-DD') || null,
                                  );
                                }}
                                minDate={
                                  podData?.invoiceDate
                                    ? dayjs(podData.invoiceDate)
                                    : null
                                }
                                maxDate={dayjs(new Date())}
                                renderInput={renderInputBill}
                              />
                            </LocalizationProvider>
                          </TableCell>
                          {/* <TableCell className={`${classes.cellPadding}`}>
                          <Box width="25px" display="inline-block">
                            <IconButton
                              sx={{padding: '0px'}}
                              data-testid="show-comp-prod"
                              onClick={e => {
                                e.stopPropagation();
                                index === data.podDetailsList.length - 1
                                  ? addPod(data.productId)
                                  : removePod(data?.productId, index);
                              }}>
                              {index !== data.podDetailsList.length - 1 ? (
                                <RemoveCircleOutlineOutlined color="primary" />
                              ) : (
                                <AddCircleOutlineOutlined color="primary" />
                              )}
                            </IconButton>
                          </Box>
                        </TableCell> */}
                        </TableRow>
                      );
                    }),
                  )}
                </TableBody>
              ) : (
                <Box className={classes.loader}>
                  <CircularProgress />
                </Box>
              )}
            </Table>
          </TableContainer>
        </Container>
        <UploadPod
          setSelectedFile={setSelectedFile}
          checkValidation={checkValidation}
          fileName={fileName}
          setFileName={setFileName}
        />
      </Box>
    </Box>
  );
};

export default ViewPodContainer;
