import Box from '@mui/material/Box';
import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import {useStyles} from '../style';
import {RootStateOrAny, useSelector, useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import {strings} from '@app/common/strings';
import dateUtils from '@app/screens/zho/utils/dateFormat';

import {
  fetchInvoiceProductApproveCreator,
  fetchOrderProductApprovalCreator,
  fetchRejectInvoiceOptionsCreator,
  fetchRejectOptionsCreator,
} from '../../redux-api/slice';
import {useLocation} from 'react-router-dom';

interface ApproveralList {
  role: string;
  status: string;
  date: string | null;
  statusCode: number;
  approverName: string | null;
}

const OrderApprovalStatus = ({
  userLoginInfo,
  handleRejectToggle,
  handleToggleModal,
  orderFormId,
  setApprovalStatus,
  isSynced,
  setApproveModalOpen,
  approveModalOpen,
  setSyncApproveChecking,
}: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {DateFormat, IstFormat} = dateUtils;
  const location = useLocation();
  const approvalData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormApprovalData,
  );

  const statusToggleNo = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.statusToggleNo,
  );

  const userTab = useSelector(
    (state: RootStateOrAny) =>
      state.hoOrderStore.value.requestPayloadOrder.type,
  );

  const screenNo = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.screenNo,
  );

  const tab = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.activeTabIndex,
  );

  const [sortedApprovalData, setSortedApprovalData] = useState<
    ApproveralList[]
  >([]);

  const LightTooltipNormal = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      // boxShadow: theme.shadows[1],
      fontSize: 11.7,
      border: '1px solid #dcdce0',
      borderRadius: '5px',
      padding: '0.3rem',
      zindex: '99999999',
      marginRight: '84px',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    },
  }));

  const getStatusColor = (status: number) => {
    if (status === 0) {
      return '#ff7650';
    } else if (status === 1) {
      return '#01a685';
    } else if (status === 6) {
      return '#f5ce42';
    } else if (status === 3 || status === 4) {
      return '#f45b60';
    } else if (status === 5) {
      return '#34b53a';
    }
  };

  const length = sortedApprovalData.length;
  let columns = 5;
  let xs = 1;

  if (length === 3) {
    columns = 3;
    xs = 1;
  } else if (length === 4) {
    columns = 4;
    xs = 1;
  } else if (length === 5) {
    columns = 5;
    xs = 1;
  }

  useEffect(() => {
    if (approvalData && approvalData.approvalList?.length > 0) {
      setSortedApprovalData(approvalData?.approvalList);
      setApprovalStatus(approvalData[approvalData.length - 1]?.statusCode);
      setApprovalStatus(
        approvalData?.approvalList[approvalData?.approvalList?.length - 1]
          ?.statusCode,
      );
    }
  }, [approvalData]);

  const handleApprovalReviewForm = () => {
    if (tab === 0) {
      if (location.pathname === '/ho-admin') {
        dispatch(
          fetchOrderProductApprovalCreator(
            userLoginInfo?.staffPositionId,
            userLoginInfo?.userId,
            [orderFormId],
            'ho',
          ),
        );

        setSyncApproveChecking({
          isAllSelected: false,
          isAllSynced: false,
          approveCheckList: [],
          type: 'approve',
        });

        handleToggleModal();
        setApproveModalOpen(!approveModalOpen);
      }
    } else if (location.pathname === '/fc-admin') {
      dispatch(
        fetchOrderProductApprovalCreator(
          userLoginInfo?.staffPositionId,
          userLoginInfo?.userId,
          [orderFormId],
          'fc',
        ),
      );
    } else {
      dispatch(
        fetchInvoiceProductApproveCreator(
          userLoginInfo?.staffPositionId,
          userLoginInfo?.userId,
          [orderFormId],
        ),
      );
    }
    handleToggleModal();
  };

  const handleRejectReviewForm = () => {
    if (tab === 0 || location.pathname === '/fc-admin') {
      dispatch(fetchRejectOptionsCreator());
    } else {
      dispatch(fetchRejectInvoiceOptionsCreator());
    }
    handleRejectToggle();
    // handleToggleModal();
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={'center'}>
        <Typography
          fontSize="15.7px"
          color="#1c1939"
          sx={{margin: '0px !important', fontWeight: '600 !important'}}
          className={`${classes.RfHeading} ${classes.noWrap}  `}
          variant="h6">
          {strings.zho.fcAdmin.requestForm.approvalStatus}
        </Typography>
        {statusToggleNo === 1 && screenNo === 2 && (
          <Box>
            <Button
              data-testid="button-reject"
              variant="outlined"
              size="large"
              className={classes.paperButton}
              onClick={handleRejectReviewForm}>
              {strings.reject}
            </Button>
            {tab === 0 ? (
              !isSynced ? (
                <LightTooltipNormal
                  placement="bottom"
                  title={
                    <Box>
                      <Typography
                        fontSize="11.7px"
                        fontWeight="400"
                        variant="caption">
                        Order is not synced for today please sync.
                      </Typography>
                    </Box>
                  }>
                  <Box display="inline-block">
                    <Button
                      disabled={isSynced ? false : true}
                      data-testid="button-approve"
                      variant="contained"
                      className={classes.applyButton}
                      onClick={handleApprovalReviewForm}>
                      {strings.approve}
                    </Button>
                  </Box>
                </LightTooltipNormal>
              ) : (
                <Button
                  disabled={isSynced ? false : true}
                  data-testid="button-approve"
                  variant="contained"
                  className={classes.applyButton}
                  onClick={handleApprovalReviewForm}>
                  {strings.approve}
                </Button>
              )
            ) : (
              <Button
                data-testid="button-approve"
                variant="contained"
                className={classes.applyButton}
                onClick={handleApprovalReviewForm}>
                {strings.approve}
              </Button>
            )}
          </Box>
        )}

        {statusToggleNo === 1 && screenNo === 1 && userTab === 1 && (
          <Box>
            <Button
              data-testid="button-reject"
              variant="outlined"
              size="large"
              className={classes.paperButton}
              onClick={handleRejectReviewForm}>
              {strings.reject}
            </Button>
            <Button
              data-testid="button-approve"
              variant="contained"
              className={classes.applyButton}
              onClick={handleApprovalReviewForm}>
              {strings.approve}
            </Button>
          </Box>
        )}
      </Stack>

      <>
        <Grid marginTop={2} columns={columns} container>
          {sortedApprovalData.map((data, index) => {
            return (
              <Grid key={index} item xs={xs}>
                <Typography
                  variant="subtitle1"
                  sx={{fontWeight: '600 !important'}}
                  className={`${classes.RfHeading} ${classes.priDefaultFontSize} ${classes.noBorderBottom} ${classes.cellPadding}`}>
                  {data?.role}
                </Typography>
              </Grid>
            );
          })}
        </Grid>

        <Grid
          className={classes.proDetailContainerBorder}
          container
          columns={columns}>
          {sortedApprovalData.map((data, index) => {
            return (
              <Grid item xs={xs}>
                <Box className={classes.cellPadding}>
                  <Typography
                    sx={{fontWeight: '400 !important'}}
                    style={{
                      color: getStatusColor(
                        sortedApprovalData[index]?.statusCode,
                      ),
                    }}
                    variant="body2">
                    {data?.status}
                  </Typography>
                  <Typography
                    fontSize="12.7px"
                    style={{marginTop: '8px'}}
                    className={classes.priDefaultFontSize}
                    variant="body2">
                    {data?.approverName && `(${data?.approverName})`}
                  </Typography>
                </Box>
              </Grid>
            );
          })}

          {sortedApprovalData.map((data, index) => {
            return (
              <Grid key={index} item xs={xs}>
                {data?.date !== null && (
                  <Box className={classes.cellPadding}>
                    <Typography
                      fontWeight="600"
                      color={'#8d8c9c'}
                      sx={{
                        opacity: '0.7',
                        textTransform: 'uppercase',
                        fontSize: 10.7,
                      }}
                      variant="body2">
                      {strings.date}
                    </Typography>

                    <Typography
                      className={classes.priDefaultFontSize}
                      variant="body2">
                      {data.date === null && '---'}
                      {data?.date !== null && DateFormat(data?.date)}
                      {data?.date !== null && ' , '}
                      {data?.date !== null && IstFormat(data?.date)}
                    </Typography>
                  </Box>
                )}
              </Grid>
            );
          })}
        </Grid>
      </>
    </Box>
  );
};

export default OrderApprovalStatus;
