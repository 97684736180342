import {filterStateActions} from '@app/components/right-sidebar/redux';
import {
  navbarComponentName,
  routeStateActions,
} from '@app/router/redux/routeHandler';
import FilterSummary from '@app/screens/zho/components/summary/filterSummary';
import TableSummary from '@app/screens/zho/components/summary/tableSummary';
import TotalBoxSummary from '@app/screens/zho/components/summary/totalBoxSummary';
import {useEffect} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {setSubTabSwitch} from '../redux/tabName';
import {hoDashboardActions} from '../../ho-admin/redux-api';
import {
  clearSummaryPayload,
  updateSummaryPayload,
} from '../../ho-admin/redux/orderStore';
export const Summary = ({userLoginInfo}: any) => {
  const dispatch = useDispatch();
  const summaryPayload = useSelector(
    (state: RootStateOrAny) => state.hoOrderStore.value.summaryPayload,
  );

  useEffect(() => {
    dispatch(filterStateActions.setFilterState({}));
    dispatch(
      routeStateActions.setNavbarComponentName(navbarComponentName.fcAdmin),
    );
    dispatch(
      updateSummaryPayload({
        ...summaryPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
      }),
    );
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(hoDashboardActions.setSummaryTableData([]));
      dispatch(hoDashboardActions.setSummaryTotalData([]));
      dispatch(clearSummaryPayload());
      dispatch(setSubTabSwitch(1));
    };
  }, []);
  return (
    <>
      <FilterSummary userLoginInfo={userLoginInfo} />
      <TotalBoxSummary userLoginInfo={userLoginInfo} />
      <TableSummary userLoginInfo={userLoginInfo} />
    </>
  );
};
