import {useEffect, useCallback, useState, useRef, ChangeEvent} from 'react';
import {filterStateActions} from '@app/components/right-sidebar/redux';
import {routeStateActions} from '@app/router/redux';
import {
  Container,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Checkbox,
  Box,
  Stack,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useDispatch, useSelector, RootStateOrAny} from 'react-redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import Togglefilter from '@app/screens/zho/components/toggle-filter';
import {useStyles} from '@app/screens/zho/styles';
import Modal from 'react-modal';
import {Scrollbar} from 'react-scrollbars-custom';
import OrderFormTable from './components/order-form-tables/orderFormtable';
import dayjs from 'dayjs';
import {strings} from '@app/common/strings';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import OrderFormHeading from './components/orderFormHeading';
import OrderApprovalStatus from './components/orderApprovalStatus';
import OrderDetails from './components/orderDetails';
import OrderProductDetails from './components/orderProductDetails';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {fcDashboardActions} from '../../fc-admin/redux-api';
import {hoDashboardActions} from '../redux-api';
import {
  clearRequestPayloadOrder,
  setStateSelect,
  updateRequestPayloadOrder,
} from '../redux/orderStore';
import {fetchDivisionDataCreator as fetchDivisionDataCreatorFc} from '../../fc-admin/redux-api/slice';
import {
  fetchHosHeaderCountCreator,
  fetchHqDataCreator,
  fetchMrDataCreator,
  fetchOrderFormDataCreator,
  fetchOrderProductRejectCreator,
  fetchStateDataCreator,
} from '../redux-api/slice';
import {useLocation} from 'react-router-dom';
import OrderApproveTable from './components/OrderApproveTable';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Division {
  divisionId: number;
  divisionName: string;
}
interface StateFilter {
  stateId: number;
  stateName: string;
}

interface HqFilter {
  hqId: number;
  hqName: string;
}

interface MrFilter {
  mrId: number;
  hqId: number;
  mrName: string;
  sapCode: string;
}

export const Order = ({userLoginInfo}: any) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [isRejectModelOpen, setIsRejectModelOpen] = useState(false);
  const [division, setDivision] = useState<number | string>('');
  const [stateId, setStateId] = useState<number | string>('');
  const [orderFormNo, setOrderFormNo] = useState<string>('');
  const [hqName, setHqName] = useState<number[]>([]);
  const [mrName, setMrName] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null | string>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null | string>(null);
  const [orderFormId, setOrderFormId] = useState(0);
  const [approvalStatus, setApprovalStatus] = useState<null | number>(null);
  const [rejectOption, setRejectOption] = useState<string>('');
  const [rejectOptionalValue, setRejectOptionalvalue] = useState<string>('');
  const [isRemarkSubmitted, setIsRemarkSubmitted] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState({id: 0, isOpen: false});
  const [isSynced, setIsSynced] = useState(false);
  const [isFocused, setIsFocused] = useState<{
    focus: boolean;
    id: number | null;
  }>({
    focus: false,
    id: null,
  });
  const [syncApproveChecking, setSyncApproveChecking] = useState({
    isAllSelected: false,
    isAllSynced: false,
    approveCheckList: [],
    type: '',
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const handleInputFocus = (id: number) => {
    setIsFocused({
      focus: true,
      id,
    });
  };

  const handleInputBlur = () => {
    setIsFocused({
      focus: false,
      id: null,
    });
  };

  const handleToggleModal = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handleRejectToggle = () => {
    setIsRejectModelOpen(!isRejectModelOpen);
    setRejectOption('');
    setIsRemarkSubmitted(false);
    setRejectOptionalvalue('');
  };

  const divisionData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.divisionData,
  );

  const divisionLoading = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.divisionLoading,
  );

  const stateData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.stateData,
  );

  const stateLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.stateLoading,
  );

  const hqData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.hqData,
  );

  const hqLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.hqLoading,
  );

  const mrData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.mrData,
  );

  const mrLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.mrLoading,
  );
  const orderFormPayload = useSelector(
    (state: RootStateOrAny) => state.hoOrderStore.value.requestPayloadOrder,
  );

  const toogleStatusNo = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.statusToggleNo,
  );

  const productData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormProductData,
  );

  const rejectedListOptions = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderRejectOptionsData,
  );

  const approvalData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormApprovalData,
  );

  const approvalStatusLoader = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormApprovalLoading,
  );

  const productDetailDataLoader = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormProductLoading,
  );

  useEffect(() => {
    dispatch(fetchDivisionDataCreatorFc(userLoginInfo?.staffPositionId, ''));
    dispatch(fetchStateDataCreator(userLoginInfo?.staffPositionId, 'ho'));
    dispatch(
      updateRequestPayloadOrder({
        ...orderFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
      }),
    );
    dispatch(filterStateActions.setFilterState({}));
    dispatch(
      routeStateActions.setNavbarComponentName(navbarComponentName.hoAdmin),
    );
    dispatch(fetchHosHeaderCountCreator(userLoginInfo?.staffPositionId));
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(fcDashboardActions.setDivisionData([]));
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(clearRequestPayloadOrder());
      dispatch(fcDashboardActions.setHqData([]));
      dispatch(fcDashboardActions.setMrData([]));
      dispatch(hoDashboardActions.setOrderFormData([]));
      dispatch(
        updateRequestPayloadOrder({
          ...orderFormPayload,
          pageSize: 10,
          pageNumber: 0,
        }),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearStateValues();
    setModalOpen({id: 0, isOpen: false});
    setDivision('');
    dispatch(hoDashboardActions.setHqData([]));
    dispatch(hoDashboardActions.setMrData([]));
  }, [toogleStatusNo]);

  const classes = useStyles();

  const preventDefaultHandleBill = useCallback(e => e.preventDefault(), []);
  const renderInputBill = useCallback(function (params: any) {
    return (
      <TextField
        {...params}
        helperText={null}
        onKeyDown={preventDefaultHandleBill}
        size="small"
      />
    );
  }, []);

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };
  const enableScroll = () => {
    document.body.style.overflow = 'auto';
  };

  const handleDateChange = (date: dayjs.Dayjs | null | any, type: string) => {
    if (type === 'start') {
      if (date) {
        const formattedStartDate = date.format('YYYY-MM-DD');
        setStartDate(formattedStartDate);
      }
    } else {
      if (date) {
        const formattedEndDate = date.format('YYYY-MM-DD');
        setEndDate(formattedEndDate);
      }
    }
  };

  const handleFilterChange = (event: any, type: string, dropValue: any) => {
    const {
      target: {value},
    } = event;
    let selectedIds: any;
    if (type === 'division') {
      const divisionId = dropValue ? parseInt(dropValue.divisionId) : '';
      setDivision(divisionId);
    } else if (type === 'state') {
      const stateId = dropValue ? parseInt(dropValue.stateId) : '';
      setStateId(stateId);
      setHqName([]);
      setMrName([]);
      dispatch(hoDashboardActions.setHqData([]));
      dispatch(hoDashboardActions.setMrData([]));
      dispatch(setStateSelect(stateId));
      if (stateId !== '') {
        dispatch(fetchHqDataCreator(stateId, 'ho'));
      }
    } else if (type === 'search') {
      setOrderFormNo(value);
    } else if (type === 'mr') {
      selectedIds = dropValue.map((item: MrFilter) => item.mrId);
      setMrName(selectedIds);
    } else {
      selectedIds = dropValue.map((item: HqFilter) => item.hqId);
      setHqName(selectedIds);
      if (selectedIds.length !== 0) {
        dispatch(fetchMrDataCreator(selectedIds, 'ho'));
      } else {
        dispatch(hoDashboardActions.setMrData([]));
        setMrName([]);
      }
    }
  };

  const handleFilterApiCall = () => {
    let dateCheck = false;

    if (endDate && startDate) {
      if (endDate < startDate) {
        dateCheck = true;
        ToasterService.hideToaster();
        ToasterService.showToaster('Invalid Date Range!', ToastType.ERROR);
      }
    }

    setModalOpen({id: 0, isOpen: false});
    dispatch(
      updateRequestPayloadOrder({
        ...orderFormPayload,
        pageSize: 10,
        pageNumber: 0,
        formNo: orderFormNo,
        mrStaffIds: mrName,
        state: stateId,
        hq: hqName,
        startDate: startDate,
        endDate: endDate,
        division: division === '' ? [] : [+division],
        tabType: toogleStatusNo,
      }),
    );
    if (!dateCheck) {
      if (location.pathname === '/ho-admin') {
        dispatch(
          fetchOrderFormDataCreator(
            {
              currentStaffPositionId: userLoginInfo?.staffPositionId,
              division: division === '' ? [] : [+division],
              pageSize: orderFormPayload.pageSize,
              pageNumber: 0,
              formNo: orderFormNo,
              mrStaffIds: mrName,
              state: stateId,
              hq: hqName,
              startDate: startDate,
              endDate: endDate,
            },
            toogleStatusNo,
            'ho',
          ),
        );
      }
    }
  };

  const clearStateValues = () => {
    dispatch(clearRequestPayloadOrder());
    setStartDate(null);
    setStateId('');
    setEndDate(null);
    setOrderFormNo('');
    setHqName([]);
    setMrName([]);
  };

  const handleRejectionChange = (event: any, type: string) => {
    const {
      target: {value},
    } = event;

    if (type === 'select') {
      setIsRemarkSubmitted(false);
      setRejectOption(value);
    } else {
      setIsRemarkSubmitted(false);
      setRejectOptionalvalue(value);
    }
  };

  const handleRejectReviewForm = () => {
    if (
      rejectOption !== 'Others' &&
      !isRemarkSubmitted &&
      rejectOption !== '' &&
      rejectOption !== undefined
    ) {
      setIsRemarkSubmitted(false);
      setIsRejectModelOpen(false);

      if (location.pathname === '/ho-admin') {
        dispatch(
          fetchOrderProductRejectCreator(
            userLoginInfo?.staffPositionId,
            rejectOption,
            userLoginInfo?.userId,
            orderFormId,
            'ho',
          ),
        );
      } else {
        dispatch(
          fetchOrderProductRejectCreator(
            userLoginInfo?.staffPositionId,
            rejectOption,
            userLoginInfo?.userId,
            orderFormId,
            'fc',
          ),
        );
      }

      setRejectOption('');
      setRejectOptionalvalue('');
      handleToggleModal();
    } else if (rejectOptionalValue.trim() !== '' && rejectOption === 'Others') {
      setIsRemarkSubmitted(false);
      setIsRejectModelOpen(false);

      if (location.pathname === '/ho-admin') {
        dispatch(
          fetchOrderProductRejectCreator(
            userLoginInfo?.staffPositionId,
            rejectOptionalValue,
            userLoginInfo?.userId,
            orderFormId,
            'ho',
          ),
        );
      } else {
        dispatch(
          fetchOrderProductRejectCreator(
            userLoginInfo?.staffPositionId,
            rejectOptionalValue,
            userLoginInfo?.userId,
            orderFormId,
            'fc',
          ),
        );
      }

      setRejectOption('');
      setRejectOptionalvalue('');
      handleToggleModal();
    } else {
      setIsRemarkSubmitted(true);
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'Please fill the additional remarks!',
        ToastType.ERROR,
      );
    }
  };
  return (
    <>
      {/* Popup */}
      <div style={{overflow: 'auto'}}>
        <Modal
          isOpen={isModelOpen}
          onAfterOpen={disableScroll}
          onAfterClose={enableScroll}
          style={{
            content: {
              width: '65%',
              height: '94vh',
              border: '25px',
              margin: 'auto',
              overflow: 'auto',
              right: 0,
              backgroundColor: '#ffffff',
              // boxShadow: ' 0 4 #000000 26.7 0 25%',
              borderRadius: '15px',
              padding: '10px',
              boxShadow: '0px 3.48px 23.22px 0px #00000040',
            },
            overlay: {zIndex: 1200, backgroundColor: '#0000003D'},
          }}>
          {approvalStatusLoader || productDetailDataLoader ? (
            <Box
              sx={{
                height: '100%',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px',
              }}>
              <Box
                className={classes.loader}
                sx={{display: 'flex', justifyContent: 'center', mt: 3}}>
                <CircularProgress />
              </Box>
            </Box>
          ) : null}

          {!approvalStatusLoader && !productDetailDataLoader && (
            <Box
              sx={{
                height: '100%',
                overflow: 'auto',
                padding: '10px',
              }}>
              <OrderFormHeading
                title={strings.zho.hoAdmin.dashboard.modalPopup.order}
                handleToggleModal={handleToggleModal}
              />
              <OrderApprovalStatus
                setSyncApproveChecking={setSyncApproveChecking}
                setApproveModalOpen={setApproveModalOpen}
                approveModalOpen={approveModalOpen}
                isSynced={isSynced}
                approvalStatus={approvalStatus}
                approvalData={approvalData}
                setApprovalStatus={setApprovalStatus}
                orderFormId={orderFormId}
                handleToggleModal={handleToggleModal}
                handleRejectToggle={handleRejectToggle}
                userLoginInfo={userLoginInfo}
              />
              <OrderDetails
                productData={productData}
                userLoginInfo={userLoginInfo}
              />
              <OrderProductDetails
                hide={false}
                handleToggleModal={handleToggleModal}
                approvalStatus={approvalStatus}
                productData={productData}
                supplyPod={false}
                userLoginInfo={userLoginInfo}
              />
            </Box>
          )}
        </Modal>
      </div>
      {/* Popup */}

      <div>
        <Modal
          isOpen={isRejectModelOpen}
          onAfterOpen={disableScroll}
          onAfterClose={enableScroll}
          style={{
            content: {
              width: '330px',
              height: 'fit-content',
              border: '25px',
              margin: 'auto',

              right: 0,
              backgroundColor: '#ffffff',
              boxShadow: ' 0 4 #000000 26.7 0 25%',
              // borderRadius: '20px',
            },
            overlay: {zIndex: 1200, backgroundColor: '#0000003D'},
          }}>
          <Box>
            <Typography
              sx={{
                fontSize: '18.7px',
                cursor: 'pointer',
                color: '#1c1939',
              }}
              variant="subtitle2">
              {strings.zho.orderRejectionRemarks}
            </Typography>
          </Box>
          <Stack spacing={2} direction="column" marginTop={6}>
            <Box>
              <Typography
                sx={{fontSize: '15.7px', cursor: 'pointer', color: '#1c1939'}}
                variant="subtitle2">
                {
                  strings.zho.fcAdmin.dashboard.tableHeading
                    .ReasonsforRejections
                }
                <Typography variant="caption" color="red">
                  *
                </Typography>
              </Typography>
            </Box>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center">
              <FormControl fullWidth className={classes.dropdownBgWhite}>
                <InputLabel size="small" id="state" sx={{fontSize: '12.7px'}}>
                  {strings.zho.fcAdmin.requestForm.filters.rejection}
                </InputLabel>
                <Select
                  className={`${classes.remarksDropdown} ${
                    isRemarkSubmitted && rejectOption === ''
                      ? classes.errorBorder
                      : ''
                  }  `}
                  size="small"
                  labelId="remarks"
                  id="remarks"
                  value={rejectOption}
                  label={strings.zho.fcAdmin.requestForm.filters.rejection}
                  onChange={e => handleRejectionChange(e, 'select')}>
                  {rejectedListOptions?.map((options: any, index: number) => {
                    return (
                      <MenuItem
                        sx={{
                          whiteSpace: 'normal !important',
                          maxWidth: '280px',
                          minWidth: '280px',
                          minHeight: 'auto',
                          fontSize: '12.7px',
                          height: 'auto',
                          color: '#1c1939',
                        }}
                        key={index}
                        value={options.rejectionOptions}>
                        {options.rejectionOptions}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {rejectOption === 'Others' && (
                <Box
                  height="120px"
                  overflow="auto"
                  marginTop="40px"
                  width="100%">
                  <textarea
                    style={{resize: 'vertical'}}
                    onChange={e => handleRejectionChange(e, 'input')}
                    value={rejectOptionalValue}
                    cols={3}
                    rows={4}
                    className={`${classes.rejectedTextArea} ${
                      isRemarkSubmitted && rejectOptionalValue.trim() === ''
                        ? classes.errorBorder
                        : ''
                    } `}
                    placeholder="Type remakrs Ex: Mr quit the company"></textarea>
                </Box>
              )}
            </Stack>
          </Stack>

          <Box
            marginTop={rejectOption === 'Others' ? 3 : 7}
            borderTop="1px solid #ececec ">
            <Stack direction="row" marginTop={2}>
              <Button
                data-testid="button-cancel"
                variant="outlined"
                size="large"
                sx={{textTransform: 'capitalize'}}
                onClick={handleRejectToggle}
                className={`${classes.paperButton} ${classes.rejectionButtonCancel}`}>
                {strings.cancel}
              </Button>
              <Button
                data-testid="button-reject"
                variant="contained"
                sx={{textTransform: 'capitalize'}}
                onClick={handleRejectReviewForm}
                className={`${classes.applyButton} ${classes.rejectionButtonRej} `}>
                {strings.reject}
              </Button>
            </Stack>
          </Box>
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={approveModalOpen}
          onAfterOpen={disableScroll}
          onAfterClose={enableScroll}
          style={{
            content: {
              width: '60%',
              minWidth: '320px',
              height: 'fit-content',
              margin: 'auto',
              right: 0,
              backgroundColor: '#ffffff',
              boxShadow: ' 0 4 #000000 26.7 0 25%',
              borderRadius: '8px',
              padding: '0px',
            },
            overlay: {zIndex: 1200, backgroundColor: '#0000003D'},
          }}>
          <OrderApproveTable
            syncApproveChecking={syncApproveChecking}
            setApproveModalOpen={setApproveModalOpen}
            approveModalOpen={approveModalOpen}
          />
        </Modal>
      </div>

      <Container
        sx={{
          padding: '12px 20px !important',
          paddingTop: '0px !important',
          maxHeight: 'calc(100vh - 155px)',
          overflow: 'auto',
        }}
        maxWidth={false}>
        <Togglefilter role={2} />
        <div className={classes.toolbarRequestForm}>
          <Scrollbar
            style={{
              width: '100%',
              height: '55px',
              marginRight: '10px',
            }}>
            <Grid
              sx={{paddingTop: '3px'}}
              container
              className={classes.filterGrid}
              spacing={1}>
              <Grid item xs sx={{flexBasis: '16%'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    disableFuture={true}
                    label={strings.zho.fcAdmin.requestForm.filters.startDate}
                    className={classes.filterFromDate}
                    // PopperProps={DialogProps}
                    views={['year', 'month', 'day']}
                    value={startDate}
                    onChange={e => handleDateChange(e, 'start')}
                    renderInput={renderInputBill}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs sx={{flexBasis: '16%'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disableFuture={true}
                    label={strings.zho.fcAdmin.requestForm.filters.endDate}
                    inputFormat="DD/MM/YYYY"
                    className={classes.filterToDate}
                    // PopperProps={DialogProps}
                    views={['year', 'month', 'day']}
                    value={endDate}
                    onChange={e => handleDateChange(e, 'end')}
                    renderInput={renderInputBill}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs sx={{flexBasis: '16%', fontSize: '12.7px'}}>
                <TextField
                  onChange={e => handleFilterChange(e, 'search', null)}
                  data-testid="requestform-no-filter"
                  size="small"
                  id="orderFormNo"
                  placeholder="Request Form/Order No."
                  value={orderFormNo}
                  sx={{
                    color: 'red !important',
                    fontSize: '12.7px !important',

                    '& .MuiInputBase-input': {
                      height: '17px !important',
                    },
                  }}
                  label={strings.zho.hoAdmin.dashboard.filters.requestFormOrder}
                  variant="outlined"
                  className={` ${classes.filter}`}
                />
              </Grid>
              <Grid item xs sx={{flexBasis: '16%', fontSize: '12.7px'}}>
                <FormControl fullWidth className={classes.dropdownBgWhite}>
                  <Autocomplete
                    value={
                      divisionData.find(
                        (item: Division) => item.divisionId === division,
                      ) || null
                    }
                    onChange={(e, value: any) =>
                      handleFilterChange(e, 'division', value)
                    }
                    noOptionsText="No options"
                    data-testid="test-division-dropdown"
                    key={'search-Division'}
                    slotProps={{
                      popper: {
                        sx: {
                          zIndex: 1000,
                        },
                      },
                    }}
                    sx={{
                      minWidth: 180,
                      maxWidth: 180,
                      paddingRight: '0px !important',

                      '& .MuiAutocomplete-input': {
                        width: '1px !important',
                        minWidth: '1px !important',
                      },
                      '& .MuiInputLabel-formControl': {
                        fontSize: '11.7px !important',
                      },
                      '& .MuiAutocomplete-endAdornment svg': {
                        fill: '#322b7c',
                      },
                    }}
                    options={divisionData}
                    getOptionLabel={(option: Division) =>
                      option?.divisionName?.length > 13
                        ? option?.divisionName?.slice(0, 13) + '...'
                        : option?.divisionName
                    }
                    renderOption={(props, option: Division) => (
                      <li
                        {...props}
                        key={option.divisionId}
                        style={{
                          fontSize: '12.7px',
                          color: '##1c1939',
                          paddingBottom: '2px',
                          wordBreak: 'break-all',
                        }}>
                        {option.divisionName}
                      </li>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder={'Division'}
                        className={classes.textField}
                        label="Division"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {divisionLoading ? (
                                <CircularProgress
                                  className={classes.filterLoader}
                                  color="primary"
                                  size={20}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),

                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '11px !important',
                            },
                          },
                        }}
                      />
                    )}
                    renderTags={(value: any) => {
                      if (!value) return null;
                      const firstLabel =
                        value.divisionName?.length > 13
                          ? value.divisionName?.slice(0, 13) + '...'
                          : value.divisionName;
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {firstLabel}
                        </span>
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item sx={{flexBasis: '16%'}}>
                <FormControl fullWidth className={classes.dropdownBgWhite}>
                  <Autocomplete
                    value={
                      stateData.find(
                        (item: StateFilter) => item.stateId === stateId,
                      ) || null
                    }
                    onChange={(e, value: any) =>
                      handleFilterChange(e, 'state', value)
                    }
                    noOptionsText="No options"
                    data-testid="test-product-dropdown"
                    key={'search-state'}
                    slotProps={{
                      popper: {
                        sx: {
                          zIndex: 1000,
                        },
                      },
                    }}
                    sx={{
                      minWidth: 180,
                      maxWidth: 180,
                      paddingRight: '0px !important',

                      '& .MuiAutocomplete-input': {
                        width: '1px !important',
                        minWidth: '1px !important',
                      },
                      '& .MuiInputLabel-formControl': {
                        fontSize: '11.7px !important',
                      },
                      '& .MuiAutocomplete-endAdornment svg': {
                        fill: '#322b7c',
                      },
                    }}
                    options={stateData}
                    getOptionLabel={(option: StateFilter) =>
                      option?.stateName?.length > 13
                        ? option?.stateName?.slice(0, 13) + '...'
                        : option?.stateName
                    }
                    renderOption={(props: any, option: StateFilter) => (
                      <li
                        {...props}
                        key={option?.stateId}
                        style={{
                          fontSize: '12.7px',
                          color: '##1c1939',
                          paddingBottom: '2px',
                          wordBreak: 'break-all',
                        }}>
                        {option.stateName}
                      </li>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder={'Select State'}
                        className={classes.textField}
                        label="Select State"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {stateLoading ? (
                                <CircularProgress
                                  className={classes.filterLoader}
                                  color="primary"
                                  size={20}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '11px !important',
                            },
                          },
                        }}
                      />
                    )}
                    renderTags={(value: any) => {
                      if (!value) return null;
                      const firstLabel =
                        value.stateName?.length > 13
                          ? value.stateName?.slice(0, 13) + '...'
                          : value.stateName;
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {firstLabel}
                        </span>
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sx={{flexBasis: '16%'}}>
                <FormControl
                  sx={{height: '34px !important'}}
                  fullWidth
                  className={classes.dropdownBgWhite}>
                  <Autocomplete
                    noOptionsText="No options"
                    disableCloseOnSelect
                    data-testid="test-product-dropdown"
                    onChange={(e, value) => handleFilterChange(e, 'hq', value)}
                    value={hqData.filter((item: HqFilter) =>
                      hqName.includes(item.hqId),
                    )}
                    key={'search Hq'}
                    slotProps={{
                      popper: {
                        sx: {
                          zIndex: 1000,
                        },
                      },
                    }}
                    sx={{
                      minWidth: 180,
                      maxWidth: 180,
                      paddingRight: '0px !important',
                      '& .MuiAutocomplete-input': {
                        width: '2px !important',
                        minWidth: '2px !important',
                        paddingLeft: '1px !important',
                      },
                      '& .MuiOutlinedInput-root': {
                        paddingRight: '0px !important',
                      },
                      '& .MuiInputLabel-formControl': {
                        fontSize: '11.7px !important',
                      },
                      '& .MuiAutocomplete-endAdornment svg': {
                        fill: '#322b7c',
                      },
                    }}
                    multiple
                    options={hqData}
                    getOptionLabel={(option: any) => option.hqName}
                    renderOption={(props, option, {selected}) => (
                      <li
                        {...props}
                        key={option.hqId}
                        style={{
                          fontSize: '12.7px',
                          padding: '0px',
                          color: '##1c1939',
                          paddingBottom: '2px',
                          wordBreak: 'break-all',
                        }}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={
                            option.hqId === 'select_all'
                              ? hqName.length === hqData.length
                              : selected
                          }
                        />
                        {option.hqName}
                      </li>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder={'Select HQ'}
                        className={classes.textField}
                        label="Select HQ"
                        InputProps={{
                          ...params.InputProps,
                          onFocus: () => handleInputFocus(2),
                          onBlur: handleInputBlur,
                          endAdornment: (
                            <>
                              {hqLoading ? (
                                <CircularProgress
                                  className={classes.filterLoader}
                                  color="primary"
                                  size={20}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '11px !important',
                              opacity:
                                hqName.length !== 0 &&
                                isFocused.focus &&
                                isFocused.id === 2
                                  ? 0.5
                                  : 0,
                            },
                          },
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) => {
                      if (isFocused.focus && isFocused.id === 2) return null; // Hide tags when input is focused
                      if (value.length === 0) return null;

                      const firstLabel = value[0]?.hqName?.slice(0, 13) + '...';
                      const additionalCount =
                        value?.length > 1
                          ? ` ${value?.length} HQ's selected`
                          : '';

                      if (value?.length === 1) {
                        return (
                          <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                            {firstLabel}
                          </span>
                        );
                      } else {
                        return (
                          <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                            {additionalCount}
                          </span>
                        );
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sx={{flexBasis: '16%'}}>
                <FormControl
                  sx={{height: '34px !important'}}
                  fullWidth
                  className={classes.dropdownBgWhite}>
                  <Autocomplete
                    noOptionsText="No options"
                    disableCloseOnSelect
                    onChange={(e, value) => handleFilterChange(e, 'mr', value)}
                    value={mrData?.filter((item: MrFilter) =>
                      mrName.includes(item.mrId),
                    )}
                    data-testid="test-product-dropdown"
                    key={'search-Mr'}
                    slotProps={{
                      popper: {
                        sx: {
                          zIndex: 1000,
                        },
                      },
                    }}
                    sx={{
                      minWidth: 180,
                      maxWidth: 180,
                      paddingRight: '0px !important',
                      '& .MuiAutocomplete-input': {
                        width: '2px !important',
                        minWidth: '2px !important',
                        paddingLeft: '1px !important',
                      },
                      '& .MuiOutlinedInput-root': {
                        paddingRight: '0px !important',
                      },
                      '& .MuiInputLabel-formControl': {
                        fontSize: '11.7px !important',
                      },
                      '& .MuiAutocomplete-endAdornment svg': {
                        fill: '#322b7c',
                      },
                    }}
                    multiple
                    options={mrData}
                    getOptionLabel={(option: MrFilter) =>
                      option?.mrName?.length > 13
                        ? option.mrName?.slice(0, 13) + '...'
                        : option.mrName
                    }
                    renderOption={(props, option: any, {selected}, index) => (
                      <li
                        {...props}
                        key={+option.mrId}
                        style={{
                          fontSize: '12.7px',
                          padding: '0px',
                          color: '##1c1939',
                          paddingBottom: '2px',
                          wordBreak: 'break-all',
                        }}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={
                            option.mrId === 'select_all'
                              ? mrName.length === mrData.length
                              : selected
                          }
                        />
                        {`${option.mrName} (${option.sapCode})`}
                      </li>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder={'Select MR'}
                        className={classes.textField}
                        label="Select MR"
                        InputProps={{
                          ...params.InputProps,
                          onFocus: () => handleInputFocus(3),
                          onBlur: handleInputBlur,
                          endAdornment: (
                            <>
                              {mrLoading ? (
                                <CircularProgress
                                  className={classes.filterLoader}
                                  color="primary"
                                  size={20}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '11px !important',
                              opacity:
                                hqName.length !== 0 &&
                                isFocused.focus &&
                                isFocused.id === 3
                                  ? 0.5
                                  : 0,
                            },
                          },
                        }}
                      />
                    )}
                    renderTags={(value: any, getTagProps) => {
                      if (isFocused.focus && isFocused.id === 3) return null; // Hide tags when input is focused
                      if (value.length === 0) return null;
                      const firstLabel = value[0]?.mrName?.slice(0, 13) + '...';
                      const additionalCount =
                        value?.length > 1
                          ? ` ${value.length} Mr's selected`
                          : '';
                      if (value.length === 1) {
                        return (
                          <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                            {firstLabel}
                          </span>
                        );
                      } else {
                        return (
                          <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                            {additionalCount}
                          </span>
                        );
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Scrollbar>
          <Button
            onClick={handleFilterApiCall}
            data-testid="goButton"
            variant="contained"
            component="span"
            className={classes.requestFormgo}
            // disabled={goButton}
            sx={{
              maxWidth: '10px',
              fontSize: '12.7px',
              textTransform: 'capitalize',
            }}
            size="medium">
            {strings.go}
          </Button>
        </div>

        {/* <GridTable handleToggleModal={handleToggleModal} /> */}
        <OrderFormTable
          setSyncApproveChecking={setSyncApproveChecking}
          syncApproveChecking={syncApproveChecking}
          setApproveModalOpen={setApproveModalOpen}
          approveModalOpen={approveModalOpen}
          setIsSynced={setIsSynced}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          setOrderFormId={setOrderFormId}
          handleToggleModal={handleToggleModal}
          userLoginInfo={userLoginInfo}
        />
        {/* <Togglefilter segments={segmentData2} /> */}
      </Container>
    </>
  );
};
