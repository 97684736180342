import {Button, Container} from '@mui/material';
import {useEffect, useState} from 'react';
import YearlySummaryHeader from '@app/screens/module-specific-utilities/components/expense-reports/yearly-disbursement-summary/yearlySummaryHeader';
import YearlySummaryReport from '@app/screens/module-specific-utilities/components/expense-reports/yearly-disbursement-summary/yearlySummaryReport';
import {useDispatch} from 'react-redux';
import {useStyles} from '@app/screens/Styles/style';
import {routeStateActions} from '@app/router/redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { strings } from '@app/common/strings';

const YearlySummaryContent: React.FC<{setReportBack: any}> = ({
  setReportBack,
}) => {
  const classes = useStyles();
  const [report, setReport] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Button
            onClick={() => setReportBack('')}
            style={{alignSelf: 'flex-start'}}>
            <ArrowBackIosIcon />
          </Button>
          <div
            style={{
              height: 75,
              fontFamily: 'Poppins',
              fontSize: 18,
              fontWeight: '600',
              fontStyle: 'normal',
              letterSpacing: 0,
              textAlign: 'left',
              color: '#1c1939',
              padding: 4
            }}>
           {strings.ModuleSpecificUtilitie.expenseReportPage.Yearly_Disbursement_Summary}
          </div>
        </div>,
      ),
    );
    return () => {
      dispatch(
        routeStateActions.setNavbarComponentHeading(
          <div className={classes.navbarComponentHeadingEmpty}>
            {strings.ModuleSpecificUtilitie.expenseReportPage.expenseReport}
          </div>,
        ),
      );
    };
  }, []);
  return (
    <Container maxWidth={false} style={{width: '100%'}}>
      <YearlySummaryHeader setReport={setReport} />
      {report && <YearlySummaryReport />}
    </Container>
  );
};

export default YearlySummaryContent;
