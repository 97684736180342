import {makeStyles} from '@mui/styles';
import Colors from '@app/themes/colors';
import {color} from '@mui/system';
import theme from '@app/themes';

export const useStyles = makeStyles({
  MuiTableRoot: {
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
    border: 'transparent',
  },
  modelPopup: {
    width: '60%',
    height: 'auto',
    border: '25px',
    margin: 'auto',
    right: 0,
    backgroundColor: '#ffffff',
    boxShadow: ' 0 4 #000000 26.7 0 25%',
    zIndex: 2000,
  },
  RfHeadingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  RfHeading: {
    color: '#1C1939',
    marginBottom: '20px',
    fontWeight: '600',
  },
  paperButton: {
    border: '3px solid #322b7c',
    backgroundColor: Colors.white,
    borderRadius: '5px',
    width: '160px',
    height: '35px',
    textTransform: 'capitalize',
    fontFamily: 'Poppins',
    fontSize: '12.7px',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.primary,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.white,
    },
  },
  applyButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '160px',
    height: '35px',
    fontFamily: 'Poppins',
    textTransform: 'capitalize',
    fontSize: '12.7px',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    marginLeft: 10,
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
    '&:disabled': {
      backgroundColor: Colors.grey[100],
      border: `3px solid ${Colors.grey[100]}`,
    },
  },

  requestFormSmallText: {
    fontSize: '11.7px',
  },

  priDefaultFontSize: {
    fontSize: '12.7px !important',
    color: `${Colors.grey[200]} !important`,
    fontWeight: '400',
  },
  approved: {
    color: '#40E0D0',
  },
  noBorderBottom: {
    borderBottom: 'none',
  },
  pending: {
    color: '#F5AD00',
  },
  defaultText: {
    color: Colors.grey[200],
    fontSize: '12.7px',
    // minWidth: 'auto !important',
  },
  defaultLightText: {
    color: '#1C1939B3',
    opacity: '0.7',
    fontSize: '10.7px',
    fontWeight: 600,
  },
  headingMarginBottom: {
    marginBottom: '15px',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginEnd: {
    marginLeft: 'auto',
  },
  noBoxShd: {
    boxShadow: 'none',
    borderRadius: '0px',
    paddingRight: '2px',
  },
  detailsBox: {
    boxShadow: 'none',
    border: `1px solid ${theme.colors.grey[400]} `,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    padding: '16px',
  },

  productDetailsTableBody: {
    boxShadow: 'none',
  },
  aprovalStatusTableBody: {
    boxShadow: 'none',
  },

  editIcon: {
    height: '20px',
    width: '20px',
    opacity: '1',
    marginLeft: '1rem',
    fill: theme.colors.primary,
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  proDeatilsThead: {
    background: '#7090B01F',
    border: '1px solid #e2e2e2 ',
    borderRight: '0px !important',
    borderTop: '0px',
    borderBottom: '0px',
  },
  proDetailsCompet: {
    borderTop: '1px solid ',
  },
  proDetailsTheadingOdd: {
    background: '#ffffff',
  },

  proDetailsTheadingEven: {
    background: '#dde7eb',
  },
  proDetailsColorThead: {
    background: theme.colors.primary,
    padding: '0px 15px',
  },
  textWhite: {
    color: '#ffffff',
  },

  proDetailCompDividerSmall: {
    borderBottom: '1px solid  #c2d1dc ',
    fontSize: '11.7px !important',
    color: `${Colors.grey[200]} !important`,
    fontWeight: '400',
    textWrap: 'nowrap',
  },

  proDetailContainerBorder: {
    border: '1px solid #e2e2e2',
    borderRadius: '10px',
  },

  proDetailsCompDivider: {
    borderBottom: '1px solid  #e2e2e2 ',
    fontWeight: '600',
    fontSize: '12.7px !important',
  },
  proDetailsLeftBorder: {
    borderLeft: '1px solid #c2d1dc !important ',
  },
  proDetailsRightBorder: {
    borderRight: '1px solid #c2d1dc ',
  },
  borderBottomNone: {
    borderBottom: 'none',
  },
  tedProBorder: {
    border: '2px solid red',
  },
  deletedProBorderBottom: {
    borderBottom: '2px solid #da3131',
  },
  detailsNamesWidth: {
    minWidth: '100px',
    paddingRight: '20px',
  },
  proDetailsHeading: {
    minWidth: '180px',
  },
  cellPadding: {
    padding: '8px 16px',
  },
  reviewCellPadding: {
    padding: '8px',
    paddingRight: '4px',
  },
  proDetailsRoundCorner: {
    borderTopLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  proDetailsTableTopHeadings: {
    borderBottom: 'none',
    background: theme.colors.primary,
    padding: '2px 4px',
    fontSize: '11.7px',
    textAlign: 'center',
    color: '#ffffff',
  },
  toggleProDetailsTableTopHeadings: {
    borderBottom: 'none',
    background: theme.colors.primary,
    padding: '2px 4px',
    fontSize: '11.7px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    color: '#ffffff',
  },
  justifyEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1.5rem',
  },
  detailsCustomerNames: {
    color: Colors.grey[200],
    fontSize: '12.7px',
    whiteSpace: 'nowrap',
    borderRight: '1px solid #c2d1dc ',
    paddingRight: '20px',
    fontWeight: '400',
  },
  noBorder: {
    border: 'none !important',
    background: '#ffffff !important',
  },

  fontBold: {
    fontWeight: '600',
  },

  productTableLeftBorder: {
    borderLeft: `1.2px solid ${theme.colors.grey[400]} `,
  },
  productTableRightBorder: {
    borderRight: `1.2px solid ${theme.colors.grey[400]} `,
  },
  updateProdListing: {
    marginTop: '25px',
    width: '100%',
    background: '#f4f4f4',
    borderRadius: '10px',
    padding: '24px',
  },

  borderTopLeft: {
    borderTopLeftRadius: '10px',
  },
  borderTopRight: {
    borderTopRightRadius: '10px',
  },

  borderBottomLeft: {
    borderBottomLeftRadius: '10px',
  },
  borderBottomRight: {
    borderBottomRightRadius: '10px',
  },
  productDetailsInput: {
    width: '47px',
    padding: '0px !important',
    textAlign: 'center',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  selectedProductsWidth: {
    minWidth: '180px',
  },
  requestformTableHeading: {
    fontWeight: '600',
    fontSize: '11.7px',
    color: theme.colors.primary,
  },
  requestFormHeadingWidth: {
    minWidth: '60px !important',
  },

  requestFormHeadingLgWidth: {
    minWidth: '105px !important',
  },

  requestFormTableFont: {
    fontSize: '11.7px',
    color: '##1C1939',
  },

  requestFormTableBorderLeft: {
    borderLeft: '1px #f2f2f2 solid',
  },
  requestFormTableValueFont: {
    fontSize: '12.7px',
  },
  actionSticky: {
    position: 'sticky',
    top: 0,
    right: 0,
  },

  hoOrderLeftHeading1: {
    position: 'sticky',
    top: '0',
    left: '0',
    zIndex: '999',
    background: 'white',
  },
  hoOrderLeftHeading2: {
    position: 'sticky',
    top: '0',
    left: '73px',
    zIndex: '999',
    background: 'white',
  },

  hoOrderRightBorder: {
    borderRight: '1px solid #f2f2f2 ',
  },
  productDetailsTableSpacing: {
    // borderSpacing: '0px 12px',
    borderCollapse: 'separate',
  },
  productDetailsTableHeading: {
    // display: 'table',
    // borderSpacing: '0px 0px',
  },

  hoTableBorderTop: {
    borderTop: '1px solid #e2e2e2',
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1,
  },

  highlightBorderwithStatus: {
    borderBottom: '2px solid #DA3131 !important',
  },

  customToolTipIcon: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    borderRadius: '100%',
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '-25px',
    right: '-25px',
  },
  requestformTbleBoxSdw: {
    // boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 5px 0px',
    boxShadow: ' 0px 1.23px 5px -0.5px #0000001A',
  },
  checkBoxShd: {
    boxShadow: 'rgba(255, 255, 255) 3px 6px 2.6px',
  },
});
