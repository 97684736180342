import {useEffect, useState} from 'react';
import {Order} from './Order';
import './index.css';
import {Invoice} from './Invoicing';
import {
  RootStateOrAny,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  setRightSidebarToggle,
  setScreenNo,
  SwitchingNo,
} from '../fc-admin/redux/tabName';
import RightSidebarView from '../../components/right-sidebar/rightSidebarView';
import {Stack} from '@mui/system';
import {filterStateActions} from '@app/components/right-sidebar/redux';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import ViewPodContainer from './Invoicing/components/view-Pod/viewPodContainer';
import {
  fetchHosHeaderCountCreator,
  fetchPerformanceCreator,
} from './redux-api/slice';
import {Summary} from './summary';
export const HOAdmin = () => {
  const dispatch = useDispatch();
  const [showRightPopup, setShowRightPopup] = useState(false);
  const zhoRightSidebarVisible = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.RightSidebar,
    shallowEqual,
  );

  const userLoginInfo = useSelector(
    (state: RootStateOrAny) => state.appState.userInfo,
  );
  const tab = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.activeTabIndex,
  );

  const RightPopup = () => {
    setShowRightPopup(!showRightPopup);
  };

  useEffect(() => {
    dispatch(filterStateActions.setFilterState({}));
    dispatch(
      routeStateActions.setNavbarComponentName(navbarComponentName.fcAdmin),
    );
    dispatch(fetchPerformanceCreator());
    dispatch(setRightSidebarToggle(true));
    dispatch(setScreenNo(2));
    dispatch(fetchHosHeaderCountCreator(userLoginInfo?.staffPositionId));
    return () => {
      dispatch(setScreenNo(0));
      dispatch(SwitchingNo(0));
      dispatch(setRightSidebarToggle(false));
    };
  }, []);
  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <Order RightToggle={RightPopup} userLoginInfo={userLoginInfo} />;
      case 1:
        return <Invoice userLoginInfo={userLoginInfo} />;
      case 2:
        return <Summary userLoginInfo={userLoginInfo} />;
      case 10:
        return <ViewPodContainer />;
      default:
        return <Order userLoginInfo={userLoginInfo} />;
    }
  };

  return (
    <>
      <Stack paddingTop={0} width="100%" direction="row">
        {renderComponent()}
        {zhoRightSidebarVisible && <RightSidebarView />}
      </Stack>
    </>
  );
};
