import {makeStyles} from '@mui/styles';
export const useStyles = makeStyles({
  grid: {
    border: 0,
    padding: '0px 2px 0px 2px',
  },
});

export const gridStyles = {
  '& .text-align-center': {
    textAlign: 'center',
  },
  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important',
  },

  '& .MuiDataGrid-cell': {
    lineHeight: 'unset !important',
    maxHeight: 'none !important',
    whiteSpace: 'normal',
    color: '#1c1939',
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    fontSize: 12.7,
    border: '1px #f2f2f2 solid',
    borderTopWidth: 0,
    borderBottomWidth: 0,
    padding: "0px 10px",
    outline: 'none !important',
  },
  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
    border: '2px #f2f2f2 solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    width: '100%',
  },
  '& .boldHeader': {
    fontFamily: 'Poppins',
    fontSize: 12.7,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '21.3px',
    letterSpacing: 0,
    textAlign: 'left',
    color: '#322b7c',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    outline: 'none !important',
  },
  '& .MuiDataGrid-selectedRowCount': {
    visibility: 'hidden',
  },
};
