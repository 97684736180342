import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {MenuProp, useStyles} from '@app/screens/Styles/style';
import {
  Box,
  Button,
  Container,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {ConfigurationManagementSelector} from '@app/screens/configuration-management/components/redux/seletors';
import {
  configurationManagementStateActions,
  createProRataMiscExpenseCreator,
  fetchExpenseTypeDropdownCreator,
} from '@app/screens/configuration-management/components/redux/slice';
import {AddExpenseProRataMiscExpense} from '../../components/pro-rata-misc-grid-popup';
import {ProRataMiscExpenseGrid} from '../../components/pro-Rata-Misc-Expense-grid';
import {appSelector} from '@app/store/selectors';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {strings} from '@app/common/strings';

export const util: any = {
  handleChangeexpenseType: null,
  handleOnClickGo: null,
};

export const ProRataMiscExpense = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [expenseType, setExpenseType] = useState('');
  const [open, setOpen] = useState(false);
  const addExpenseProRataConfigManagementPopupVisible = useSelector(
    ConfigurationManagementSelector.getProRataMiscExpensePopupVisile(),
  );
  const {isWriteAccess = false} = useSelector(
    appSelector.getUserInfo(),
    shallowEqual,
  );
  const expenseTypeDropdown = useSelector(
    ConfigurationManagementSelector.getExpenseType(),
  );
  const expense = useSelector(
    ConfigurationManagementSelector.getProRataMiscExpense(),
  );

  const request = useSelector(filterSelector.getFilterOptions());
  const handleChangeexpenseType = (event: {target: {value: any}}) => {
    setExpenseType(event?.target?.value);
  };
  const handleOnClickGo = () => {
    setOpen(true);
    dispatch(
      filterStateActions.setFilterOptions({
        ...request,
        expenseTypeId: expenseType,
      }),
    );
    dispatch(createProRataMiscExpenseCreator({}));
  };
  util.handleChangeexpenseType = handleChangeexpenseType;
  util.handleOnClickGo = handleOnClickGo;
  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.ConfigurationManagement,
      ),
    );
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeading}>
          {strings.navBarProRataMiscExpense}
        </div>,
      ),
    );
    dispatch(fetchExpenseTypeDropdownCreator(true));
    dispatch(
      configurationManagementStateActions.setconfigurationManagementPage(false),
    );
    dispatch(configurationManagementStateActions.setCurrentPage(1));
    return () => {
      dispatch(configurationManagementStateActions.setExpenseTypeSetting([]));
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(
        configurationManagementStateActions.setconfigurationManagementPage(
          true,
        ),
      );
    };
  }, [classes.navbarComponentHeading, dispatch]);

  return (
    <div>
      <Container maxWidth={false}>
        <Stack spacing={0.5} p={2} direction="row" justifyContent="left">
          <Box className={classes.boxWidth}>
            <Typography className={classes.label}>
              {strings.componentManagement.expenseType}
            </Typography>
            <FormControl size="small" fullWidth>
              <Select
                size="small"
                disabled={!isWriteAccess}
                value={expenseType}
                placeholder="expenseType"
                MenuProps={MenuProp}
                inputProps={{
                  'data-testid': 'dropdown-setDivision',
                }}
                onChange={handleChangeexpenseType}
                className={classes.select}>
                {expenseTypeDropdown?.map((Value: any) => (
                  <MenuItem
                    key={Value?.expenseTypeID}
                    value={Value?.expenseTypeID}>
                    {Value?.expenseTypeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{marginTop: '5px !important'}}>
            <Button
              data-testid="button-handleOnClickGo"
              variant="contained"
              disabled={!isWriteAccess || !expenseType}
              size="large"
              className={classes.goButton}
              onClick={handleOnClickGo}>
              {strings.go}
            </Button>
          </Box>
        </Stack>

        {open && (
          <>
            {' '}
            <ProRataMiscExpenseGrid expenseTypeSetting={expense ?? []} />
          </>
        )}
        {addExpenseProRataConfigManagementPopupVisible && (
          <AddExpenseProRataMiscExpense />
        )}
      </Container>
    </div>
  );
};
export default ProRataMiscExpense;
