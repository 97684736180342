import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Modal from 'react-modal';
import {updateTotalTradeDiscountStyles as styles} from '../styles';
import {Search} from '@mui/icons-material';
import {strings} from '@app/common/strings';
import {Fragment, useCallback, useEffect, useState} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {
  fetchAddProductTotalDiscountDataCreator,
  fetchGenerateProductExcelFileDataCreator,
  fetchProductForDiscountDataCreator,
} from '../../redux-api/slice';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import ClearIcon from '@mui/icons-material/Clear';
import {Stack} from '@mui/system';
import theme from '@app/themes';
import {UploadFile} from './uploadFile';
import {Dayjs} from 'dayjs';
import 'react-toastify/dist/ReactToastify.css';
import {ErrorTable} from './errorTable';

interface Product {
  productId: number;
  name: string;
  standardDiscount: number;
  additionalDiscount?: number;
  dailyQuantity?: number;
  monthlyQuantity?: number;
  validFrom?: Dayjs | null;
}

const AddProductDiscount = ({userLoginInfo}: any) => {
  const dispatch = useDispatch();
  const [selectedDivisionId, setSelectedDivisionId] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [checkValidation, setCheckValidation] = useState<boolean>(false);
  const [productSearchData, setProductSearchData] = useState<Array<Product>>(
    [],
  );
  const [selectedProducts, setSelectedProducts] = useState<Array<Product>>([]);

  const divisionData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.divisionData,
  );
  const productData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.productForDiscountData,
  );
  const UpdateAddProductTotalDiscountCompleted = useSelector(
    (state: RootStateOrAny) =>
      state.fcDashBoard.addProductTotalDiscountCompleted,
  );
  const errorData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.uploadProductExcelFileData,
  );

  const renderChipData = () => {
    const uniqueProductsSet: Set<number> = new Set();

    productSearchData
      ?.slice(0, 7)
      .forEach(product => uniqueProductsSet.add(product.productId));
    selectedProducts?.forEach(product =>
      uniqueProductsSet.add(product.productId),
    );

    const concatenatedArray: Product[] = [];
    uniqueProductsSet.forEach(productId => {
      const productFromSelected = selectedProducts?.find(
        p => p.productId === productId,
      );
      if (productFromSelected) {
        concatenatedArray.unshift(productFromSelected);
      } else {
        const productFromSearch = productSearchData?.find(
          p => p.productId === productId,
        );
        if (productFromSearch) {
          concatenatedArray.push(productFromSearch);
        }
      }
    });

    return concatenatedArray;
  };

  const handleToggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const getOptionDisabled = (option: Product) => {
    return selectedProducts.some(
      product => product?.productId === option?.productId,
    );
  };

  const isProductSelected = (productId: number): boolean => {
    return selectedProducts.some(item => item.productId === productId);
  };

  const handleDivisionChange = (divisionId: number) => {
    setSelectedDivisionId(divisionId);
    dispatch(fetchProductForDiscountDataCreator(divisionId));
    setSelectedProducts([]);
    setCheckValidation(false);
  };

  const handleAddProduct = (product: any) => {
    setCheckValidation(false);
    if (
      product !== null &&
      !selectedProducts.some(item => item.productId === product?.productId)
    ) {
      const newProduct = {
        ...product,
        validFrom: new Date().toISOString(),
        additionalDiscount: null,
        dailyQuantity: null,
        monthlyQuantity: null,
      };
      setSelectedProducts([...selectedProducts, newProduct]);
    }
  };

  const handleRemoveProduct = (id: number) => {
    setSelectedProducts(prevProducts =>
      prevProducts.filter(product => product.productId !== id),
    );
  };

  const percentageInputValidation = (e: any) => {
    let inputValue = e.target.value;
    const cursorPosition = e.target.selectionStart;
    const previousValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, '');
    const parts = inputValue.split('.');
    if (parts[0].length > 3) {
      parts[0] = parts[0].slice(0, 3);
    }
    if (parts.length > 1) {
      parts[1] = parts[1].slice(0, 2);
      inputValue = parts[0] + '.' + parts[1];
    } else {
      inputValue = parts[0];
    }
    e.target.value = inputValue;
    let newCursorPosition = cursorPosition;
    if (previousValue.length > inputValue.length) {
      newCursorPosition -= previousValue.length - inputValue.length;
    } else if (previousValue.length < inputValue.length) {
      newCursorPosition += inputValue.length - previousValue.length;
    }
    setTimeout(
      () => e.target.setSelectionRange(newCursorPosition, newCursorPosition),
      0,
    );
  };

  const handleInputChange = (
    productId: number,
    fieldName: keyof Product,
    value: any,
  ) => {
    setSelectedProducts(prevProducts =>
      prevProducts.map(product =>
        product.productId === productId
          ? {...product, [fieldName]: value}
          : product,
      ),
    );
  };

  const handleSubmitClick = () => {
    setCheckValidation(true);
    const hasInValidValues = selectedProducts.some(
      product =>
        product.additionalDiscount === undefined ||
        product.additionalDiscount === null ||
        product.additionalDiscount <= 0 ||
        product.additionalDiscount > 100 ||
        (product.dailyQuantity !== undefined &&
          product.dailyQuantity !== null &&
          product.dailyQuantity < 0) ||
        (product.monthlyQuantity !== undefined &&
          product.monthlyQuantity !== null &&
          product.monthlyQuantity < 0),
    );
    const payload = {
      productDiscountList: selectedProducts,
      divisionId: selectedDivisionId,
    };
    if (!hasInValidValues) {
      dispatch(fetchAddProductTotalDiscountDataCreator(payload));
    }
  };

  const handleDownloadTemplate = () => {
    dispatch(fetchGenerateProductExcelFileDataCreator(selectedDivisionId));
  };

  useEffect(() => {
    dispatch(fetchProductForDiscountDataCreator(selectedDivisionId));
    setSelectedProducts([]);
  }, [UpdateAddProductTotalDiscountCompleted]);

  useEffect(() => {
    if (productData?.length > 0) {
      setProductSearchData(productData);
    }
  }, [productData]);

  useEffect(() => {
    if (divisionData.length > 0) {
      setSelectedDivisionId(divisionData[0].divisionId);
      dispatch(fetchProductForDiscountDataCreator(divisionData[0].divisionId));
    }
  }, [divisionData]);

  const preventDefaultHandleBill = useCallback(e => e.preventDefault(), []);
  const renderInputBill = useCallback(function (params: any) {
    return (
      <TextField
        {...params}
        helperText={null}
        onKeyDown={preventDefaultHandleBill}
        size="small"
        sx={styles.dateInput}
      />
    );
  }, []);

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };
  const enableScroll = () => {
    document.body.style.overflow = 'auto';
  };

  const capitalizeWord = (str: string) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  };

  return (
    <>
      {/* Popup */}
      <div>
        <Modal
          isOpen={modalOpen}
          onAfterOpen={disableScroll}
          onAfterClose={enableScroll}
          style={{
            content: {
              width: '30%',
              minWidth: '320px',
              height: 'fit-content',
              margin: 'auto',
              right: 0,
              backgroundColor: '#ffffff',
              boxShadow: ' 0 4 #000000 26.7 0 25%',
              borderRadius: '8px',
              padding: '0px',
            },
            overlay: {zIndex: 1200, backgroundColor: '#0000003D'},
          }}>
          <UploadFile
            handleToggleModal={handleToggleModal}
            userLoginInfo={userLoginInfo}
            divisionId={selectedDivisionId}
          />
        </Modal>
      </div>
      <div>
        <Modal
          // isOpen={true}
          isOpen={errorData?.successFullInsert !== undefined}
          onAfterOpen={disableScroll}
          onAfterClose={enableScroll}
          style={{
            content: {
              width: '60%',
              minWidth: '350px',
              height: 'fit-content',
              maxHeight: '95vh',
              margin: 'auto',
              right: 0,
              backgroundColor: '#ffffff',
              boxShadow: ' 0 4 #000000 26.7 0 25%',
              borderRadius: '8px',
              padding: '0px',
            },
            overlay: {zIndex: 1200, backgroundColor: '#0000003D'},
          }}>
          <ErrorTable />
        </Modal>
      </div>
      {/* Popup */}

      <Box sx={styles.container}>
        <Box sx={styles.section}>
          <FormControl
            sx={[styles.productListingDropDown, styles.dropdownBgWhite]}>
            <InputLabel
              sx={{fontSize: '12.7px', color: '#1c1939B3'}}
              id="division">
              {strings.zho.division}
            </InputLabel>
            <Select
              sx={styles.productListingDropDown}
              labelId="division"
              data-testid="division-dropdown"
              id="division"
              size="small"
              value={selectedDivisionId}
              label={strings.zho.division}
              onChange={e => handleDivisionChange(Number(e.target.value))}>
              {divisionData?.map((division: any) => {
                return (
                  <MenuItem
                    value={division?.divisionId}
                    sx={{fontSize: '12.7px'}}>
                    {division?.divisionName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={styles.section}>
          <FormControl fullWidth>
            <Autocomplete
              disableCloseOnSelect
              data-testid="test-product-dropdown"
              key={'search-product'}
              sx={{width: '100%', border: '0px'}}
              onChange={(e, value) => {
                handleAddProduct(value);
              }}
              options={productSearchData}
              getOptionDisabled={getOptionDisabled}
              getOptionLabel={(option: Product) => capitalizeWord(option.name)}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option?.productId}
                  style={{fontSize: '12.7px'}}>
                  {capitalizeWord(option.name)}
                </li>
              )}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder={
                    strings.zho.fcAdmin.updateLimit.searchProductPlaceholder
                  }
                  sx={styles.textField}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start" sx={styles.padding5}>
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box sx={styles.section}>
          <Stack
            marginTop={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
            gap={1}>
            {renderChipData().map(product => {
              return (
                <Chip
                  sx={{
                    minWidth: '150px',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    fontSize: '11.7px',
                    height: '28px',
                  }}
                  data-testid="product-chip"
                  label={capitalizeWord(product?.name)}
                  color="primary"
                  variant={
                    isProductSelected(product.productId) ? 'filled' : 'outlined'
                  }
                  onClick={() => handleAddProduct(product)}
                  onDelete={() => handleRemoveProduct(product?.productId)}
                  deleteIcon={
                    isProductSelected(product.productId) ? (
                      <ClearIcon
                        sx={{
                          fill: `${theme.colors.primary}`,
                          borderRadius: '3000px',
                          backgroundColor: '#ffffff',
                          height: '15px',
                          width: '15px',
                        }}
                      />
                    ) : (
                      <></>
                    )
                  }
                />
              );
            })}
          </Stack>
        </Box>
        <Box sx={styles.section}>
          <Table
            aria-label="Product table"
            style={{maxWidth: '100vw', overflowX: 'auto'}}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={[
                    styles.tableCell,
                    styles.tableHeader,
                    {width: '30%', minWidth: '200px'},
                  ]}>
                  {strings.zho.selectedproducts}
                </TableCell>
                <TableCell
                  sx={[
                    styles.tableCell,
                    styles.tableHeader,
                    // styles.centeredCell,
                  ]}>
                  {strings.zho.standardTradeDiscount} %
                </TableCell>
                <TableCell
                  sx={[
                    styles.tableCell,
                    styles.tableHeader,
                    // styles.centeredCell,
                  ]}>
                  <Box sx={styles.requiredHeader}>
                    {strings.zho.additionalradeDiscount} %
                  </Box>
                </TableCell>
                <TableCell
                  sx={[
                    styles.tableCell,
                    styles.tableHeader,
                    // styles.centeredCell,
                  ]}>
                  {strings.zho.fcAdmin.updateLimit.dailyQty}
                </TableCell>
                <TableCell
                  sx={[
                    styles.tableCell,
                    styles.tableHeader,
                    // styles.centeredCell,
                  ]}>
                  {strings.zho.fcAdmin.updateLimit.monthlyQty}
                </TableCell>
                <TableCell
                  sx={[
                    styles.tableCell,
                    styles.tableHeader,
                    // styles.centeredCell,
                    {minWidth: '140px'},
                  ]}>
                  <Box sx={styles.requiredHeader}>
                    {strings.zho.fcAdmin.updateLimit.fromDate}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Fragment>
                {selectedProducts?.map(product => {
                  return (
                    <TableRow key={product?.productId}>
                      <TableCell
                        sx={[styles.tableCell, {width: '30%', padding: '6px'}]}>
                        <Box sx={styles.selectedProductName}>
                          {product?.name}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={[
                          styles.tableCell,
                          styles.actionCell,
                          // styles.centeredCell,
                        ]}>
                        <TextField
                          sx={[styles.cellInput, styles.disabledCellInput]}
                          disabled={true}
                          contentEditable={false}
                          InputProps={{
                            style: styles.cellPropInput,
                          }}
                          value={product?.standardDiscount}
                        />
                      </TableCell>
                      <TableCell
                        sx={[
                          styles.tableCell,
                          styles.actionCell,
                          // styles.centeredCell,
                        ]}>
                        <TextField
                          data-testid="additional-discount-input"
                          error={
                            checkValidation &&
                            (product?.additionalDiscount === undefined ||
                              product?.additionalDiscount === null ||
                              product?.additionalDiscount <= 0 ||
                              product?.additionalDiscount > 100)
                          }
                          sx={[styles.cellInput, styles.hideSpinButton]}
                          InputProps={{
                            style: styles.cellPropInput,
                          }}
                          type="text"
                          value={product?.additionalDiscount}
                          onInput={(e: any) => {
                            percentageInputValidation(e);
                          }}
                          onChange={e =>
                            handleInputChange(
                              product?.productId,
                              'additionalDiscount',
                              e.target.value !== '' && e.target.value[0] !== '.'
                                ? e.target.value
                                : null,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={[
                          styles.tableCell,
                          styles.actionCell,
                          // styles.centeredCell,
                        ]}>
                        <TextField
                          error={
                            checkValidation &&
                            product.dailyQuantity !== undefined &&
                            product.dailyQuantity < 0
                          }
                          sx={[styles.cellInput, styles.hideSpinButton]}
                          InputProps={{
                            style: styles.cellPropInput,
                          }}
                          type="number"
                          value={product?.dailyQuantity}
                          onInput={(e: any) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value),
                            )
                              .toString()
                              .slice(0, 7);
                          }}
                          onChange={e =>
                            handleInputChange(
                              product?.productId,
                              'dailyQuantity',
                              e.target.value !== ''
                                ? Number(e.target.value)
                                : null,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={[
                          styles.tableCell,
                          styles.actionCell,
                          // styles.centeredCell,
                        ]}>
                        <TextField
                          error={
                            checkValidation &&
                            product.monthlyQuantity !== undefined &&
                            product.monthlyQuantity < 0
                          }
                          sx={[styles.cellInput, styles.hideSpinButton]}
                          InputProps={{
                            style: styles.cellPropInput,
                          }}
                          type="number"
                          value={product?.monthlyQuantity}
                          onInput={(e: any) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value),
                            )
                              .toString()
                              .slice(0, 7);
                          }}
                          onChange={e =>
                            handleInputChange(
                              product?.productId,
                              'monthlyQuantity',
                              e.target.value !== ''
                                ? Number(e.target.value)
                                : null,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell sx={[styles.tableCell, styles.actionCell]}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          // sx={{backgroundColor: 'red'}}
                        >
                          <DatePicker
                            inputFormat="DD/MM/YYYY"
                            // PopperProps={DialogProps}
                            disablePast={true}
                            views={['year', 'month', 'day']}
                            value={product?.validFrom}
                            onChange={date =>
                              handleInputChange(
                                product?.productId,
                                'validFrom',
                                date?.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                              )
                            }
                            renderInput={renderInputBill}
                          />
                        </LocalizationProvider>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Fragment>
            </TableBody>
          </Table>
        </Box>
        {selectedProducts.length > 0 && (
          <Box sx={[styles.section, {marginBottom: '25px'}]}>
            <Button
              data-testid="submit-product-for-discount"
              disabled={false}
              sx={styles.actionButton}
              variant="contained"
              onClick={handleSubmitClick}>
              {strings.zho.submit}
            </Button>
          </Box>
        )}
        <Box sx={[styles.section, styles.uploadFileSection]}>
          <Button
            data-testid="upload-product-excel"
            disabled={false}
            sx={styles.actionButton}
            variant="contained"
            onClick={handleToggleModal}>
            {strings.zho.fcAdmin.updateLimit.uploadFile}
          </Button>
          <Box sx={styles.downloadTemplateSection}>
            <Typography sx={styles.bulkUpdloadText}>
              {strings.zho.fcAdmin.updateLimit.bulkUpdateLabel}
            </Typography>
            <Typography
              data-testid="download-excel-template"
              onClick={handleDownloadTemplate}
              sx={styles.downloadTemplateText}>
              {strings.zho.fcAdmin.updateLimit.downloadTemplate}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddProductDiscount;
