import {CustomModal} from '@app/components/widgets/CustomModal';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {Box} from '@mui/system';
import {useDispatch, useSelector} from 'react-redux';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import {Link, Popover, Typography} from '@mui/material';
import {
  LegendColor,
  nameStyles,
  referColor,
  useStyles,
} from '@app/screens/Styles/style';
import {RowRadioButtonsGroup} from './radioButton';
import {navbarCss} from '@app/components/widgets/navbar/navbarCss';
import DaDataGrid from './DaDataGrid';
import TaDataGrid from './TaDataGrid';
import {useEffect, useState} from 'react';
import {fetchDailyExpensePopupTADASaveData} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import theme from '@app/themes';
import {strings} from '@app/common/strings';
import { Footer } from '@app/components/widgets/Footer/Footer';

export const TADAPopup: any = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? strings.moduleSpecificUtilities.simplePopover : undefined;

  const PopupVisible: boolean = useSelector(
    approveExpenseStateSelector.getTaDaPopupVisible(),
  );
  const PopupData = useSelector(
    approveExpenseStateSelector.getPopupReadOnlyData(),
  );
  const PopuptadaData = useSelector(
    approveExpenseStateSelector.getPopuptadaData(),
  );
  const SelectedExpenseApprovalsUser = useSelector(
    approveExpenseStateSelector.getSelectedExpenseApprovalsUser(),
  );
  const taGrid = useSelector(approveExpenseStateSelector.getTaGrid());
  const daGrid = useSelector(approveExpenseStateSelector.getDaGrid());
  const status = useSelector(
    approveExpenseStateSelector.getApproveExpensestatus(),
  );

  const tasave = useSelector(approveExpenseStateSelector.getDisableSave());

  const dasave = useSelector(approveExpenseStateSelector.getDisableDaSave());
  const save = () => {
    return tasave || dasave;
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const taGridDta = () => {
    let arr: any[] = [];

    PopuptadaData?.forEach((items: any, index: number) => {
      if (items?.expenseCategory === strings.ta) {
        let ta = {
          id: items?.id,
          dcrId: PopupData?.expenseDetails?.dcrId,
          expenseCategory: items?.expenseCategory,
          systemAmount: items?.systemAmount,
          customAmount: items?.amount,
          remarks: items?.remarkOthers,
          managerApprovedAmount: items?.managerApprovedAmount,
          managerRemarks: items?.managerRemarks,
          totaldistance: items?.isRuleEngineCalculated
            ? PopupData?.expenseDetails?.adminApprovedDistance
            : 0,
          adminApprovedAmount: items?.adminApprovedAmount,
          adminRemarks: items?.adminRemarks,
          billpath: items?.billPath,
          isRuleEngineCalculated: items?.isRuleEngineCalculated,
          isBillUploaded: items?.isBillUploaded,
        };
        arr?.push(ta);
      }
    });
    return arr;
  };
  const daGridDta = () => {
    let arr1: any[] = [];
    PopuptadaData?.forEach((items: any, index: number) => {
      if (items?.expenseCategory === strings.da) {
        let da = {
          id: items?.id,
          dcrId: PopupData?.expenseDetails?.dcrId,
          expenseCategory: items?.expenseCategory,
          systemAmount: items?.systemAmount,
          customAmount: items?.amount,
          remarks: items?.remarkOthers,
          managerApprovedAmount: items?.managerApprovedAmount,
          managerRemarks: items?.managerRemarks,
          hqTypeAdmin: items?.isRuleEngineCalculated
            ? PopupData?.expenseDetails?.adminApprovedHQType
            : '',
          adminApprovedAmount: items?.adminApprovedAmount,
          adminRemarks: items?.adminRemarks,
          isRuleEngineCalculated: items?.isRuleEngineCalculated,
          billpath: items?.billPath,
          dahqAmount: PopupData?.expenseDetails?.dahqAmount,
          daExAmount: PopupData?.expenseDetails?.daExAmount,
          daOutAmount: PopupData?.expenseDetails?.daOutAmount,
          isBillUploaded: items?.isBillUploaded,
        };
        arr1?.push(da);
      }
    });
    return arr1;
  };

  const getData = () => {
    if (PopuptadaData && PopupData) {
      dispatch(approveExpenseStateActions.setTaGrid(taGridDta() ?? []));

      dispatch(approveExpenseStateActions.setDaGrid(daGridDta() ?? []));
    }
  };
  useEffect(() => {
    getData();
  }, [PopuptadaData, PopupData]);
  const onCloseClick = () => {
    dispatch(approveExpenseStateActions.setTaDaPopupVisible(false));
    dispatch(approveExpenseStateActions.setTaGrid([]));
    dispatch(approveExpenseStateActions.setDaGrid([]));
    dispatch(approveExpenseStateActions.setPopuptadaData([]));
    dispatch(approveExpenseStateActions.setPopupReadOnlyData([]));
    dispatch(approveExpenseStateActions.setBillPathArr([]));
    dispatch(approveExpenseStateActions.setViewBillPopupVisible(false));
  };
  const onResetClick = () => {
    getData();
  };
  const afterApprove = () => {
    return status?.expenseStatusId == 9 ? save() : true;
  };
  const reduce = (arr: any) => {
    return arr
      ?.map((item: {adminApprovedAmount: any}) => item?.adminApprovedAmount)
      ?.reduce((prev: any, next: any) => Number(prev) + Number(next));
  };
  const onSaveClick = () => {
    let ta = taGrid?.find((item: any) => item?.isRuleEngineCalculated);
    let da = daGrid?.find((item: any) => item?.isRuleEngineCalculated);
    let concat = [...taGrid, ...daGrid];
    let arr1 = concat?.map((items: any) => {
      return {
        taDaLookUpId: items?.id,
        adminApprovedAmount: items?.adminApprovedAmount,
        adminRemarks: items?.adminRemarks,
      };
    });

    let request = {
      dcrId: PopupData?.expenseDetails?.dcrId,
      adminApprovedTAAmount: reduce(taGrid),
      adminApprovedDAAmount: reduce(daGrid),
      adminApprovedDistance: ta?.totaldistance,
      adminApprovedHQType: da?.hqTypeAdmin,
      month: SelectedExpenseApprovalsUser?.expenseMonth,
        year: SelectedExpenseApprovalsUser?.expenseYear,
        staffPositionId: SelectedExpenseApprovalsUser?.staffPositionId,
        userId: SelectedExpenseApprovalsUser?.staffUserId,
      AdminApprovedTARemarks: taGrid
        ?.map((val: any) => val?.adminRemarks)
        .filter((element: any) => Boolean(element))
        .join(','),
      AdminApprovedDARemarks: daGrid
        ?.map((val: any) => val?.adminRemarks)
        .filter((element: any) => Boolean(element))
        .join(','),
      saveTaDaLookUp: arr1,
    };
    if (da?.hqTypeAdmin) {
      dispatch(fetchDailyExpensePopupTADASaveData(request));
    }
  };
  const legend = () => {
    return (
      <Typography>
        {LegendColor?.map((item: any) => {
          return (
            <span key={item?.name} style={referColor}>
              <span style={nameStyles(`${item?.color}`)}></span> {item?.name}
            </span>
          );
        })}
      </Typography>
    );
  };
  const triggeringButton = () => {
    legend();
    onSaveClick();
    onResetClick();
    afterApprove();
    daGridDta();
    handleClose();
    save();
    onCloseClick();
    taGridDta();
  };
  return (
    <>
      <button
        data-testid="triggeringButton"
        className={'hiddenButton'}
        onClick={triggeringButton}
      />
      <CustomModal
        fullWidth={true}
        maxWidth={'md'}
        open={PopupVisible}
        onClose={onCloseClick}
        header={
          <Box display={'flex'} justifyContent="space-between">
            <Typography color={`${theme.colors.blue[1400]}`}>
              {PopupData?.expenseDetails?.dcrDate &&
                new Date(
                  PopupData?.expenseDetails?.dcrDate,
                )?.toLocaleDateString('en-us', {
                  year: 'numeric',
                  day: 'numeric',
                  month: 'short',
                })}
            </Typography>
          </Box>
        }
        footer={
          <Footer
            onResetClick={onResetClick}
            onSaveClick={onSaveClick}
            isDisable={afterApprove()}
            hide={true}
          />
        }>
        <Box>
          <RowRadioButtonsGroup
            value={
              PopupData?.expenseDetails?.isNightStayAvailed
                ? strings.moduleSpecificUtilities.availednightstay
                : strings.moduleSpecificUtilities.returnedhome
            }
          />
        </Box>
        <Box className={classes.justifyContentbtflex} sx={{pr: 2}}>
          <Box className={classes.expenseDetailsContainer}>
            <div style={navbarCss.flexSpaceBetween}>
              <Box sx={{my: 1}}>
                <Typography className={classes.labelSizing}>
                  {strings.moduleSpecificUtilities.nightStay}
                </Typography>
                <Box className={classes.labelSizing}>
                  {' '}
                  {PopupData?.expenseDetails?.nightStayGeoLocationName}
                </Box>
              </Box>
              <Box>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      fontWeight: 600,
                      fontSize: '10px',
                      textAlign: 'start',
                      borderBottom: '1px  solid #ddd6d6',
                    }}>
                    <Box sx={{mx: 5, my: 1, width: '40px'}}>
                      {strings.start}
                    </Box>
                    <Box sx={{mx: 5, my: 1, width: '40px'}}>{strings.end}</Box>
                    <Box sx={{mx: 5, my: 1, width: '42px'}}>
                      {strings.distance}
                    </Box>
                  </div>

                  {PopupData?.expenseDistanceBreakup?.map((item: any) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          fontWeight: 400,
                          fontSize: '10px',
                          textAlign: 'start',
                          borderBottom: '1px  solid #ddd6d6',
                        }}
                        key={item?.id}>
                        <Box
                          sx={{mx: 5, my: 1, width: '40px'}}
                          key={item?.startAreaName}>
                          {item?.startAreaName}
                        </Box>
                        <Box
                          sx={{mx: 5, my: 1, width: '40px'}}
                          key={item?.endAreaName}>
                          {item?.endAreaName}
                        </Box>
                        <Box
                          sx={{mx: 5, my: 1, width: '40px'}}
                          key={item?.distanceCovered}>
                          {item?.distanceCovered}
                        </Box>
                      </div>
                    );
                  })}
                </Popover>
              </Box>
            </div>

            <Box>
              <Typography className={classes.labelSizing}>
                {strings.moduleSpecificUtilities.stationCovered}
              </Typography>
              <Box className={classes.labelSizing}>
                {PopupData?.expenseStationsCovered?.map((item: any) => {
                  return (
                    <span
                      style={{paddingRight: '4px'}}
                      key={item?.geoLocationName}>
                      {item?.geoLocationName}
                    </span>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Typography className={classes.labelSizing}>
                {strings.DISTANCE}
              </Typography>
              <Box className={classes.labelSizing}>
                {PopupData?.expenseDetails?.distanceCoveredinKm} {strings.km}
              </Box>
            </Box>
            <Box sx={{my: 2}}>
              <Typography className={classes.labelSizing}>
                {strings.moduleSpecificUtilities.remarks}
              </Typography>
              <Box className={classes.labelSizing}>
                {PopupData?.expenseDetails?.wsfaRemarks}
              </Box>
            </Box>
          </Box>
          <Box>
            <Link
              className={classes.toLink}
              aria-describedby={id}
              onClick={handleClick}>
              {open ? strings.hide : strings.details}
            </Link>
          </Box>
        </Box>
        <Box sx={{my: 2}}>
          <Box sx={{my: 2, display: 'flex', justifyContent: 'space-between'}}>
            <Typography className={classes.labelSizing}>
              {strings.ta}
            </Typography>{' '}
            {legend()}
          </Box>
          <Box className={classes.labelSizing}>
            <TaDataGrid
              gridData={taGrid}
              isDisable={status?.expenseStatusId == 9}
            />
          </Box>
        </Box>
        <Box sx={{my: 2}}>
          <Box sx={{my: 2, display: 'flex', justifyContent: 'space-between'}}>
            <Typography className={classes.labelSizing}>
              {strings.da}
            </Typography>
            {legend()}
          </Box>
          <Box className={classes.labelSizing}>
            <DaDataGrid
              gridDataDa={daGrid}
              isDisable={status?.expenseStatusId == 9}
            />
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};
