import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {useStyles} from '../styles';
import {Stack} from '@mui/material';
import {strings} from '@app/common/strings';
interface ApprovalStatusProps {
  handleToggleModal: (type: string) => void;
}

const RequestFormHeading = ({handleToggleModal}: ApprovalStatusProps) => {
  const classes = useStyles();
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.RfHeadingContainer}>
      <Typography
        sx={{margin: '0px !important'}}
        className={classes.RfHeading}
        fontSize="18.7px"
        fontWeight="600"
        color="#1c1939"
        variant="h6">
        {strings.zho.requestForm}
      </Typography>
      <IconButton
        data-testid="close-modal"
        onClick={() => handleToggleModal('review')}
        color="primary">
        <CloseIcon sx={{marginRight: '-12px'}} fontSize="large" />
      </IconButton>
    </Stack>
  );
};

export default RequestFormHeading;
