import theme from '@app/themes';
import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles({
  PodContainer: {
    boxShadow: '0px 3.48px 23.22px 0px #00000026',
    padding: '1rem',
    borderRadius: '15px',
    marginTop: '-50px'
  },
  viewPodBackBtn:{
    border: `1.5px solid ${Colors.primary}`,
    borderRadius: "50%",
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
    cursor: 'pointer'
  },
  podWidthDimension: {
    width: 'calc(100% - 272px)',
    height: 'calc(100vh - 16px)',
    overflow: 'auto'
  },
  viewContainer: {
    boxShadow: '0px 3.48px 12.22px 0px #00000026',
    padding: '1rem',
    borderRadius: '15px',
    marginTop: '1rem',
  },
  podTableFieldShadow: {
    boxShadow: '-1px 0.5px 4px 0px #00000026',
    // border: '1.5px solid #CACCD1',
    borderRadius: '5px',
    padding: '4px 8px',
    minHeight: '35px',
    display: 'flex',
    alignItems: 'center'
  },
  podHeading: {
    color: '#1C1939',
    fontWeight: '600',
    fontSize: '18.7px',
  },
  podTableText: {
    color: '#1C1939',
    fontWeight: '400',
    fontSize: '12.7px',
  },
  paperButton: {
    border: '3px solid #322b7c',
    backgroundColor: Colors.white,
    borderRadius: '5px',
    width: '160px',
    height: '35px',
    textTransform: 'capitalize',
    fontFamily: 'Poppins',
    fontSize: '12.7px',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.primary,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.white,
    },
  },
  applyButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '160px',
    height: '35px',
    fontFamily: 'Poppins',
    textTransform: 'capitalize',
    fontSize: '12.7px',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    marginLeft: 10,
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
    '&:disabled': {
      backgroundColor: Colors.grey[100],
      border: `3px solid ${Colors.grey[100]}`,
    },
  },
  priDefaultFontSize: {
    fontSize: '12.7px !important',
    color: `${Colors.grey[200]} !important`,
    fontWeight: '400',
  },
  fontBold: {
    fontWeight: '600',
    color: Colors.black,
  },
  cellPadding: {
    padding: '4px',
  },
  podUploadTextBox: {
    minWidth: '455px',
    padding: '0.4rem',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1.25px solid #e2e2e2',
  },
  dateInput:{
    backgroundColor:"#322B7C08",
    borderRadius:'6px',
    color:theme.colors.grey[200],
    width: 120,
    maxWidth: 140,
    '& .MuiInputBase-input': {
      fontSize: 12.7,
    },
    '& .MuiInputLabel-root': {
      fontSize: 12.7,
    },
    '& .MuiSvgIcon-root': {
      fill: '#322b7c',
    },
    '& .MuiInputAdornment-root': {
      marginLeft: '0px',
    },
    '& .MuiIconButton-edgeEnd': {
      paddingLeft: '0px',
      marginRight: '-15px'
    },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   borderColor: `${theme.colors.primary} !important`
    // }
  },
  listingInput:{
    paddingLeft: '0px !important',
    textAlign: 'center',
    '& .MuiInputBase-input': {
      padding: '0px !important',
      textAlign: 'center',
    },
  },
  hideSpinButton: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled':{
      '-webkit-text-fill-color':'rgba(0, 0, 0, 0.6)'
    }
  },
  requiredHeader: {
    "::after": {
      color: "#da3131",
      content: '"*"'
    }
  },
  loader: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    left: '50%',
    top: '70%',
    transform: 'translate(-50%,-50%)',
    zIndex: 1,
  },
  podModalHeader:{
    padding:15,
    display: 'flex',
    justifyContent: 'space-between',
  },
  podModalHeaderTitle:{
    color: theme.colors.grey[200],
    fontSize:15.7
  },
  separation:{
    borderBottom:`0.5px solid ${theme.colors.grey[3400]}!important`,
    paddingBottom: 10
  }
});
