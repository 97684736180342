import {Button, Typography} from '@mui/material';
import {updateTotalTradeDiscountStyles as styles} from '../styles';
import {Box} from '@mui/system';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {fcDashboardActions} from '../../redux-api';
import {useStyles as tableClasses} from '@app/screens/zho/components/grid-table/styles';
import {
  DataGrid,
  GridColDef,
} from '@mui/x-data-grid';
import { strings } from '@app/common/strings';

export const ErrorTable = () => {
  const tableStyles = tableClasses();
  const dispatch = useDispatch();
  const errorData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.uploadProductExcelFileData,
  );

  const handleClose = () => {
    dispatch(fcDashboardActions.setUploadProductExcelFileData([]));
  };

  const columns: GridColDef[] = [
    {
      field: 'worksheet',
      headerName: strings.zho.fcAdmin.updateLimit.worksheet,
      headerClassName: 'boldHeader',
      width: 150,
      sortable: false,
    },
    {
      field: 'row',
      headerName: strings.zho.fcAdmin.updateLimit.row,
      headerClassName: 'boldHeader',
      width: 50,
      sortable: false
    },
    {
      field: 'productId',
      headerName: strings.zho.fcAdmin.updateLimit.productId,
      headerClassName: 'boldHeader',
      width: 100,
      sortable: false
    },
    {
      field: 'productName',
      headerName: strings.zho.fcAdmin.updateLimit.productName,
      headerClassName: 'boldHeader',
      width: 200,
      sortable: false
    },
    {
      field: 'message',
      headerName: strings.zho.fcAdmin.updateLimit.errorMessage,
      headerClassName: 'boldHeader',
      width: 250,
      sortable: false,
    },
  ];

  return (
      <>
        <Box
          sx={[
            styles.modalSection,
            styles.modalHeader,
            {display: 'flex', gap: 2, flexWrap: 'wrap'},
          ]}>
          <Typography sx={{fontSize:"12.7px"}}>
            {strings.zho.fcAdmin.updateLimit.successfulInserts} {errorData?.successFullInsert}
          </Typography>
          <Typography  sx={{fontSize:"12.7px"}}>
          {strings.zho.fcAdmin.updateLimit.successfulUpdates} {errorData?.successFullUpdates}
          </Typography>
        </Box>
        <Box sx={[styles.modalSection, {padding: '0px'}]}>
          {errorData?.errors?.length > 0 ? (
            <Box className={tableStyles.container} sx={{padding:"0 0 0 10px"}}>
              <DataGrid
                rows={errorData?.errors}
                disableSelectionOnClick
                columns={columns}
                initialState={{
                  pagination: {
                    pageSize: 10, 
                  },
                }}
                sx={[styles.errorTable]}
                getRowId={row => row.productId||Math.random()}
                rowsPerPageOptions={[10, 20, 50, 100]}
              />
            </Box>
          ):(
            <Typography sx={{textAlign: 'center', padding: '20px', fontWeight: 400, fontSize:"12.7px"}}>Data inserted without any error</Typography>
          )}
        </Box>
        <Box sx={[styles.modalSection, styles.modalFooter]}>
          <Button data-testid="close-errorTable" variant="contained" sx={{fontSize:"12.7px", textTransform:"capitalize"}} onClick={handleClose}>
          {strings.zho.close}
          </Button>
        </Box>
      </>
  );
};
