import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {useStyles} from '@app/screens/Styles/style';
import {appSliceActions} from '@app/store/reducers/appSlice';
import {Container} from '@mui/system';
import {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {GenerateReportGrid} from '@app/screens/module-specific-utilities/components/generate-report/generate-report-grid';
import {generateReportStateSelector} from '@app/screens/module-specific-utilities/pages/generateReport/redux/selectors';
import {
  fetchGenerateReportCreator,
  generateReportStateActions,
} from './redux/slice';
import { fetchHQTypeDropdownCreator, fetchDesignationsDropdownCreator } from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import { fetchDivisionDataCreator } from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import { strings } from '@app/common/strings';

export const GenerateReport = () => {
  const currentYear = new Date().getFullYear();
  const previousYears = Array.from(
    {length: 5},
    (_, index) => currentYear - index,
  ).map(year => ({label: year.toString()}));
  const filterOrder = ['HQ', 'Division', 'Designation', 'Year', 'Month'];
  const months = [
    {label: 'January'},
    {label: 'February'},
    {label: 'March'},
    {label: 'April'},
    {label: 'May'},
    {label: 'June'},
    {label: 'July'},
    {label: 'August'},
    {label: 'September'},
    {label: 'October'},
    {label: 'November'},
    {label: 'December'},
  ];
  const dispatch = useDispatch();
  const classes = useStyles();
  const filters: any = useSelector(
    filterSelector.getFilterState(),
    shallowEqual,
  );
  const generateReportGrid = useSelector(
    generateReportStateSelector.getGenerateReport(),
  );
  const yearDropdown: any = useSelector(
    generateReportStateSelector.getYearDropdown(),
  );

  const monthDropdown: any = useSelector(
    generateReportStateSelector.getMonthDropdown(),
  );

  const isGenerateReport = useSelector(
    generateReportStateSelector.getIsGenerateReport(),
  );

  useEffect(() => {
    dispatch(filterStateActions.setFilterState({}));
    if (isGenerateReport && yearDropdown?.length > 0 && monthDropdown?.length) {
      const years: any = {};
      const months: any = {};
      yearDropdown?.map((item: any) => {
        years[item.label] = false;
        return null;
      });
      monthDropdown?.map((item: any) => {
        months[item.label] = false;
        return null;
      });
      const generateFilters = filterOrder.reduce(
        (acc, filterKey) => {
          const monthsarr = filterKey === strings.ModuleSpecificUtilitie.generatepage.MONTH ? months : [];
          acc[filterKey] = {
            completeApplied: false,
            collapsed: true,
            options: filterKey === strings.ModuleSpecificUtilitie.generatepage.YEAR ? years : monthsarr,
          };
          return acc;
        },
        {...filters},
      );
      dispatch(filterStateActions.setFilterState(generateFilters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearDropdown, monthDropdown, isGenerateReport]);

  useEffect(() => {
    dispatch(generateReportStateActions.setYearDropdown(previousYears));
    dispatch(generateReportStateActions.setMonthDropdown(months));
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.commonheader,
      ),
    );
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeadingEmpty}>
          {strings.ModuleSpecificUtilitie.generatepage.generateReport}
        </div>,
      ),
    );
    dispatch(generateReportStateActions.setIsGenerateReport(true));
    dispatch(fetchGenerateReportCreator());
    dispatch(fetchDivisionDataCreator());
    dispatch(fetchHQTypeDropdownCreator());
    dispatch(fetchDesignationsDropdownCreator());
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(generateReportStateActions.setYearDropdown([]));
      dispatch(generateReportStateActions.setMonthDropdown([]));
      dispatch(filterStateActions.setFilterState({}));
      dispatch(generateReportStateActions.setIsGenerateReport(false));
      dispatch(generateReportStateActions.setGenerateReportPageNo(0));
      dispatch(
        appSliceActions.setExcelData({
          data: [],
          headers: [],
        }),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div>
      <Container maxWidth={false}>
        <GenerateReportGrid generateReportgridData={generateReportGrid} />
      </Container>
    </div>
  );
};
