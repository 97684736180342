import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {
  expenseDAMasterStateSelector,
  getexpenseDAMasterStateSelector,
} from '@app/screens/master-data-management/pages/expense-da-master/redux/selectors';
import {expenseDAMasterStateActions} from '@app/screens/master-data-management/pages/expense-da-master/redux';
import Stack from '@mui/material/Stack';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {useCallback, useEffect, useState} from 'react';

import {DropdownStyle} from '@app/components/widgets/TextFieldStyle/index';
import {AntSwitch} from '@app/components/widgets/AntSwitch/AntSwitch';
import {
  AddExpenseValidationDAMasterCreator,
  fetchExpenseDAMasterDesignationByDivisionCreator,
  fetchHqByEdividionDesginationDAMasterCreator,
} from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import {muiltipleSelect} from '@app/components/widgets/placeholder/multiselect-placeholder';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePickerStyle} from '@app/components/widgets/datepickerStyle';
import {popUpStyle} from '@app/components/widgets/popupCss/popUpStyles';
import {DialogProps} from '@app/App';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {strings} from '@app/common/strings';

export const AddExpenseDAMaster = () => {
  const dispatch = useDispatch();
  const [expenseDAMasterDivision, setExpenseDAMasterDivision] = useState<any>(
    [],
  );
  const [expenseDAMasterHQ, setExpenseDAMasterHQ] = useState<any>([]);
  const [expenseDAMasterHQType, setExpenseDAMasterHQType] = useState('');
  const [expenseDAMasterAmount, setExpenseDAMasterAmount] = useState('');
  const [expenseDAMasterDesignation, setExpenseDAMasterDesignation] =
    useState<any>([]);
  const [expenseDAMasterStartDates, setExpenseDAMasterStartDates] =
    useState<Date | null>(null);
  const [expenseDAMasterEndDates, setExpenseDAMasterEndDates] =
    useState<Date | null>(null);
  const [expenseDAMasterValidation, setExpenseDAMasterValidation] =
    useState(true);
  const [expenseDAMasterStatus, setExpenseDAMasterStatus] = useState(true);
  const [expenseDAMasterMaxDate, setExpenseDAMasterMaxDate] = useState('');
  const [open, setOpen] = useState(false);
  const [openDesign, setOpenDesign] = useState(false);
  const [reset, setReset] = useState(0);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const designationsDropdown = useSelector(
    expenseDAMasterStateSelector.getDesignationArr(),
  );
  const addExpenseDAMasterPopupVisible = useSelector(
    getexpenseDAMasterStateSelector('addExpenseDAMasterPopupVisible'),
  );
  const HQsDropdown = useSelector(
    expenseDAMasterStateSelector.getHqByDivisionDesignation(),
  );

  const HQTypeDropdown = useSelector(expenseDAMasterStateSelector.getHqType());

  const onResetClick = useCallback(() => {
    setExpenseDAMasterHQ([]);
    setExpenseDAMasterDesignation([]);
    setExpenseDAMasterDivision([]);
    setExpenseDAMasterHQType('');
    setExpenseDAMasterAmount('');
    setExpenseDAMasterStatus(true);
    setExpenseDAMasterEndDates(null);
    setExpenseDAMasterStartDates(null);
    setExpenseDAMasterValidation(true);
    setReset(reset + 1);
    dispatch(expenseDAMasterStateActions.setDesignationArr([]));
    dispatch(expenseDAMasterStateActions.setHqByDivisionDesignation([]));
    dispatch(expenseDAMasterStateActions.setExpenseDAMasterNewData([]));
  }, []);

  const validationDAMaster = () => {
    if (
      expenseDAMasterDivision?.length > 0 &&
      expenseDAMasterDesignation?.length > 0 &&
      expenseDAMasterHQ?.length > 0 &&
      expenseDAMasterHQType?.length > 0 &&
      expenseDAMasterAmount &&
      expenseDAMasterStartDates
    ) {
      setExpenseDAMasterValidation(false);
    } else {
      setExpenseDAMasterValidation(true);
    }
  };

  const handleChangeDesignationDAMaster = useCallback(
    function (_event: any, value: any) {
      setExpenseDAMasterDesignation(value);
    },
    [expenseDAMasterDesignation],
  );

  const handleChangeHQDAMaster = useCallback(
    function (_event: any, value: any) {
      setExpenseDAMasterHQ(value);
    },
    [expenseDAMasterHQ],
  );
  const divisionsDropDown = useSelector(
    approveExpenseStateSelector?.getDivisionData(),
  );
  const handleChangeDivisionDAMaster = useCallback(
    function (_event: any, value: any) {
      setExpenseDAMasterDivision(value);
    },
    [expenseDAMasterDivision],
  );
  const handleChangeDAMasterStartDates = useCallback(
    (value: any) => {
      setExpenseDAMasterStartDates(value);
      setExpenseDAMasterMaxDate(
        new Date(
          new Date(value)?.getFullYear() + 1,
          new Date(value)?.getMonth(),
          new Date(value)?.getDate(),
        ).toString(),
      );
    },
    [expenseDAMasterStartDates],
  );
  const handleChangeDAMasterEndDates = useCallback(
    (event: any) => {
      setExpenseDAMasterEndDates(event);
    },
    [expenseDAMasterEndDates],
  );
  const handleChangeDAMasterStatus = useCallback(
    (event: any) => {
      setExpenseDAMasterStatus(event?.target?.checked);
    },
    [expenseDAMasterStatus],
  );
  const handleChangeDAMasterAmont = useCallback(
    (event: any) => {
      setExpenseDAMasterAmount(event?.target?.value.replace(/\D/g, ''));
    },
    [expenseDAMasterAmount],
  );

  const handleChangeHQTypeDAMaster = useCallback(
    function (_event: any, value: any) {
      setExpenseDAMasterHQType(value?.map((val: any) => val?.customCategoryId));
    },
    [expenseDAMasterHQType],
  );
  const triggeringButton = () => {
    OncloseDAMasterAdd();
    OncloseDivisionDAMasterAdd();
    OnOpeneDivisionDAMasterAdd();
    OnOpeneDesiDAMasterAdd();
    OncloseDesiDAMasterAdd();
    onResetClick();
    onSaveClick();
    closeDAMasterAdd('backdropClick');
  };

  const onSaveClick = useCallback(() => {
    const expenseDAMaster = {
      divisionIds: setvalues(expenseDAMasterDivision, 'value'),
      designationIds: setvalues(expenseDAMasterDesignation, 'designationId'),
      hqIds: setvalues(expenseDAMasterHQ, 'headQuaterId'),
      hqType: expenseDAMasterHQType,
      amount: expenseDAMasterAmount,
      startDate: expenseDAMasterStartDates,
    };
    dispatch(AddExpenseValidationDAMasterCreator(expenseDAMaster));
  }, [
    expenseDAMasterDivision,
    expenseDAMasterDesignation,
    expenseDAMasterHQ,
    expenseDAMasterHQType,
    expenseDAMasterAmount,
    expenseDAMasterStartDates,
    expenseDAMasterEndDates,
    expenseDAMasterStatus,
  ]);
  const closeDAMasterAdd = useCallback((reason: string) => {
    if (reason === 'backdropClick') return;
    dispatch(
      expenseDAMasterStateActions.setaddExpenseDAMasterPopupVisible(false),
    );
    onResetClick();
  }, []);
  const setvalues = (value: any, id: any) =>
    value?.map((val: any) => val?.['' + id + '']);
  const OncloseDAMasterAdd = useCallback(() => {
    dispatch(
      expenseDAMasterStateActions.setaddExpenseDAMasterPopupVisible(false),
    );
    onResetClick();
  }, []);
  const OncloseDivisionDAMasterAdd = useCallback(() => {
    setOpen(false);
  }, []);
  const OnOpeneDivisionDAMasterAdd = useCallback(() => setOpen(true), []);
  const OncloseDesiDAMasterAdd = useCallback(() => {
    setOpenDesign(false);
  }, []);
  const OnOpeneDesiDAMasterAdd = useCallback(() => setOpenDesign(true), []);
  const preventDefaultHandleDAMasterAdd = useCallback(
    e => e.preventDefault(),
    [],
  );
  const renderInputDAMasterAdd = useCallback(function (params: any) {
    return (
      <TextField
        size="small"
        {...params}
        sx={DatePickerStyle.datepick}
        helperText={null}
        onKeyDown={preventDefaultHandleDAMasterAdd}
        style={{maxwidth: 270, marginLeft: 5}}
      />
    );
  }, []);
  const renderInputdivDA = useCallback(function (params1: any) {
    return (
      <TextField
        {...params1}
        size="small"
        placeholder={strings.placeholder.division}
      />
    );
  }, []);
  const renderInputdesDA = useCallback(function (params1: any) {
    return (
      <TextField
        {...params1}
        size="small"
        placeholder={strings.placeholder.designation}
      />
    );
  }, []);
  const renderInputhqDA = useCallback(function (params1: any) {
    return (
      <TextField
        {...params1}
        size="small"
        placeholder={strings.placeholder.hq}
      />
    );
  }, []);
  const renderInputhqtypeDA = useCallback(function (params1: any) {
    return (
      <TextField
        {...params1}
        size="small"
        placeholder={strings.placeholder.hqType}
      />
    );
  }, []);
  useEffect(() => {
    validationDAMaster();
  }, [
    expenseDAMasterDivision,
    expenseDAMasterDesignation,
    expenseDAMasterHQ,
    expenseDAMasterHQType,
    expenseDAMasterAmount,
    expenseDAMasterStartDates,
  ]);
  useEffect(() => {
    if (expenseDAMasterDivision !== undefined) {
      setExpenseDAMasterDesignation([]);
      dispatch(
        fetchExpenseDAMasterDesignationByDivisionCreator(
          muiltipleSelect(setvalues(expenseDAMasterDivision, 'value')),
        ),
      );
    }
  }, [expenseDAMasterDivision]);

  useEffect(() => {
    let ids = {
      divisionIds: setvalues(expenseDAMasterDivision, 'value'),
      designationIds: setvalues(expenseDAMasterDesignation, 'designationId'),
    };
    if (ids?.divisionIds?.length !== 0 && ids?.designationIds?.length !== 0) {
      setExpenseDAMasterHQ([]);
      dispatch(fetchHqByEdividionDesginationDAMasterCreator(ids));
    }
  }, [expenseDAMasterDivision, expenseDAMasterDesignation]);
  return (
    <div>
      <button
        data-testid="triggeringButton"
        className={'hiddenButton'}
        onClick={triggeringButton}
      />
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={addExpenseDAMasterPopupVisible}
        onClose={closeDAMasterAdd}>
        <DialogTitle>
          <Box display={'flex'} justifyContent="space-between">
            <Box>
              <Typography sx={popUpStyle.addcss}>Add</Typography>
              <Typography sx={popUpStyle.headingcss}>
                Expense DA Master
              </Typography>
            </Box>
            <Box>
              <Button
                data-testid="button-setEditExpenseDAMasterPopupVisible"
                onClick={OncloseDAMasterAdd}>
                <ClearOutlinedIcon sx={popUpStyle.closeIcon} />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <div style={popUpStyle.dialogContent}>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {strings.division}
              </Typography>

              <Autocomplete
                multiple
                key={reset}
                id="checkboxes-tags-demo"
                options={divisionsDropDown}
                limitTags={2}
                disableCloseOnSelect
                size="small"
                open={open}
                value={expenseDAMasterDivision}
                onOpen={OnOpeneDivisionDAMasterAdd}
                onClose={OncloseDivisionDAMasterAdd}
                getOptionLabel={(option: any) => option?.label}
                onChange={handleChangeDivisionDAMaster}
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option?.label}
                  </li>
                )}
                renderInput={renderInputdivDA}
              />
            </FormControl>

            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {strings.designation}
              </Typography>
              <Autocomplete
                multiple
                key={reset}
                id="checkboxes-tags-demo"
                options={designationsDropdown}
                limitTags={2}
                disableCloseOnSelect
                value={expenseDAMasterDesignation}
                size="small"
                open={openDesign}
                onOpen={OnOpeneDesiDAMasterAdd}
                onClose={OncloseDesiDAMasterAdd}
                getOptionLabel={(option: any) => option?.designationName}
                onChange={handleChangeDesignationDAMaster}
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option?.designationName}
                  </li>
                )}
                renderInput={renderInputdesDA}
              />
            </FormControl>
          </div>
          <div style={popUpStyle.twocolumnlayout}>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>{strings.hq}</Typography>
              <Autocomplete
                multiple
                key={reset}
                id="checkboxes-tags-demo"
                options={HQsDropdown}
                limitTags={1}
                disableCloseOnSelect
                size="small"
                value={expenseDAMasterHQ}
                getOptionLabel={(option: any) => option?.headQuaterName}
                onChange={handleChangeHQDAMaster}
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option?.headQuaterName}
                  </li>
                )}
                renderInput={renderInputhqDA}
              />
            </FormControl>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {strings.amount}
              </Typography>

              <TextField
                InputProps={{
                  inputProps: {
                    inputMode: 'numeric',
                    pattern: '[0-9]+$',
                    'data-testid': 'text-handleChangeEditDAMasterAmont',
                  },
                }}
                value={expenseDAMasterAmount}
                onChange={handleChangeDAMasterAmont}
                sx={DropdownStyle.textroot}
                id="fullWidth"
                size="small"
              />
            </FormControl>
          </div>
          <div>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {strings.hqType}
              </Typography>
              <Autocomplete
                multiple
                key={reset}
                id="checkboxes-tags-demo"
                options={HQTypeDropdown}
                limitTags={1}
                disableCloseOnSelect
                size="small"
                getOptionLabel={(option: any) => option?.displayName}
                onChange={handleChangeHQTypeDAMaster}
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option?.displayName}
                  </li>
                )}
                renderInput={renderInputhqtypeDA}
              />
            </FormControl>
          </div>
          <div>
            <div style={popUpStyle.twocolumnlayout}>
              <FormControl fullWidth sx={popUpStyle.marginBottom15}>
                <Typography sx={popUpStyle.labelSizing}>
                  {strings.effectiveForm}
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    mask="__/__/____"
                    inputFormat="DD/MM/YYYY"
                    minDate={new Date(new Date().setDate(1))}
                    value={expenseDAMasterStartDates}
                    maxDate={expenseDAMasterEndDates}
                    onChange={handleChangeDAMasterStartDates}
                    label={strings.label.startDate}
                    PopperProps={DialogProps}
                    renderInput={renderInputDAMasterAdd}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl
                fullWidth
                sx={[popUpStyle.marginBottom15, {paddingLeft: '5px'}]}>
                <Typography sx={popUpStyle.labelSizing}>
                  {strings.validUpto}
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    mask="__/__/____"
                    inputFormat="DD/MM/YYYY"
                    disabled={true}
                    maxDate={new Date(expenseDAMasterMaxDate)}
                    minDate={expenseDAMasterStartDates}
                    value={expenseDAMasterEndDates}
                    onChange={handleChangeDAMasterEndDates}
                    label={strings.label.endDate}
                    PopperProps={DialogProps}
                    renderInput={renderInputDAMasterAdd}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            {!expenseDAMasterStatus && (
              <FormGroup>
                <Typography sx={popUpStyle.labelSizing}>
                  {strings.status}
                </Typography>

                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography sx={popUpStyle.labelSizingAntSwitch}>
                    {strings.inActive}
                  </Typography>
                  <AntSwitch
                    checked={expenseDAMasterStatus}
                    inputProps={{'aria-label': 'ant design'}}
                    onChange={handleChangeDAMasterStatus}
                  />
                  <Typography sx={popUpStyle.labelSizingAntSwitch}>
                    {strings.active}
                  </Typography>
                </Stack>
              </FormGroup>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Box display={'flex'} justifyContent="center" width={'100%'}>
            <Button
              data-testid="button-resetDAMaster"
              variant="contained"
              sx={popUpStyle.resetButton}
              onClick={onResetClick}>
              {strings.reset}
            </Button>

            <Button
              data-testid="button-onSaveClick"
              variant="contained"
              sx={popUpStyle.saveButton}
              disabled={expenseDAMasterValidation}
              onClick={onSaveClick}>
              {strings.save}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};
