import { API_PATH } from "@app/common/constants";
import { NetworkService, ToasterService } from "@app/services";
import { ToastType } from "@app/services/toaster";
import { takeLatest, put,call, select } from "redux-saga/effects";
import { fetchGenerateReportCreatorTypeName, generateReportStateActions } from "./slice";
import { filterSelector } from '@app/components/right-sidebar/redux/filterHandlerSelector';
import { filterStateActions } from '@app/components/right-sidebar/redux/filterHandler';
import { appSliceActions } from "@app/store/reducers/appSlice";
import moment from "moment";

/**
 * Employee Details Watcher
 */
 export function* fetchGenerateReportDataWatcher() {
    yield takeLatest(
        fetchGenerateReportCreatorTypeName,
      fetchGenerateReportDataWatcherWorker,
    );
  }
  /**
   * Employee Details Worker
   */
  export function* fetchGenerateReportDataWatcherWorker(): any {
    try {
      yield put(generateReportStateActions.setGenerateReportLoading(true));
      const url = `${API_PATH.generateReport.getGenerateReport}`;
      const generateReportData = yield call(NetworkService.get, url, {}, {});
      yield put(
          generateReportStateActions.setGenerateReport(generateReportData?.data),
      );
      yield put(
        generateReportStateActions.setGenerateReportSnaShot(generateReportData?.data),
      );
      const filters = yield select(
        filterSelector.getFilterState()
      );
      const designations = [...new Set(generateReportData?.data?.map((g: any) => g.staffDesignationName))].map((g: any) => ([g, false ]))
      const divisions = [...new Set(generateReportData?.data?.map((g: any) => g.divisionName))].map((g: any) => ([g, false ]))
      const hqs = [...new Set(generateReportData?.data?.map((g: any) => g.hqName))].map((g: any) => ([g, false ]))
      yield put(
        filterStateActions.setFilterState({
          ...filters,
          HQ: {
            ...filters['HQ'],
            completeApplied: false,
            collapsed: true,
            options: Object.fromEntries(hqs),
          },
          Division: {
            ...filters['Division'],
            completeApplied: false,
            collapsed: true,
            options: Object.fromEntries(divisions),
          },
          Designation: {
            ...filters['Designation'],
            completeApplied: false,
            collapsed: true,
            options: Object.fromEntries(designations),
          },
        }),
      );
      yield put(generateReportStateActions.setGenerateReportLoading(false));
    } catch (error) {
      ToasterService.showToaster(
        'Something went wrong while fetching generate ReportData!',
        ToastType.ERROR,
      );
    }
  }