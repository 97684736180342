import theme from "@app/themes";
import { color } from "@mui/system";

export const manageUserRoleStyles = {
  wrapper:{
    maxHeight: 'calc(100vh - 155px)',
    overflowY:'scroll'
  },
  productListingDropDown: {
    minWidth: '260px',
    fontSize:"12.7px",
    color:theme.colors.grey[200]
  },
  font14: {
    fontSize: '14px',
  },
  dropdownBgWhite: {
    backgroundColor: '#fff',
  },
  tableTitleText: {
    color:'#322b7c',
    fontWeight:'600',
    fontFamily:'Poppins',
    fontSize:'15.7px'
  },
  header: {
    border: 'none',
    fontWeight: 'bold',
    fontSize:12.7,
    color:theme.colors.grey[200]
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1,
  },
  toggleTitle: {
    color: '#322b7c',
    fontWeight: '700',
    fontFamily: 'Poppins',
    fontSize: '12.7px',
  },
  disabledToggle: {
    opacity: 0.5,
  },
  actionTableCell:{
    borderBottom: 'none !important'
  },
  goButton: {
    backgroundColor: '#322b7c',
    borderRadius: '5px',
    width: 'auto',
    color: '#fff',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12.7,
    marginTop: '0px',
    minWidth: '40px',
    height: '35px',
    marginLeft: '2px',
    '&:hover': {
      background: '#322b7c',
    },
    textTransform:'none'
  },
};
