import {createSlice} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialStateValue = {
  orderReviewPopup: false,
  navbarShow: true,
  currentOrderId: 0,
  stateSelect: null,
  stateHqMulti: [],
  stateMrMulti: [],
  CloseOrderPopup: false,
  requestPayloadOrder: {
    division: [],
    currentStaffPositionId: 0,
    pageSize: 10,
    pageNumber: 0,
    formNo: '',
    mrStaffIds: [],
    state: '',
    hq: [],
    startDate: '',
    endDate: '',
    tabType: 1,
    type: 1,
  },
  summaryPayload: {
    currentStaffPositionId: 0,
    pageSize: 10,
    pageNumber: 0,
    customer: [],
    mrStaffIds: [],
    state: '',
    division: [],
    hq: [],
    fromDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    toDate: dayjs().format('YYYY-MM-DD'),
  },
};

export const HoOrderState = createSlice({
  name: 'hoOrderStore',
  initialState: {value: initialStateValue},
  reducers: {
    setStateSelect: (state, action) => {
      state.value.stateSelect = action.payload;
    },
    setHqMulti: (state, action) => {
      state.value.stateHqMulti = action.payload;
    },
    setMrMulti: (state, action) => {
      state.value.stateMrMulti = action.payload;
    },
    setOrderReviewPopup: state => {
      state.value.orderReviewPopup = !state.value.orderReviewPopup;
    },

    setCurrentOrderId: (state, action) => {
      state.value.currentOrderId = action.payload;
    },

    setCloseOrderPopup: (state, action) => {
      if (action.payload === false) {
        state.value.CloseOrderPopup = false;
      } else {
        state.value.CloseOrderPopup = !state.value.CloseOrderPopup;
      }
    },

    updateRequestPayloadOrder: (state, action) => {
      state.value.requestPayloadOrder = {
        ...state.value.requestPayloadOrder,
        ...action.payload,
      };
    },
    clearRequestPayloadOrder: state => {
      const {pageSize, pageNumber} = state.value.requestPayloadOrder;
      state.value.requestPayloadOrder = {
        ...state.value.requestPayloadOrder,
        currentStaffPositionId: 0,
        pageSize: pageSize, // retain the current pageSize
        pageNumber: pageNumber, // retain the current pageNumber
        formNo: '',
        mrStaffIds: [],
        state: '',
        hq: [],
        startDate: '',
        endDate: '',
        division: [],
        tabType: 1,
        type: 1,
      };
    },
    setNavbarShow: (state, action) => {
      state.value.navbarShow = action.payload;
    },
    updateSummaryPayload: (state, action) => {
      state.value.summaryPayload = {
        ...state.value.summaryPayload,
        ...action.payload,
      };
    },
    clearSummaryPayload: state => {
      state.value.summaryPayload = {
        ...state.value.summaryPayload,
        currentStaffPositionId: 0,
        pageSize: 10,
        pageNumber: 0,
        customer: [],
        mrStaffIds: [],
        state: '',
        division: [],
        hq: [],
        fromDate: dayjs().startOf('month').format('YYYY-MM-DD'),
        toDate: dayjs().format('YYYY-MM-DD'),
      };
    },
  },
});

export const {
  updateRequestPayloadOrder,
  clearRequestPayloadOrder,
  setStateSelect,
  setHqMulti,
  setMrMulti,
  setNavbarShow,
  setOrderReviewPopup,
  setCurrentOrderId,
  updateSummaryPayload,
  clearSummaryPayload,
} = HoOrderState.actions;

export const hoOrderReducer = HoOrderState.reducer;
