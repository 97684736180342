import {Box} from '@mui/system';
import {updateTotalTradeDiscountStyles as styles} from '../styles';
import {Button, CircularProgress, Typography} from '@mui/material';
import {
  CloudUploadOutlined,
  InsertDriveFileOutlined,
  TaskAltOutlined,
} from '@mui/icons-material';
import {useCallback, useEffect, useState, DragEvent} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {
  fetchProductForDiscountDataCreator,
  fetchUploadProductExcelFileDataCreator,
} from '../../redux-api/slice';
import { ToasterService } from '@app/services';
import { strings } from '@app/common/strings';

interface UploadFileProps {
  handleToggleModal: () => void;
  userLoginInfo: any;
  divisionId: number;
}

export const UploadFile: React.FC<UploadFileProps> = ({
  handleToggleModal,
  userLoginInfo,
  divisionId,
}) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadDate, setUploadDate] = useState<String | null>(null);
  const [isDispatched, setIsDispatched] = useState(false);
  const uploadProductExcelFileCompleted = useSelector(
    (state: RootStateOrAny) =>
      state.fcDashBoard.uploadProductExcelFileCompleted,
  );

  const validateFileType = (file: File): boolean => {
    const allowedTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
    ];
    return allowedTypes.includes(file.type);
  };

  const handleDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event?.dataTransfer?.files[0];
    if (droppedFile) {
      if (validateFileType(droppedFile)) {
        handleFileChange(droppedFile);
      }else{
        ToasterService.hideToaster()
        ToasterService.showToaster("Invalid File Type", "error")
      }
    }
  }, []);

  const handleFileChange = (file: File) => {
    setSelectedFile(file);

    const date = new Date();
    const padTo2Digits = (num: number) => num.toString().padStart(2, '0');

    const day = padTo2Digits(date.getDate());
    const month = padTo2Digits(date.getMonth() + 1);
    const year = date.getFullYear().toString().slice(-2);

    const hours = date.getHours();
    const minutes = padTo2Digits(date.getMinutes());
    const period = hours >= 12 ? 'PM' : 'AM';

    const formattedHours = padTo2Digits(hours % 12 || 12);
    const formattedDate = `${day}/${month}/${year}, ${formattedHours}:${minutes} ${period}`;
    setUploadDate(formattedDate);
  };

  const handleFileUpload = () => {
    if (selectedFile && !isDispatched) {
      const payload = {
        id: userLoginInfo?.id,
        file: selectedFile,
      };
      dispatch(fetchUploadProductExcelFileDataCreator(payload));
      setIsDispatched(true);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      handleToggleModal();
      dispatch(fetchProductForDiscountDataCreator(divisionId));
    }
  }, [uploadProductExcelFileCompleted]);

  return (
    <>
      <Typography sx={[styles.modalSection, styles.modalHeader]}>
        {strings.zho.fcAdmin.updateLimit.uploadTheFile}
      </Typography>
      <Box sx={styles.modalSection}>
          <Box sx={{position: 'relative'}}>
          {isDispatched &&
          <Box sx={styles.loader}>
            <CircularProgress />
          </Box>}
            <Box
            data-testid="file-dropzone"
              sx={[styles.dragFileBox, {visibility:isDispatched?'hidden':''}]}
              onDragOver={e => e.preventDefault()}
              onDrop={handleDrop}>
              <input
                type="file"
                accept=".xlsx,.csv"
                id="fileInput"
                style={{display: 'none'}}
                onChange={(e: any) => {
                  if (validateFileType(e.target.files[0])) {
                    handleFileChange(e.target.files[0]);
                  }else{
                    ToasterService.hideToaster()
                    ToasterService.showToaster("Invalid File Type", "error")
                  }
                }}
              />
              <CloudUploadOutlined />
              <Box sx={styles.dragFileText}>
                <Typography sx={styles.bulkUpdloadText} >
                {strings.zho.fcAdmin.updateLimit.dragFile}
                </Typography>
                <Typography
                  sx={styles.downloadTemplateText}
                  onClick={() => document.getElementById('fileInput')?.click()}>
                  {strings.zho.fcAdmin.updateLimit.chooseFile}
                </Typography>
              </Box>
            </Box>
            <Typography sx={[styles.supportedFormatText, {visibility:isDispatched?'hidden':''}]}>
            {strings.zho.fcAdmin.updateLimit.supportedFiles}
            </Typography>
          </Box>
        {selectedFile && (
          <Box sx={styles.fileDetailsWrapper}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <InsertDriveFileOutlined />
              <Box sx={styles.fileDetails}>
                <Typography sx={styles.fileName}>
                  {selectedFile?.name?.length <= 30
                    ? selectedFile?.name
                    : selectedFile?.name.slice(0, 30) + '...'}
                </Typography>
                <Typography sx={styles.fileSize}>
                  {(selectedFile?.size / (1024 * 1024)).toFixed(2)} MB
                </Typography>
              </Box>
            </Box>
            <TaskAltOutlined sx={{fill: '#18A685'}} />
          </Box>
        )}
        {selectedFile && (
          <Typography sx={styles.fileUploadDate}>
            {strings.zho.fcAdmin.updateLimit.uploadedOn} {uploadDate}
          </Typography>
        )}
      </Box>
      <Box sx={[styles.modalSection, styles.modalFooter]}>
        <Button data-testid="cancel-upload" sx={{fontSize:"12.7px",textTransform:'capitalize'}} variant="outlined" fullWidth onClick={handleToggleModal}>
        {strings.zho.cancel}
        </Button>
        <Button data-testid="confirm-upload" sx={{fontSize:"12.7px",textTransform:'capitalize'}} variant="contained" fullWidth onClick={handleFileUpload} disabled={isDispatched}>
        {strings.zho.upload}
        </Button>
      </Box>
    </>
  );
};
