import { createSelector } from "@reduxjs/toolkit";

export const generateReportStateSelector = {

    getGenerateReport: () => {
        return createSelector(
          [(state: any) => state.generateReport.generateReportData],
          (generateReportData) => generateReportData
        );
      },
      getGenerateReportLoading: () => {
        return createSelector(
          [(state: any) => state.generateReport.generateReportLoading],
          (generateReportLoading) => generateReportLoading
        );
      },
      getIsGenerateReport: () => {
        return createSelector(
          [(state: any) => state.generateReport.isGenerateReport],
          (isGenerateReport) => isGenerateReport
        );
      },
      getGenerateReportPageNo: () => {
        return createSelector(
          [(state: any) => state.generateReport.generateReportPageNo],
          (generateReportPageNo) => generateReportPageNo
        );
      },
      getGenerateReportSnaShot: () => {
        return createSelector(
          [(state: any) => state.generateReport.generateReportSnaShot],
          (generateReportSnaShot) => generateReportSnaShot
        );
      },
      getYearDropdown: () => {
        return createSelector(
          [(state: any) => state.generateReport.yearDropdown],
          (yearDropdown) => yearDropdown
        );
      },
      getMonthDropdown: () => {
        return createSelector(
          [(state: any) => state.generateReport.monthDropdown],
          (monthDropdown) => monthDropdown
        );
      },
      getSelectedGenerateReprot: () => {
        return createSelector(
          [(state: any) => state.generateReport.selectedGenerateReprotData],
          (selectedGenerateReprotData) => selectedGenerateReprotData
        );
      }
}