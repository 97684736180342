import { createAction, createSlice } from "@reduxjs/toolkit";
export const generateReportInitialState={
generateReportData:[],
generateReportLoading:false,
isGenerateReport:false,
generateReportSnaShot:[],
generateReportPageNo:0,
yearDropdown: [],
monthDropdown: [],
selectedGenerateReprotData: []
}
export const fetchGenerateReportCreator = createAction(
    'GENERATE_REPORT_HANDLER/FETCH_GENERATE_REPORT',
  );
  export const fetchGenerateReportCreatorTypeName = fetchGenerateReportCreator().type;
const generateReportStateHandler = createSlice({
    name: "GENERATE_REPORT_HANDLER",
    initialState: generateReportInitialState,
    reducers: {
        setGenerateReport: (prevState, action) => {
            const { payload } = action;
            return {
              ...prevState,
              generateReportData: payload,
            };
          },
          setGenerateReportPageNo: (prevState, action) => {
            const { payload } = action;
            return {
              ...prevState,
              generateReportPageNo: payload,
            };
          },
          setGenerateReportSnaShot: (prevState, action) => {
            const { payload } = action;
            return {
              ...prevState,
              generateReportSnaShot: payload,
            };
          },
          setIsGenerateReport: (prevState, action) => {
            const { payload } = action;
            return {
              ...prevState,
              isGenerateReport: payload,
            };
          },
          setGenerateReportLoading: (prevState, action) => {
            const { payload } = action;
            return {
              ...prevState,
              generateReportLoading: payload,
            };
          },
          setYearDropdown: (prevState, action) => {
            return {
              ...prevState,
              yearDropdown: action.payload
            }
          },
          setMonthDropdown: (prevState, action) => {
            return {
              ...prevState,
              monthDropdown: action.payload
            }
          },
          selectedGenerateReprot: (prevState, action) => {
            return {
              ...prevState,
              selectedGenerateReprotData: action.payload
            }
          },
    }
})

export const generateReportStateActions = generateReportStateHandler.actions;
export const generateReportStateReducer = generateReportStateHandler.reducer;