import {
  Box,
  Drawer,
  Typography,
  Stack,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  CircularProgress,
} from '@mui/material';
import {useStyles} from '@app/screens/zho/styles';
import NotificationModal from '@app/components/right-sidebar/NotificationModal';
import theme from '@app/themes';
import {RootStateOrAny, useSelector} from 'react-redux';
import dateUtils from '@app/screens/zho/utils/dateFormat';
import {strings} from '@app/common/strings';

const drawerWidth = 270;

const styles = {
  backgroundWhite: {
    backgroundColor: '#fff',
  },
  drawer: {
    minWidth: drawerWidth,
    width: 'auto',
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
      border: 0,
    },
  },
  peroTableHeading: {
    color: theme.colors.primary,
    fontWeight: '600',
  },
  paperProps: {
    backgroundColor: '#f5f8f9',
  },
};

const RightSidebarView = () => {
  const PerformanceData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.hoPerformanceData,
  );

  const PerformanceLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.hoPerformanceLoading,
  );

  const classes = useStyles();
  const {DateFormat} = dateUtils;

  return (
    <Drawer
      PaperProps={{sx: styles.paperProps}}
      sx={styles.drawer}
      variant="permanent"
      anchor="right">
      <NotificationModal />
      <Box
        margin="20px"
        borderRadius="20px"
        sx={styles.backgroundWhite}
        className="hide-scrollbar"
        height="100vh"
        overflow="auto">
        {!PerformanceLoading ? (
          <Box padding="15px">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                {strings.zho.hoAdmin.performanceBar.performance}
              </Typography>
              <Typography fontSize="11.7px" variant="subtitle1" gutterBottom>
                {DateFormat(new Date())}
              </Typography>
            </Stack>

            <Box>
              <Table
                sx={{
                  '& .MuiTableCell-root': {
                    borderBottom: 'none ',
                  },
                }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{...styles.peroTableHeading, paddingLeft: '0px'}}>
                      {strings.zho.hoAdmin.performanceBar.ho}
                    </TableCell>
                    <TableCell
                      sx={{...styles.peroTableHeading, paddingRight: '0px'}}>
                      {strings.zho.hoAdmin.dashboard.order}
                    </TableCell>
                    <TableCell
                      sx={{...styles.peroTableHeading, paddingRight: '0px'}}>
                      {strings.zho.hoAdmin.performanceBar.pod}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{overflowY: 'auto'}}>
                  {PerformanceData?.map((data: any, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell sx={{paddingLeft: '0px'}}>
                          {data?.userName}
                        </TableCell>
                        <TableCell sx={{paddingRight: '0px'}}>
                          {data?.orderCount}
                        </TableCell>
                        <TableCell sx={{paddingRight: '0px'}}>
                          {data?.podCount}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* <TableRow>
                    <TableCell sx={{paddingLeft: '0px'}}>
                      PHARMACY WAIKHOM BASANTA SINGH
                    </TableCell>
                    <TableCell sx={{paddingRight: '0px'}}>12</TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </Box>
          </Box>
        ) : (
          <Box
            className={classes.loader}
            sx={{display: 'flex', justifyContent: 'center', mt: 3}}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default RightSidebarView;
