import {API_PATH, HTTP_OK} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {call, put, takeEvery} from 'redux-saga/effects';
import {
  hoDashboardActions,
  fetchStateDataTypeName,
  fetchHqDataTypeName,
  fetchOrderFormDataTypeName,
  fetchMrDataTypeName,
  fetchOrderInnerFormDataTypeName,
  fetchOrderFormProductDataTypeName,
  fetchOrderFormApprovalDataTypeName,
  fetchOrderProductApprovalDataTypeName,
  fetchOrderProductRejectDataTypeName,
  fetchOrderSyncDataTypeName,
  fetchRejectOptionsDataTypeName,
  fetchErrorInfoMsgDataTypeName,
  fetchInvoiceFormDataTypeName,
  fetchInvoiceProductApproveTypeName,
  fetchInvoiceProductRejectTypeName,
  fetchRejectInvoiceOptionsDataTypeName,
  fetchPodForOrderIdTypeName,
  fetchDownloadPodFileTypeName,
  fetchEditPodForOrderTypeName,
  fetchUploadPodDocumentTypeName,
  fetchOrderFormProductDataCreator,
  fetchPerformanceTypeName,
  fetchOrderFormDataCreator,
  fetchHosHeaderCountTypeName,
  fetchCustomerDataTypeName,
  fetchSummaryTotalDataTypeName,
  fetchSummaryTableDataTypeName,
  fetchPerformanceCreator,
} from './slice';
import {redirectToLogin} from '@app/screens/zho/utils/handleAuth';
import {TabNames} from '../../fc-admin/redux/tabName';
import {setOrderReviewPopup} from '../redux/orderStore';
// State Dropdown watcher
export function* fetchHoStateDataWatcher() {
  yield takeEvery(fetchStateDataTypeName, fetchHoStateDataWorker);
}

// State Dropdown worker
export function* fetchHoStateDataWorker(actions: any): any {
  let url = '';
  const payObj = actions.payload;
  if (payObj.type === 'ho') {
    url = `${API_PATH.zho.hoDashboard.orderDashboard.dropDowns.state}${payObj.currentStaffPositionId}`;
  } else if (payObj.type === 'summary') {
    url = `${API_PATH.zho.summary.filter.State}${payObj.currentStaffPositionId}`;
  } else {
    url = `${API_PATH.zho.fcDashboard.dropdowns.stateOrders}${payObj.currentStaffPositionId}`;
  }
  try {
    yield put(hoDashboardActions.setStateLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setStateError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(hoDashboardActions.setStateData(response?.data?.response));
      }
    } else {
      yield put(hoDashboardActions.setStateError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setStateError());
  }
}

// Hq Dropdown watcher
export function* fetchHoHqDataWatcher() {
  yield takeEvery(fetchHqDataTypeName, fetchHoHqDataWorker);
}

// Hq Dropdown worker
export function* fetchHoHqDataWorker(actions: any): any {
  let url = '';
  const payObj = actions.payload;
  if (payObj.type === 'ho') {
    url = `${API_PATH.zho.hoDashboard.orderDashboard.dropDowns.hq}${payObj.currentStateId}`;
  } else if (payObj.type === 'summary') {
    url = `${API_PATH.zho.summary.filter.hq}${payObj.currentStateId}`;
  } else {
    url = `${API_PATH.zho.fcDashboard.dropdowns.hqOrders}${payObj.currentStateId}`;
  }
  try {
    yield put(hoDashboardActions.setHqLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setHqError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(hoDashboardActions.setHqData(response?.data?.response));
      }
    } else {
      yield put(hoDashboardActions.setHqError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setHqError());
  }
}

// Mr Dropdown watcher
export function* fetchHoMrDataWatcher() {
  yield takeEvery(fetchMrDataTypeName, fetchHoMrDataWorker);
}

// Mr Dropdown worker
export function* fetchHoMrDataWorker(actions: any): any {
  let url = '';
  const payObj = actions.payload;
  if (payObj.type === 'ho') {
    url = `${API_PATH.zho.hoDashboard.orderDashboard.dropDowns.mr}`;
  } else if (payObj.type === 'summary') {
    url = `${API_PATH.zho.summary.filter.mr}`;
  } else {
    url = `${API_PATH.zho.fcDashboard.dropdowns.mrOrders}`;
  }
  try {
    yield put(hoDashboardActions.setMrLoading());
    const response = yield call(NetworkService.post, url, payObj.hqIds, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setMrError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(hoDashboardActions.setMrData(response?.data?.response));
      }
    } else {
      yield put(hoDashboardActions.setMrError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setMrError());
  }
}

// orderForm table watcher
export function* fetchHoOrderFormDataWatcher() {
  yield takeEvery(fetchOrderFormDataTypeName, fetchHoOrderFormDataWorker);
}

// orderForm table worker
export function* fetchHoOrderFormDataWorker(actions: any): any {
  let url = '';
  const payObj = actions.payload;
  if (payObj.type === 'ho') {
    url = `${API_PATH.zho.hoDashboard.orderDashboard.orderDashboardTable}${payObj.statusToggleNo}`;
  } else {
    url = `${API_PATH.zho.fcDashboard.orderForm.tableData}`;
  }
  try {
    yield put(hoDashboardActions.setOrderFormLoading());
    const response = yield call(
      NetworkService.post,
      url,
      payObj.currentRequestStatus,
      {},
    );
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setOrderFormError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        if (payObj.type === 'ho') {
          yield put(
            TabNames.actions.updateHotabs({
              index: 0,
              value: `Order(${response?.data?.response?.totalCount || 0})`,
            }),
          );
        } else {
          yield put(
            TabNames.actions.updateAdminTabs({
              index: 1,
              value: `Order(${response?.data?.response?.totalCount || 0})`,
            }),
          );
        }

        yield put(
          hoDashboardActions.setOrderFormData(response?.data?.response),
        );
      }
    } else {
      yield put(hoDashboardActions.setOrderFormError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderFormError());
  }
}

// orderForm innertable Dropdown watcher
export function* fetchHoOrderInnerFormDataWatcher() {
  yield takeEvery(
    fetchOrderInnerFormDataTypeName,
    fetchHoOrderInnerFormDataWorker,
  );
}

// orderForm innertable Dropdown worker
export function* fetchHoOrderInnerFormDataWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.orderInnerTableData}${payObj.currentOrderId}`;
  try {
    yield put(hoDashboardActions.setOrderInnerFormLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setOrderInnerFormError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setOrderInnerFormData(response?.data?.response),
        );
      }
    } else {
      yield put(hoDashboardActions.setOrderInnerFormError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderInnerFormError());
  }
}

// orderFormPopup product Details watcher
export function* fetchHoOrderFormProductDataWatcher() {
  yield takeEvery(
    fetchOrderFormProductDataTypeName,
    fetchHoOrderFormProductDataWorker,
  );
}

// orderFormPopup product Details worker
export function* fetchHoOrderFormProductDataWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.orderPopup.productDetails}${payObj.currentOrderId}`;
  try {
    yield put(hoDashboardActions.setOrderFormProductLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setOrderFormProductError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setOrderFormProductData(response?.data?.response),
        );
      }
    } else {
      yield put(hoDashboardActions.setOrderFormProductError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderFormProductError());
  }
}

// orderFormPopup approval Details watcher
export function* fetchHoOrderFormApprovalDataWatcher() {
  yield takeEvery(
    fetchOrderFormApprovalDataTypeName,
    fetchHoOrderFormApprovalDataWorker,
  );
}

// orderFormPopup approval Details worker
export function* fetchHoOrderFormApprovalDataWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.orderPopup.approvalStatus}${payObj.currentOrderId}`;
  try {
    yield put(hoDashboardActions.setOrderFormApprovalLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setOrderFormApprovalError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setOrderFormApprovalData(response?.data?.response),
        );
      }
    } else {
      yield put(hoDashboardActions.setOrderFormApprovalError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderFormApprovalError());
  }
}

// order product approval  watcher
export function* fetchHoOrderProductApprovalDataWatcher() {
  yield takeEvery(
    fetchOrderProductApprovalDataTypeName,
    fetchHoOrderProductApprovalDataWorker,
  );
}

// order product approval Details worker
export function* fetchHoOrderProductApprovalDataWorker(actions: any): any {
  let url = '';
  const payObj = actions.payload;
  if (payObj.type === 'ho') {
    url = `${API_PATH.zho.hoDashboard.orderDashboard.approveOrder}${payObj.staffPositionId}&currentUserId=${payObj.UserId}`;
  } else {
    url = `${API_PATH.zho.fcDashboard.orderForm.approveFcOrder}${payObj.staffPositionId}&currentUserId=${payObj.UserId}`;
  }
  try {
    yield put(hoDashboardActions.setOrderProductApprovalLoading());
    yield put(hoDashboardActions.setOrderSyncLoading());
    const response = yield call(
      NetworkService.post,
      url,
      payObj.currentOrderIds,
      {},
    );
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setOrderProductApprovalError());
        yield put(setOrderReviewPopup());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        ToasterService.hideToaster();
        ToasterService.showToaster(response?.data?.response, ToastType.SUCCESS);
        yield put(hoDashboardActions.setOrderProductApprovalLoadingComplete());
        yield put(
          hoDashboardActions.setOrderSyncCompleted(response?.data?.response),
        );
        yield put(hoDashboardActions.setOrderProductApprovalComplete(null));

        if (payObj.type === 'ho') {
          yield put(fetchPerformanceCreator());
        }
      }
    } else {
      yield put(hoDashboardActions.setOrderProductApprovalError());
      yield put(setOrderReviewPopup());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    yield put(hoDashboardActions.setOrderProductApprovalError());
    yield put(setOrderReviewPopup());
    redirectToLogin();
    yield put(hoDashboardActions.setOrderProductApprovalError());
  }
}

// order reject  watcher
export function* fetchHoOrderProductRejectDataWatcher() {
  yield takeEvery(
    fetchOrderProductRejectDataTypeName,
    fetchHoOrderProductRejectDataWorker,
  );
}

// order Reject worker
export function* fetchHoOrderProductRejectDataWorker(actions: any): any {
  let url = '';
  const payObj = actions.payload;
  if (payObj.type === 'ho') {
    url = `${API_PATH.zho.hoDashboard.orderDashboard.orderPopup.rejectOrder}${payObj.currentOrderId}&RejectionRemarks=${payObj.rejectionRemarks}&staffPosId=${payObj.staffPositionId}&currentUser=${payObj.UserId}`;
  } else {
    url = `${API_PATH.zho.fcDashboard.orderForm.rejectFcOrder}${payObj.currentOrderId}&RejectionRemarks=${payObj.rejectionRemarks}&staffPosId=${payObj.staffPositionId}&currentUser=${payObj.UserId}`;
  }
  try {
    yield put(hoDashboardActions.setOrderProductRejectLoading());
    const response = yield call(NetworkService.post, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setOrderProductRejectError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        ToasterService.hideToaster();
        ToasterService.showToaster(response?.data?.response, ToastType.SUCCESS);
        if (payObj.type === 'ho') {
          yield put(fetchPerformanceCreator());
        }
        yield put(hoDashboardActions.setOrderProductApprovalComplete(null));
      }
    } else {
      yield put(hoDashboardActions.setOrderProductRejectError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderProductRejectError());
  }
}

// order Sync watcher
export function* fetchHoOrderSyncDataWatcher() {
  yield takeEvery(fetchOrderSyncDataTypeName, fetchHoOrderSyncDataWorker);
}

// order sync worker
export function* fetchHoOrderSyncDataWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.syncOrder}`;
  try {
    yield put(hoDashboardActions.setOrderSyncLoading());
    const response = yield call(
      NetworkService.post,
      url,
      payObj.currentOrderId,
      {},
    );
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setOrderSyncError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
        yield put(setOrderReviewPopup());
        yield put(hoDashboardActions.setOrderProductApprovalError());
      } else {
        ToasterService.hideToaster();
        ToasterService.showToaster(response.data.response, ToastType.SUCCESS);
        yield put(
          fetchOrderFormDataCreator(
            payObj.currentRequestStatus,
            payObj.statusToggleNo,
            payObj.type,
          ),
        );
        yield put(
          hoDashboardActions.setOrderSyncCompleted(response?.data?.response),
        );
      }
    } else {
      yield put(hoDashboardActions.setOrderSyncError());
      yield put(setOrderReviewPopup());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    yield put(hoDashboardActions.setOrderProductApprovalError());
    yield put(setOrderReviewPopup());
    redirectToLogin();
    yield put(hoDashboardActions.setOrderSyncError());
  }
}

// order reject options watcher
export function* fetchHoOrderRejectOptionsWatcher() {
  yield takeEvery(
    fetchRejectOptionsDataTypeName,
    fetchHoOrderRejectOptionsWorker,
  );
}

// order Reject options worker
export function* fetchHoOrderRejectOptionsWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.orderRejectOptions}`;
  try {
    yield put(hoDashboardActions.setOrderRejectOptionsLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setOrderRejectOptionsError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setOrderRejectOptionsData(
            response?.data?.response,
          ),
        );
      }
    } else {
      yield put(hoDashboardActions.setOrderRejectOptionsError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderRejectOptionsError());
  }
}

// order tooltip error watcher
export function* fetchHoOrderErrorInfoMsgWatcher() {
  yield takeEvery(
    fetchErrorInfoMsgDataTypeName,
    fetchHoOrderErrorInfoMsgWorker,
  );
}

// order tooltip error worker
export function* fetchHoOrderErrorInfoMsgWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.orderDashboard.errorInfoMsg}${payObj.currentOrderId}`;
  try {
    yield put(hoDashboardActions.setOrderErrorInfoMsgLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setOrderErrorInfoMsgError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setOrderErrorInfoMsgData(response?.data?.response),
        );
      }
    } else {
      yield put(hoDashboardActions.setOrderErrorInfoMsgError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderErrorInfoMsgError());
  }
}

// InvoiceForm table watcher
export function* fetchHoInvoiceFormDataWatcher() {
  yield takeEvery(fetchInvoiceFormDataTypeName, fetchHoInvoiceFormDataWorker);
}

// InvoiceForm table worker
export function* fetchHoInvoiceFormDataWorker(actions: any): any {
  const invoiceformTableObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.invoiceDashboard.invoiceDashboardTable}${invoiceformTableObj.statusToggleNo}`;
  try {
    yield put(hoDashboardActions.setOrderFormLoading());
    const response = yield call(
      NetworkService.post,
      url,
      invoiceformTableObj.currentRequestStatus,
      {},
    );
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setOrderFormError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          TabNames.actions.updateHotabs({
            index: 1,
            value: `Invoicing(${response?.data?.response?.totalCount || 0})`,
          }),
        );
        yield put(
          hoDashboardActions.setOrderFormData(response?.data?.response),
        );
      }
    } else {
      yield put(hoDashboardActions.setOrderFormError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderFormError());
  }
}

// Invoice product approval  watcher
export function* fetchHoInvoiceProductApprovalDataWatcher() {
  yield takeEvery(
    fetchInvoiceProductApproveTypeName,
    fetchHoInvoiceProductApprovalDataWorker,
  );
}

// Invoice product approval Details worker
export function* fetchHoInvoiceProductApprovalDataWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.invoiceDashboard.approvePod}${payObj.staffPositionId}&currentUser=${payObj.UserId}`;
  try {
    yield put(hoDashboardActions.setOrderProductApprovalLoading());
    const response = yield call(
      NetworkService.post,
      url,
      payObj.currentOrderIds,
      {},
    );
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setOrderProductApprovalError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        ToasterService.hideToaster();
        ToasterService.showToaster(response?.data?.response, ToastType.SUCCESS);
        yield put(hoDashboardActions.setOrderProductApprovalComplete(null));
        yield put(fetchPerformanceCreator());
      }
    } else {
      yield put(hoDashboardActions.setOrderProductApprovalError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderProductApprovalError());
  }
}

// Invoice reject  watcher
export function* fetchHoInvoiceProductRejectDataWatcher() {
  yield takeEvery(
    fetchInvoiceProductRejectTypeName,
    fetchHoInvoiceProductRejectDataWorker,
  );
}

// Invoice Reject worker
export function* fetchHoInvoiceProductRejectDataWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.hoDashboard.invoiceDashboard.invoicePopup.rejectPod}${payObj.currentOrderId}&RejectionRemarks=${payObj.rejectionRemarks}&staffPosId=${payObj.staffPositionId}&currentUser=${payObj.UserId}`;
  try {
    yield put(hoDashboardActions.setOrderProductRejectLoading());
    const response = yield call(NetworkService.post, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setOrderProductRejectError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        ToasterService.hideToaster();
        ToasterService.showToaster(response?.data?.response, ToastType.SUCCESS);
        yield put(hoDashboardActions.setOrderProductApprovalComplete(null));
        yield put(fetchPerformanceCreator());
      }
    } else {
      yield put(hoDashboardActions.setOrderProductRejectError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderProductRejectError());
  }
}

//  invoice reject options watcher
export function* fetchHoInvoiceRejectOptionsWatcher() {
  yield takeEvery(
    fetchRejectInvoiceOptionsDataTypeName,
    fetchHoInvoiceRejectOptionsWorker,
  );
}

//  invoice Reject options worker
export function* fetchHoInvoiceRejectOptionsWorker(actions: any): any {
  const url = `${API_PATH.zho.hoDashboard.invoiceDashboard.invoiceRejectOptions}`;
  try {
    yield put(hoDashboardActions.setOrderRejectOptionsLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setOrderRejectOptionsError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setOrderRejectOptionsData(
            response?.data?.response,
          ),
        );
      }
    } else {
      yield put(hoDashboardActions.setOrderRejectOptionsError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setOrderRejectOptionsError());
  }
}

// Performance watcher
export function* fetchHoPerformanceDataWatcher() {
  yield takeEvery(fetchPerformanceTypeName, fetchHoPerformanceDataWorker);
}

// orderForm innertable Dropdown worker
export function* fetchHoPerformanceDataWorker(actions: any): any {
  const url = `${API_PATH.zho.hoDashboard.performance}`;
  try {
    yield put(hoDashboardActions.setPerformanceLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setPerformanceError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setPerformanceData(response?.data?.response),
        );
      }
    } else {
      yield put(hoDashboardActions.setPerformanceError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setPerformanceError());
  }
}
// POD for order ID watcher
export function* fetchHoPodForOrderIdWatcher() {
  yield takeEvery(fetchPodForOrderIdTypeName, fetchHoPodForOrderIdWorker);
}

// POD for order ID worker
export function* fetchHoPodForOrderIdWorker(actions: any): any {
  const url = `${API_PATH.zho.hoDashboard.invoiceDashboard.viewPOD.podForOrderId}${actions.payload.orderId}`;
  try {
    yield put(hoDashboardActions.setPodForOrderIdLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setPodForOrderIdError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setPodForOrderIdData(response?.data?.response),
        );
      }
    } else {
      yield put(hoDashboardActions.setPodForOrderIdError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setPodForOrderIdError());
  }
}

// download POD file watcher
export function* fetchHoDownloadPodFileWatcher() {
  yield takeEvery(fetchDownloadPodFileTypeName, fetchHoDownloadPodFileWorker);
}

// download POD file worker
export function* fetchHoDownloadPodFileWorker(actions: any): any {
  const url = `${API_PATH.zho.hoDashboard.invoiceDashboard.viewPOD.downloadPodFile}${actions.payload.fileName}`;
  try {
    yield put(hoDashboardActions.setDownloadPodFileLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setDownloadPodFileError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setDownloadPodFileData(response?.data?.response),
        );
      }
      // const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response?.data?.response}`;
      // const downloadLink = document.createElement('a');
      // document.body.appendChild(downloadLink);
      // downloadLink.href = linkSource;
      // downloadLink.target = '_self';
      // downloadLink.download = actions.payload.fileName;
      // downloadLink.click();
    } else {
      yield put(hoDashboardActions.setDownloadPodFileError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setDownloadPodFileError());
  }
}

// edit POD for order watcher
export function* fetchHoEditPodForOrderWatcher() {
  yield takeEvery(fetchEditPodForOrderTypeName, fetchHoEditPodForOrderWorker);
}

// edit POD for order Worker
export function* fetchHoEditPodForOrderWorker(actions: any): any {
  const url = `${API_PATH.zho.hoDashboard.invoiceDashboard.viewPOD.editPodForOrder}`;
  try {
    yield put(hoDashboardActions.setEditPodForOrderLoading());
    const response = yield call(NetworkService.post, url, actions.payload, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setEditPodForOrderError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setEditPodForOrderMsg(response?.data?.response),
        );
        yield put(fetchOrderFormProductDataCreator(actions.payload.orderId));
        ToasterService.hideToaster();
        ToasterService.showToaster(response?.data?.response, ToastType.SUCCESS);
      }
    } else {
      yield put(hoDashboardActions.setEditPodForOrderError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setEditPodForOrderError());
    ToasterService.hideToaster();
    ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
  }
}

// upload POD document
export function* fetchHoUploadPodDocumentWatcher() {
  yield takeEvery(
    fetchUploadPodDocumentTypeName,
    fetchHoUploadPodDocumentWorker,
  );
}

// upload POD document
export function* fetchHoUploadPodDocumentWorker(actions: any): any {
  const url = `${API_PATH.zho.hoDashboard.invoiceDashboard.viewPOD.uploadPodDocument}${actions.payload.orderId}`;
  const formdata = new FormData();
  formdata.append('file', actions.payload.file);
  try {
    yield put(hoDashboardActions.setUploadPodDocumentLoading());
    const response = yield call(NetworkService.post, url, formdata, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setUploadPodDocumentError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setUploadPodDocumentMsg(response?.data?.response),
        );
        yield put(fetchOrderFormProductDataCreator(actions.payload.orderId));
      }
    } else {
      yield put(hoDashboardActions.setUploadPodDocumentError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setUploadPodDocumentError());
    ToasterService.hideToaster();
    ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
  }
}

// header count  watcher
export function* fetchHoadminHeaderCountWatcher() {
  yield takeEvery(fetchHosHeaderCountTypeName, fetchHoadminHeaderCountWorker);
}

// header count  worker
export function* fetchHoadminHeaderCountWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.headerCount.hos}${payObj.currentStaffId}`;
  try {
    yield put(hoDashboardActions.setHoadminHeaderCountLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setHoadminHeaderCountError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setHoadminHeaderCountData(response?.data.response),
        );
      }
    } else {
      yield put(hoDashboardActions.setHoadminHeaderCountError());
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setHoadminHeaderCountError());
  }
}

// Customer  watcher
export function* fetchHoCustomerWatcher() {
  yield takeEvery(fetchCustomerDataTypeName, fetchHoCustomerDataWorker);
}

// Customer Dropdown worker
export function* fetchHoCustomerDataWorker(actions: any): any {
  const payObj = actions.payload;

  const url = `${API_PATH.zho.summary.filter.customer}${payObj.divisionId}`;

  try {
    yield put(hoDashboardActions.setCustomerLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setCustomerError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(hoDashboardActions.setCustomerData(response?.data?.response));
      }
    } else {
      yield put(hoDashboardActions.setCustomerError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setCustomerError());
  }
}

// Summary total box watcher
export function* fetchSummaryTotalWatcher() {
  yield takeEvery(fetchSummaryTotalDataTypeName, fetchSummaryTotalDataWorker);
}

// Customer total box worker
export function* fetchSummaryTotalDataWorker(actions: any): any {
  const payObj = actions.payload;

  const url = `${API_PATH.zho.summary.table.totalBox}`;

  try {
    yield put(hoDashboardActions.setSummaryTotalLoading());
    const response = yield call(NetworkService.post, url, payObj.payload, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setSummaryTotalError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setSummaryTotalData(response?.data?.response),
        );
      }
    } else {
      yield put(hoDashboardActions.setSummaryTotalError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setSummaryTotalError());
  }
}

// Summary table watcher
export function* fetchSummaryTableWatcher() {
  yield takeEvery(fetchSummaryTableDataTypeName, fetchSummaryTableDataWorker);
}

// summary table worker
export function* fetchSummaryTableDataWorker(actions: any): any {
  const payObj = actions.payload;

  const url = `${API_PATH.zho.summary.table.tableTab}${payObj.tabType}`;

  try {
    yield put(hoDashboardActions.setSummaryTableLoading());
    const response = yield call(NetworkService.post, url, payObj.payload, {});
    if (response.status === HTTP_OK) {
      if (response?.data?.isError) {
        yield put(hoDashboardActions.setSummaryTableError());
        ToasterService.hideToaster();
        ToasterService.showToaster(
          response?.data?.errorMessage,
          ToastType.ERROR,
        );
      } else {
        yield put(
          hoDashboardActions.setSummaryTableData(response?.data?.response),
        );
      }
    } else {
      yield put(hoDashboardActions.setSummaryTableError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(hoDashboardActions.setSummaryTableError());
  }
}
