import moment from 'moment';

const DateFormat = (date: string | null | Date): string => {
  if (!date) {
    return '---';
  }
  return moment(date).format('DD/MM/YYYY');
};

const IstFormat = (date: string | null): string => {
  if (!date) {
    return '---';
  }
  return moment.utc(date).utcOffset('+05:30').format('hh:mm A');
};

export default {DateFormat, IstFormat};
