import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from '@mui/material';
import {useStyles} from '../../styles';
import {RootStateOrAny, useSelector} from 'react-redux';
import {strings} from '@app/common/strings';

const ToggleTable = () => {
  const classes = useStyles();

  const tableInnerProductData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.requestInnerFormData,
  );

  const tableInnerDataLoader = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.requestInnerFormLoading,
  );

  return (
    <Box sx={{position: 'relative', marginTop: '-8px'}}>
      {!tableInnerDataLoader ? (
        <Table
          sx={{
            borderCollapse: 'separate',
            width: '60%',
            borderSpacing: '0px 8px',
          }}
          aria-label="simple table">
          <TableHead>
            <TableRow sx={{transform: 'translatey(16px)'}}>
              <TableCell
                sx={{background: 'white', border: '1px solid #E2E2E2'}}
                className={`${classes.defaultText} ${classes.noBorderBottom} ${classes.borderTopLeft} `}></TableCell>
              <TableCell
                sx={{fontSize: '11.7px'}}
                colSpan={2}
                className={` ${classes.toggleProDetailsTableTopHeadings} ${classes.proDetailsLeftBorder} `}>
                {strings.zho.standardTradeDiscount}
              </TableCell>
              <TableCell
                sx={{fontSize: '11.7px'}}
                colSpan={2}
                className={`${classes.toggleProDetailsTableTopHeadings} ${classes.proDetailsLeftBorder}`}>
                {strings.zho.additionalradeDiscount}
              </TableCell>
              <TableCell
                sx={{fontSize: '11.7px'}}
                colSpan={2}
                className={`${classes.toggleProDetailsTableTopHeadings} ${classes.proDetailsLeftBorder} ${classes.borderTopRight}`}>
                {strings.zho.totalTradeDiscount}
              </TableCell>
            </TableRow>

            <TableRow
              sx={{transform: 'translatey(8px)'}}
              className={`${classes.proDeatilsThead}`}>
              <TableCell
                sx={{
                  textTransform: 'uppercase',
                  fontSize: '10.7px',
                }}
                className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.productTableLeftBorder} ${classes.proDetailsHeading} ${classes.cellPadding}`}>
                {strings.zho.selectedproducts}
              </TableCell>
              <TableCell
                sx={{fontSize: '10.7px'}}
                className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                {strings.zho.qty}
              </TableCell>
              <TableCell
                sx={{fontSize: '10.7px'}}
                className={`${classes.defaultLightText} ${classes.noBorderBottom}  ${classes.cellPadding}`}>
                {strings.zho.percent}
              </TableCell>
              <TableCell
                sx={{fontSize: '10.7px'}}
                className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                {strings.zho.qty}
              </TableCell>
              <TableCell
                sx={{fontSize: '10.7px'}}
                className={`${classes.defaultLightText} ${classes.noBorderBottom}  ${classes.cellPadding}`}>
                {strings.zho.percent}
              </TableCell>
              <TableCell
                sx={{fontSize: '10.7px'}}
                className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                {strings.zho.qty}
              </TableCell>
              <TableCell
                sx={{fontSize: '10.7px'}}
                className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.productTableRightBorder}  ${classes.cellPadding}`}>
                {strings.zho.percent}
              </TableCell>
              <TableCell
                sx={{fontSize: '10.7px'}}
                className={`${classes.defaultLightText} ${classes.noBorder} `}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={`${classes.productDetailsTableBody}`}>
            {tableInnerProductData?.map((data: any, index: number) => {
              const isLastRow = index === tableInnerProductData.length - 1;
              return (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      fontSize: '12.7px',
                      fontWeight: '400 !important',
                      borderTop: '1.2px solid #E2E2E2',
                    }}
                    className={`${classes.proDetailsCompDivider}  ${
                      classes.productTableLeftBorder
                    } ${classes.selectedProductsWidth} ${
                      classes.borderBottomLeft
                    } ${classes.cellPadding} ${
                      index === 0 ? '' : classes.borderTopLeft
                    }`}>
                    {data ? data?.productName : '---'}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '12.7px',
                      fontWeight: '400 !important',
                      borderTop: '1.2px solid #E2E2E2',
                    }}
                    className={`${classes.proDetailsCompDivider} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                    {data
                      ? `${data?.stdBaseQuantity}${'+'}${
                          data?.stdBonusQuantity
                        }`
                      : '---'}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '12.7px',
                      fontWeight: '400 !important',
                      borderTop: '1.2px solid #E2E2E2',
                    }}
                    className={`${classes.proDetailsCompDivider}  ${classes.cellPadding}`}>
                    {data
                      ? (
                          (data?.stdBonusQuantity /
                            (data?.stdBaseQuantity + data?.stdBonusQuantity)) *
                          100
                        ).toFixed(2)
                      : '---'}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '12.7px',
                      fontWeight: '400 !important',
                      borderTop: '1.2px solid #E2E2E2',
                    }}
                    className={`${classes.proDetailsCompDivider} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                    {data ? data?.additionalQuantity : '---'}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '12.7px',
                      fontWeight: '400 !important',
                      borderTop: '1.2px solid #E2E2E2',
                    }}
                    className={`${classes.proDetailsCompDivider} ${classes.cellPadding}`}>
                    {data
                      ? (
                          parseFloat(
                            (
                              ((data?.additionalQuantity +
                                data?.stdBonusQuantity) /
                                (data?.stdBaseQuantity +
                                  data?.additionalQuantity +
                                  data?.stdBonusQuantity)) *
                              100
                            ).toFixed(2),
                          ) -
                          parseFloat(
                            (
                              (data?.stdBonusQuantity /
                                (data?.stdBaseQuantity +
                                  data?.stdBonusQuantity)) *
                              100
                            ).toFixed(2),
                          )
                        ).toFixed(2)
                      : '---'}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '12.7px',
                      fontWeight: '400 !important',
                      borderTop: '1.2px solid #E2E2E2',
                      whiteSpace: 'nowrap',
                    }}
                    className={`${classes.proDetailsCompDivider} ${classes.proDetailsLeftBorder}  ${classes.cellPadding}`}>
                    {data
                      ? ` ${data?.stdBaseQuantity} ${'+'} ${
                          data?.additionalQuantity + data?.stdBonusQuantity
                        }`
                      : '---'}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '12.7px',
                      fontWeight: '400 !important',
                      borderTop: '1.2px solid #E2E2E2',
                    }}
                    className={`${classes.proDetailsCompDivider} ${
                      classes.productTableRightBorder
                    } ${classes.borderBottomRight} ${
                      index === 0 ? '' : classes.borderTopRight
                    }
                     ${classes.cellPadding}`}>
                    {data
                      ? parseFloat(
                          (
                            ((data?.additionalQuantity +
                              data?.stdBonusQuantity) /
                              (data?.stdBaseQuantity +
                                data?.additionalQuantity +
                                data?.stdBonusQuantity)) *
                            100
                          ).toFixed(2),
                        ).toFixed(2)
                      : '---'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Table sx={{minHeight: '188.877px'}}>
          <Box
            className={classes.loader}
            sx={[{display: 'flex', justifyContent: 'center', mt: 3}]}>
            <CircularProgress />
          </Box>
        </Table>
      )}
    </Box>
  );
};

export default ToggleTable;
