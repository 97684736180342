import {filterStateActions} from '@app/components/right-sidebar/redux';
import {
  navbarComponentName,
  routeStateActions,
} from '@app/router/redux/routeHandler';
import FilterSummary from '@app/screens/zho/components/summary/filterSummary';
import TableSummary from '@app/screens/zho/components/summary/tableSummary';
import TotalBoxSummary from '@app/screens/zho/components/summary/totalBoxSummary';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {setSubTabSwitch} from '@app/screens/zho/pages/fc-admin/redux/tabName';
import {Stack} from '@mui/system';
import {hoDashboardActions} from '../redux-api';
import {clearSummaryPayload} from '../redux/orderStore';
export const Summary = ({userLoginInfo}: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(filterStateActions.setFilterState({}));
    dispatch(
      routeStateActions.setNavbarComponentName(navbarComponentName.fcAdmin),
    );
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(hoDashboardActions.setSummaryTableData([]));
      dispatch(hoDashboardActions.setSummaryTotalData([]));
      dispatch(clearSummaryPayload());
      dispatch(setSubTabSwitch(1));
    };
  }, []);
  return (
    <>
      <Stack width="100%" direction="column">
        <FilterSummary userLoginInfo={userLoginInfo} />
        <TotalBoxSummary userLoginInfo={userLoginInfo} />
        <TableSummary userLoginInfo={userLoginInfo} />
      </Stack>
    </>
  );
};
