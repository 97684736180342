import theme from '@app/themes';

export const styles: any = {
  flexStartCenter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  invalidAmount: {
    fontSize: '8px',
    color: theme.colors.redish,
  },
  gridStyle: {
    position: 'sticky',
    top: 120,
    background: 'white',
    zIndex: 1000,
  },
  font13: {
    fontSize: '13px',
  },
  font12: {
    fontSize: '12px',
  },
  font11: {
    fontSize: '11px',
  },
  dropdown: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 200,
  },
  miscBox: {
    height: 460,
    width: '100%',
  },
  paddingBottom40: {
    paddingBottom: 40,
  },
  errorDialogBox: {
    padding: '5px 24px !important',
  },
  errorIcon: {
    color: theme.colors.redish,
    fontSize: '24px',
    marginTop: '2px',
  },
  closeIcon: {
    position: 'absolute',
    color: theme.colors.greyish,
    fontSize: '16px',
  },
  adminChangeTaReason: {
    fontSize: '12px',
    marginBottom: '5px',
    color: theme.colors.blackish,
  },
  adminChangeReason: {
    fontSize: '12px',
    marginBottom: '5px',
    color: theme.colors.blackish,
    marginTop: '5px',
  },
  remarksText: {
    fontSize: '12px',
    color: theme.colors.redish,
    marginTop: '10px',
  },
  tabViewContainer: {
    position: 'fixed',
    width: '100%',
    top: 115,
    backgroundColor: theme.colors.blacker,
    margin: 0,
    padding: '1%',
    zIndex: 1,
  },
  approveExpenseMain: {
    padding: '0px !important',
    position: 'relative',
    top: 30,
  },
  footerButton: {
    backgroundColor: '#ffffff',
    color: theme.colors.primary,
    border: '2px solid #322b7c',
    borderRadius: '5px',
    width: '150px',
    boxShadow: 'none',
    marginRight: '10px',
    fontWeight: 'bold',
    '&:hover': {
      background: 'none',
    },
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
  },
  cell: {
    border: '1px solid black',
    borderCollapse: 'collapse',
  },
  divContainer: {
    width: '100%',
    fontFamily: 'Balsamiq-Sans, serif',
    letterSpacing: '0.01px',
  },
  headerContainer: {
    border: '1px solid black',
    textAlign: 'left',
    fontSize: 10,
    width: '100px',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  tableContainer: {
    border: '1px solid black',
    textAlign: 'left',
    fontSize: 9,
    width: '100px',
  },
};
