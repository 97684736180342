import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const DropdownStyle = {
  root: {
    background: "#fff",
    color: "#1c1939",
    marginLeft: "5px",
    minWidth: 310,

    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        padding: "6px",
        fontSize: "12px !important",
      },
    "& .css-zfrihp-MuiSvgIcon-root-MuiSelect-icon": {
      color: "#322b7c",
      fontSize: "12px !important",
    },
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      borderColor: "#322b7c",
      borderRadius: "4px",
      paddingLeft: "7px",
      fontSize: "12px !important",
    },

    "& em ": {
      fontSize: "12px",
      color: "#1c1939",
      fontStyle: "normal",
      opacity: 0.5,
    },
  },
  smroot: {
    background: "#fff",
    color: "#1c1939",
    marginLeft: "5px",
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        padding: "6px",
      },
    "& .css-zfrihp-MuiSvgIcon-root-MuiSelect-icon": {
      color: "#322b7c",
    },
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      borderColor: "#322b7c",
      borderRadius: "4px",
      paddingLeft: "7px",
    },

    "& em ": {
      fontSize: "12px",
      color: "#1c1939",
      fontStyle: "normal",
      opacity: 0.5,
    },
    "& input::placeholder": {
      fontSize: "11px"
    }
  },
  number: {
    color: "#1c1939",
    paddingLeft: "7px",
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      borderColor: "#322b7c",
      borderRadius: "4px",
    },
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "10px",
      fontSize: "12px",
      color: "#1c1939",
      fontStyle: "normal",
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  textroot: {
    color: "#1c1939",
    paddingLeft: "7px",
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      borderColor: "#322b7c",
      borderRadius: "4px",
    },
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "20px !important",
      fontSize: "12px",
      color: "#1c1939",
      fontStyle: "normal",
    },
  },
};
export const option = {
  "&.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper": {
    top:'200px !important',
    borderRadius: '10px !important',
  }
};
export const CssTextField = styled(TextField)({
  color: "#1c1939",
  paddingLeft: "7px",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#322b7c",
    borderRadius: "4px",
  },
  "& .MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "10px",
    fontSize: "12px",
    color: "#1c1939",
    fontStyle: "normal",
  },
});
export const SelectCss = {
  background: "#fff",
  color: "#1c1939",
  marginLeft: "5px",
  minWidth: 310,
  "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "6px",
  },
  "& .css-zfrihp-MuiSvgIcon-root-MuiSelect-icon": {
    color: "#322b7c",
  },
  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderColor: "#322b7c",
    borderRadius: "4px",
    paddingLeft: "7px",
  },
  "& em ": {
    fontSize: "12px",
    color: "#1c1939",
    fontStyle: "normal",
    opacity: 0.5,
  },
};
