import {createAction, createSlice} from '@reduxjs/toolkit';

export const hoDashboardInitialState = {
  // Dropdown for state
  stateLoading: false,
  stateData: [],
  stateError: false,
  stateMsg: '',

  // Dropdown for hq
  hqLoading: false,
  hqData: [],
  hqError: false,
  hqMsg: '',

  // Dropdown for mr
  mrLoading: false,
  mrData: [],
  mrError: false,
  mrMsg: '',

  // Main request fomm table
  orderFormLoading: false,
  orderFormData: [],
  orderFormError: false,
  orderFormMsg: '',

  // orderForm Inner Table toggle
  orderInnerFormLoading: false,
  orderInnerFormData: [],
  orderInnerformError: false,
  orderInnerformMsg: '',

  // orderProduct form details
  orderFormProductLoading: false,
  orderFormProductData: [],
  orderFormProductError: false,
  orderFormProductMsg: '',

  // orderform popup approval status
  orderFormApprovalLoading: false,
  orderFormApprovalData: [],
  orderFormApprovalError: false,
  orderFormApprovalMsg: '',

  // order Approval
  orderProductApprovalLoading: false,
  orderProductApprovalError: false,
  orderProductApprovalMsg: null,
  orderProductApprovalComplete: false,

  // order Reject
  orderProductRejectLoading: false,
  orderProductRejectError: false,
  orderProductRejectMsg: '',

  // Order Sync
  orderSyncLoading: false,
  orderSyncError: false,
  orderSyncMsg: null,

  // order reject options
  orderRejectOptionsLoading: false,
  orderRejectOptionsData: [],
  orderRejectOptionsError: false,
  orderRejectOptionsMsg: '',

  orderErrorInfoMsgLoading: false,
  orderErrorInfoMsgData: [],
  orderErrorInfoMsgError: false,
  orderErrorInfoMsgMsg: '',

  // invoice Approval
  invoiceProductApprovalLoading: false,
  invoiceProductApprovalError: false,
  invoiceProductApprovalMsg: '',
  invoiceProductApprovalComplete: false,

  // invoice Reject
  invoiceProductRejectLoading: false,
  invoiceProductRejectError: false,
  invoiceProductRejectMsg: '',

  //performance
  hoPerformanceLoading: false,
  hoPerformanceData: [],
  hoPerformanceError: false,
  hoPerformanceMsg: '',
  // view POD
  podForOrderIdLoading: false,
  podForOrderIdData: [],
  podForOrderIdError: false,
  podForOrderIdMsg: '',

  // download POD file
  downloadPodFileLoading: false,
  downloadPodFileData: [],
  downloadPodFileError: false,
  downloadPodFileMsg: '',

  // edit POD for order
  editPodForOrderLoading: false,
  editPodForOrderError: false,
  editPodForOrderMsg: '',

  // upload POD document
  uploadPodDocumentLoading: false,
  uploadPodDocumentError: false,
  uploadPodDocumentMsg: '',

  // header count
  hoadminHeaderCountLoading: false,
  hoadminHeaderCountNo: null,
  hoadminHeaderCountError: false,

  // Dropdown for customer
  customerLoading: false,
  customerData: [],
  customerError: false,
  customerMsg: '',

  // summary total box
  summaryTotalLoading: false,
  summaryTotalData: [],
  summaryTotalError: false,
  summaryTotalMsg: '',

  // summary table tab
  summaryTableLoading: false,
  summaryTableData: [],
  summaryTableError: false,
  summaryTableMsg: '',
};

//  for state Dropdown
export const fetchStateDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_STATE_DATA',
  (currentStaffPositionId, type) => ({payload: {currentStaffPositionId, type}}),
);

export const fetchStateDataTypeName = fetchStateDataCreator.type;

// for hq Dropdown

export const fetchHqDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_HQ_DATA',
  (currentStateId, type) => ({payload: {currentStateId, type}}),
);

export const fetchHqDataTypeName = fetchHqDataCreator.type;

// for Mr Dropdown

export const fetchMrDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_MR_DATA',
  (hqIds, type) => ({payload: {hqIds, type}}),
);

export const fetchMrDataTypeName = fetchMrDataCreator.type;

// for Order form table Ho
export const fetchOrderFormDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderTableData',
  (currentRequestStatus, statusToggleNo, type) => ({
    payload: {currentRequestStatus, statusToggleNo, type},
  }),
);

export const fetchOrderFormDataTypeName = fetchOrderFormDataCreator.type;

// for Order innerTable
export const fetchOrderInnerFormDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderInnerTableData',
  currentOrderId => ({
    payload: {currentOrderId},
  }),
);

export const fetchOrderInnerFormDataTypeName =
  fetchOrderInnerFormDataCreator.type;

// for OrderForm Product Details
export const fetchOrderFormProductDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderFormProductData',
  currentOrderId => ({
    payload: {currentOrderId},
  }),
);

export const fetchOrderFormProductDataTypeName =
  fetchOrderFormProductDataCreator.type;

// for OrderForm Approval
export const fetchOrderFormApprovalDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderFormApprovalData',
  currentOrderId => ({
    payload: {currentOrderId},
  }),
);

export const fetchOrderFormApprovalDataTypeName =
  fetchOrderFormApprovalDataCreator.type;

// for OrderForm product Approval
export const fetchOrderProductApprovalCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderProductApprovalData',
  (staffPositionId, UserId, currentOrderIds, type) => ({
    payload: {staffPositionId, UserId, currentOrderIds, type},
  }),
);

export const fetchOrderProductApprovalDataTypeName =
  fetchOrderProductApprovalCreator.type;

// for OrderForm Reject
export const fetchOrderProductRejectCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderProductRejectData',
  (staffPositionId, rejectionRemarks, UserId, currentOrderId, type) => ({
    payload: {staffPositionId, rejectionRemarks, UserId, currentOrderId, type},
  }),
);

export const fetchOrderProductRejectDataTypeName =
  fetchOrderProductRejectCreator.type;

// for OrderSync
export const fetchOrderSyncCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_OrderSyncData',
  (currentOrderId, currentRequestStatus, statusToggleNo, type) => ({
    payload: {currentOrderId, currentRequestStatus, statusToggleNo, type},
  }),
);

export const fetchOrderSyncDataTypeName = fetchOrderSyncCreator.type;

// for reject order options
export const fetchRejectOptionsCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_RejectOptionsData',
);

export const fetchRejectOptionsDataTypeName = fetchRejectOptionsCreator.type;

// for order dashboard tooltip error
export const fetchErrorInfoMsgCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_ErrorInfoMsgData',
  currentOrderId => ({
    payload: {currentOrderId},
  }),
);

export const fetchErrorInfoMsgDataTypeName = fetchErrorInfoMsgCreator.type;

// for Invoice dashboard form table Ho
export const fetchInvoiceFormDataCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_InvoiceTableData',
  (currentRequestStatus, statusToggleNo) => ({
    payload: {currentRequestStatus, statusToggleNo},
  }),
);

export const fetchInvoiceFormDataTypeName = fetchInvoiceFormDataCreator.type;

// for Invoice approval dashboard form table Ho
export const fetchInvoiceProductApproveCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_InvoiceProductApprove',
  (staffPositionId, UserId, currentOrderIds) => ({
    payload: {staffPositionId, UserId, currentOrderIds},
  }),
);

export const fetchInvoiceProductApproveTypeName =
  fetchInvoiceProductApproveCreator.type;

// for Invoice reject form table Ho
export const fetchInvoiceProductRejectCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_InvoiceProductReject',
  (staffPositionId, rejectionRemarks, UserId, currentOrderId) => ({
    payload: {staffPositionId, rejectionRemarks, UserId, currentOrderId},
  }),
);

export const fetchInvoiceProductRejectTypeName =
  fetchInvoiceProductRejectCreator.type;

// for reject Invoice options
export const fetchRejectInvoiceOptionsCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_RejectInvoiceOptionsData',
);

export const fetchRejectInvoiceOptionsDataTypeName =
  fetchRejectInvoiceOptionsCreator.type;
// For view POD
export const fetchPodForOrderIdCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_PodForOrderId',
  orderId => ({
    payload: {orderId},
  }),
);

export const fetchPodForOrderIdTypeName = fetchPodForOrderIdCreator.type;

// download POD File
export const fetchDownloadPodFileCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_DownloadPodFile',
  fileName => ({
    payload: {fileName},
  }),
);

export const fetchDownloadPodFileTypeName = fetchDownloadPodFileCreator.type;

// edit POD for order
export const fetchEditPodForOrderCreator = createAction(
  'HO_DASHBOARD_HANDLER//EditPodForOrder',
  payload => ({
    payload: payload,
  }),
);

export const fetchEditPodForOrderTypeName = fetchEditPodForOrderCreator.type;

// upload POD document
export const fetchUploadPodDocumentCreator = createAction(
  'HO_DASHBOARD_HANDLER//UploadPodDocument',
  payload => ({
    payload: payload,
  }),
);

export const fetchUploadPodDocumentTypeName =
  fetchUploadPodDocumentCreator.type;

// for performance

// for reject Invoice options
export const fetchPerformanceCreator = createAction(
  'HO_DASHBOARD_HANDLER//FETCH_performance',
);

export const fetchPerformanceTypeName = fetchPerformanceCreator.type;

// fc-admin header count

export const fetchHosHeaderCountCreator = createAction(
  'HOS_SCREEEN_HANDLER//FETCH_HosHeaderCount',
  currentStaffId => ({
    payload: {currentStaffId},
  }),
);

export const fetchHosHeaderCountTypeName = fetchHosHeaderCountCreator.type;

//  for customer Dropdown
export const fetchCustomerDataCreator = createAction(
  'SUMMARY_HANDLER//FETCH_Customer_DATA',
  divisionId => ({payload: {divisionId}}),
);

export const fetchCustomerDataTypeName = fetchCustomerDataCreator.type;

//  for summary total box
export const fetchSummaryTotalDataCreator = createAction(
  'SUMMARY_HANDLER//FETCH_SummaryTotal_DATA',
  payload => ({payload: {payload}}),
);

export const fetchSummaryTotalDataTypeName = fetchSummaryTotalDataCreator.type;

//  for summary table type
export const fetchSummaryTableDataCreator = createAction(
  'SUMMARY_HANDLER//FETCH_SummaryTable_DATA',
  (payload, tabType) => ({payload: {payload, tabType}}),
);

export const fetchSummaryTableDataTypeName = fetchSummaryTableDataCreator.type;

const hoDashboardStatehandler = createSlice({
  name: 'HO_DASHBOARD_HANDLER',
  initialState: hoDashboardInitialState,
  reducers: {
    // filter selected data
    setStateLoading: prevState => {
      return {
        ...prevState,
        stateLoading: true,
        stateData: [],
        stateError: false,
        stateMsg: '',
      };
    },

    setStateData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        stateLoading: false,
        stateData: payload,
        stateError: false,
      };
    },

    setStateError: prevState => {
      return {
        ...prevState,
        stateLoading: false,
        stateData: [],
        stateError: true,
        stateMsg: '',
      };
    },

    // filter Hq data
    setHqLoading: prevState => {
      return {
        ...prevState,
        hqLoading: true,
        hqData: [],
        hqError: false,
        hqMsg: '',
      };
    },

    setHqData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        hqLoading: false,
        hqData: payload,
        hqError: false,
      };
    },

    setHqError: prevState => {
      return {
        ...prevState,
        hqLoading: false,
        hqData: [],
        hqError: true,
        hqMsg: '',
      };
    },

    // filter Mr data
    setMrLoading: prevState => {
      return {
        ...prevState,
        mrLoading: true,
        mrData: [],
        mrError: false,
        mrMsg: '',
      };
    },

    setMrData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        mrLoading: false,
        mrData: payload,
        mrError: false,
      };
    },

    setMrError: prevState => {
      return {
        ...prevState,
        mrLoading: false,
        mrData: [],
        mrError: true,
        mrMsg: '',
      };
    },

    // orderForm main table Data
    setOrderFormLoading: prevState => {
      return {
        ...prevState,
        orderFormLoading: true,
        orderFormData: [],
        orderFormError: false,
        orderFormMsg: '',
      };
    },

    setOrderFormData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderFormLoading: false,
        orderFormData: payload,
        orderFormError: false,
      };
    },

    setOrderFormError: prevState => {
      return {
        ...prevState,
        orderFormLoading: false,
        orderFormData: [],
        orderFormError: true,
        orderFormMsg: '',
      };
    },

    // orderForm innerTable
    setOrderInnerFormLoading: prevState => {
      return {
        ...prevState,
        orderInnerFormLoading: true,
        orderInnerFormData: [],
        orderInnerformError: false,
        orderInnerformMsg: '',
      };
    },

    setOrderInnerFormData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderInnerFormLoading: false,
        orderInnerFormData: payload,
        orderInnerformError: false,
      };
    },

    setOrderInnerFormError: prevState => {
      return {
        ...prevState,
        orderInnerFormLoading: false,
        orderInnerFormData: [],
        orderInnerformError: true,
        orderInnerformMsg: '',
      };
    },

    // orderPopup product Details
    setOrderFormProductLoading: prevState => {
      return {
        ...prevState,
        orderFormProductLoading: true,
        orderFormProductData: [],
        orderFormProductError: false,
        orderFormProductMsg: '',
      };
    },

    setOrderFormProductData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderFormProductLoading: false,
        orderFormProductData: payload,
        orderFormProductError: false,
      };
    },

    setOrderFormProductError: prevState => {
      return {
        ...prevState,
        orderFormProductLoading: false,
        orderFormProductData: [],
        orderFormProductError: true,
        orderFormProductMsg: '',
      };
    },

    // orderPopup Approval Details
    setOrderFormApprovalLoading: prevState => {
      return {
        ...prevState,
        orderFormApprovalLoading: true,
        orderFormApprovalData: [],
        orderFormApprovalError: false,
        orderFormApprovalMsg: '',
      };
    },

    setOrderFormApprovalData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderFormApprovalLoading: false,
        orderFormApprovalData: payload,
        orderFormApprovalError: false,
      };
    },

    setOrderFormApprovalError: prevState => {
      return {
        ...prevState,
        orderFormApprovalLoading: false,
        orderFormApprovalData: [],
        orderFormApprovalError: true,
        orderFormApprovalMsg: '',
      };
    },

    // order Product Approval Details
    setOrderProductApprovalLoading: prevState => {
      return {
        ...prevState,
        orderProductApprovalLoading: true,
        orderProductApprovalData: [],
        orderProductApprovalError: false,
        orderProductApprovalMsg: null,
      };
    },

    setOrderProductApprovalLoadingComplete: prevState => {
      return {
        ...prevState,
        orderProductApprovalLoading: false,
        orderProductApprovalError: false,
      };
    },

    setOrderProductApprovalComplete: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderProductApprovalComplete: !prevState.orderProductApprovalComplete,
        orderProductApprovalMsg: payload,
      };
    },

    setOrderProductApprovalError: prevState => {
      return {
        ...prevState,
        orderProductApprovalLoading: false,
        orderProductApprovalData: [],
        orderProductApprovalError: true,
        orderProductApprovalMsg: null,
      };
    },

    // order Reject
    setOrderProductRejectLoading: prevState => {
      return {
        ...prevState,
        orderProductRejectLoading: true,
        orderProductRejectData: [],
        orderProductRejectError: false,
        orderProductRejectMsg: '',
      };
    },

    setOrderProductRejectComplete: prevState => {
      return {
        ...prevState,
        orderProductApprovalComplete: !prevState.orderProductApprovalComplete,
      };
    },

    setOrderProductRejectError: prevState => {
      return {
        ...prevState,
        orderProductRejectLoading: false,
        orderProductRejectData: [],
        orderProductRejectError: true,
        orderProductRejectMsg: '',
      };
    },

    // orderSync
    setOrderSyncLoading: prevState => {
      return {
        ...prevState,
        orderSyncLoading: true,
        orderSyncData: [],
        orderSyncError: false,
        orderSyncMsg: null,
      };
    },

    setOrderSyncCompleted: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderSyncLoading: false,
        orderSyncData: [],
        orderSyncError: false,
        orderSyncMsg: payload,
      };
    },

    setOrderSyncError: prevState => {
      return {
        ...prevState,
        orderSyncLoading: false,
        orderSyncData: [],
        orderSyncError: true,
      };
    },

    // orderreject options
    setOrderRejectOptionsLoading: prevState => {
      return {
        ...prevState,
        orderRejectOptionsLoading: true,
        orderRejectOptionsData: [],
        orderRejectOptionsError: false,
        orderRejectOptionsMsg: '',
      };
    },

    setOrderRejectOptionsData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderRejectOptionsLoading: false,
        orderRejectOptionsData: payload,
        orderRejectOptionsError: false,
      };
    },

    setOrderRejectOptionsError: prevState => {
      return {
        ...prevState,
        orderRejectOptionsLoading: false,
        orderRejectOptionsData: [],
        orderRejectOptionsError: true,
        orderRejectOptionsMsg: '',
      };
    },

    // order dashboard tooltip error
    setOrderErrorInfoMsgLoading: prevState => {
      return {
        ...prevState,
        orderErrorInfoMsgLoading: true,
        orderErrorInfoMsgData: [],
        orderErrorInfoMsgError: false,
        orderErrorInfoMsgMsg: '',
      };
    },

    setOrderErrorInfoMsgData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        orderErrorInfoMsgLoading: false,
        orderErrorInfoMsgData: payload,
        orderErrorInfoMsgError: false,
      };
    },

    setOrderErrorInfoMsgError: prevState => {
      return {
        ...prevState,
        orderErrorInfoMsgLoading: false,
        orderErrorInfoMsgData: [],
        orderErrorInfoMsgError: true,
        orderErrorInfoMsgMsg: '',
      };
    },

    // performance api
    setPerformanceLoading: prevState => {
      return {
        ...prevState,
        hoPerformanceLoading: true,
        hoPerformanceData: [],
        hoPerformanceError: false,
        hoPerformanceMsg: '',
      };
    },

    setPerformanceData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        hoPerformanceLoading: false,
        hoPerformanceData: payload,
        hoPerformanceError: false,
      };
    },

    setPerformanceError: prevState => {
      return {
        ...prevState,
        hoPerformanceLoading: false,
        hoPerformanceData: [],
        hoPerformanceError: true,
        hoPerformanceMsg: '',
      };
    },
    // View POD
    setPodForOrderIdLoading: prevState => {
      return {
        ...prevState,
        podForOrderIdLoading: true,
        podForOrderIdData: [],
        podForOrderIdError: false,
        podForOrderIdMsg: '',
      };
    },

    setPodForOrderIdData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        podForOrderIdLoading: false,
        podForOrderIdData: payload,
        podForOrderIdError: false,
      };
    },

    setPodForOrderIdError: prevState => {
      return {
        ...prevState,
        podForOrderIdLoading: false,
        podForOrderIdData: [],
        podForOrderIdError: true,
        podForOrderIdMsg: '',
      };
    },

    // download POD File
    setDownloadPodFileLoading: prevState => {
      return {
        ...prevState,
        downloadPodFileLoading: true,
        downloadPodFileData: [],
        downloadPodFileError: false,
        downloadPodFileMsg: '',
      };
    },

    setDownloadPodFileData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        downloadPodFileLoading: false,
        downloadPodFileData: payload,
        downloadPodFileError: false,
      };
    },

    setDownloadPodFileError: prevState => {
      return {
        ...prevState,
        downloadPodFileLoading: false,
        downloadPodFileData: [],
        downloadPodFileError: true,
        downloadPodFileMsg: '',
      };
    },

    // edit POD for order
    setEditPodForOrderLoading: prevState => {
      return {
        ...prevState,
        editPodForOrderLoading: true,
        editPodForOrderError: false,
        editPodForOrderMsg: '',
      };
    },

    setEditPodForOrderMsg: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editPodForOrderLoading: false,
        editPodForOrderError: false,
        editPodForOrderMsg: payload,
      };
    },

    setEditPodForOrderError: prevState => {
      return {
        ...prevState,
        editPodForOrderLoading: false,
        editPodForOrderError: true,
        editPodForOrderMsg: '',
      };
    },

    // upload Pod document
    setUploadPodDocumentLoading: prevState => {
      return {
        ...prevState,
        uploadPodDocumentLoading: true,
        uploadPodDocumentError: false,
        uploadPodDocumentMsg: '',
      };
    },

    setUploadPodDocumentMsg: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        uploadPodDocumentLoading: false,
        uploadPodDocumentError: false,
        uploadPodDocumentMsg: payload,
      };
    },

    setUploadPodDocumentError: prevState => {
      return {
        ...prevState,
        uploadPodDocumentLoading: false,
        uploadPodDocumentError: true,
        uploadPodDocumentMsg: '',
      };
    },

    // to get count for header
    setHoadminHeaderCountLoading: prevState => {
      return {
        ...prevState,
        hoadminHeaderCountLoading: true,
        hoadminHeaderCountNo: null,
        hoadminHeaderCountError: false,
      };
    },

    setHoadminHeaderCountData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        hoadminHeaderCountLoading: false,
        hoadminHeaderCountNo: payload,
        hoadminHeaderCountError: false,
      };
    },

    setHoadminHeaderCountError: prevState => {
      return {
        ...prevState,
        hoadminHeaderCountLoading: false,
        hoadminHeaderCountNo: null,
        hoadminHeaderCountError: true,
      };
    },

    // filter Customer data
    setCustomerLoading: prevState => {
      return {
        ...prevState,
        customerLoading: true,
        customerData: [],
        customerError: false,
        customerMsg: '',
      };
    },

    setCustomerData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        customerLoading: false,
        customerData: payload,
        customerError: false,
      };
    },

    setCustomerError: prevState => {
      return {
        ...prevState,
        customerLoading: false,
        customerData: [],
        customerError: true,
        customerMsg: '',
      };
    },

    /// summary total box
    setSummaryTotalLoading: prevState => {
      return {
        ...prevState,
        summaryTotalLoading: true,
        summaryTotalData: [],
        summaryTotalError: false,
        summaryTotalMsg: '',
      };
    },

    setSummaryTotalData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        summaryTotalLoading: false,
        summaryTotalData: payload,
        summaryTotalError: false,
      };
    },

    setSummaryTotalError: prevState => {
      return {
        ...prevState,
        summaryTotalLoading: false,
        summaryTotalData: [],
        summaryTotalError: true,
        summaryTotalMsg: '',
      };
    },

    /// Summary table tab
    setSummaryTableLoading: prevState => {
      return {
        ...prevState,
        summaryTableLoading: true,
        summaryTableData: [],
        summaryTableError: false,
        summaryTableMsg: '',
      };
    },

    setSummaryTableData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        summaryTableLoading: false,
        summaryTableData: payload,
        summaryTableError: false,
      };
    },

    setSummaryTableError: prevState => {
      return {
        ...prevState,
        summaryTableLoading: false,
        summaryTableData: [],
        summaryTableError: true,
        summaryTableMsg: '',
      };
    },
  },
});

export const hoDashboardActions = hoDashboardStatehandler.actions;
export const hoDashboardReducer = hoDashboardStatehandler.reducer;
