import React, {useRef} from 'react';
import './styles.css';
import {Stack, width} from '@mui/system';
import ZhoToggleSeperator from './zhoToggleSeperator';
import {RootStateOrAny, shallowEqual, useSelector} from 'react-redux';
import {FcOrders} from '../../pages/fc-admin/fc-orders';

interface FilterProps {
  role: number;
}

const Togglefilter: React.FC<FilterProps> = ({role}) => {
  const handleToggleChange = () => {};

  const screenIndex = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.activeTabIndex,
  );

  const fcSegments = [
    {
      label: 'Pending Approval',
      value: 'Pending Approval',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Partially Approved',
      value: 'Partially Approved',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Approved',
      value: 'Approved',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Rejected',
      value: 'Rejected',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Expired',
      value: 'Expired',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'All',
      value: 'All',
      ref: useRef<HTMLDivElement>(null),
    },
  ];

  const hoOrders = [
    {
      label: 'Pending Approval',
      value: 'Pending Approval',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Approved',
      value: 'Approved',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Rejected',
      value: 'Rejected',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'All',
      value: 'All',
      ref: useRef<HTMLDivElement>(null),
    },
  ];

  const fcOrders = [
    {
      label: 'Pending Approval',
      value: 'Pending Approval',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Approved',
      value: 'Approved',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Pending POD Upload',
      value: 'Pending POD Upload',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Pending POD Approval',
      value: 'Pending POD Approval',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Rejected',
      value: 'Rejected',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Closed',
      value: 'Closed',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'All',
      value: 'All',
      ref: useRef<HTMLDivElement>(null),
    },
  ];

  const hoInvoice = [
    {
      label: 'Pending POD Upload',
      value: 'Pending POD Upload',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'Pending POD Approval',
      value: 'Pending POD Approval',
      ref: useRef<HTMLDivElement>(null),
    },

    {
      label: 'Closed',
      value: 'Closed',
      ref: useRef<HTMLDivElement>(null),
    },
    {
      label: 'All',
      value: 'All',
      ref: useRef<HTMLDivElement>(null),
    },
  ];

  //  role id
  // FCAdmin
  // 1 - Request Form
  // 4 - FcOrders

  // Hoadmin
  // 2- Orders
  // 3- Invoice

  const renderTabs = () => {
    switch (role) {
      case 1:
        return fcSegments;
      case 2:
        return hoOrders;
      case 3:
        return hoInvoice;
      case 4:
        return fcOrders;
      default:
        return fcSegments;
    }
  };

  const renderIndex = () => {
    if (role === 1 || role === 4 || role === 2) {
      return 0;
    }

    if (role === 3) {
      return 1;
    }
  };

  return (
    <Stack direction="row" justifyContent="flex-end" sx={{padding: '0px 15px'}}>
      <ZhoToggleSeperator
        name="group-1"
        controlRef={useRef()}
        roleId={role}
        defaultIndex={renderIndex()}
        callback={handleToggleChange}
        segments={renderTabs()}
        itemStyle={{padding: '2px 8px', width: 'fit-content'}}
      />
    </Stack>
  );
};

export default Togglefilter;
