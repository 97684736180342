import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Button,
} from '@mui/material';
import {useStyles} from '../style';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {strings} from '@app/common/strings';
import {Stack} from '@mui/system';
import OrderSupplyPod from './orderSupplyPod';
import {useEffect, useState} from 'react';

const OrderProductDetails = ({
  hide,
  approvalStatus,
  supplyPod,
  handleToggleModal,
  orderInnerFormData,
  productData,
}: any) => {
  const classes = useStyles();
  const [isRejectedOrder, setIsRejectedOrder] = useState(false);
  const dispatch = useDispatch();
  const productLoader = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormProductLoading,
  );

  const approvalData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormApprovalData,
  );

  const statusToggleNo = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.statusToggleNo,
  );

  const tab = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.activeTabIndex,
  );

  const [isAllRejected, setIsAllRejected] = useState(false);

  useEffect(() => {
    const hasDeactivated = productData?.products?.some(
      (order: any) => order.isRejected,
    );

    const allDeactivated = productData?.products?.every(
      (order: any) => order.isRejected,
    );

    setIsRejectedOrder(hasDeactivated);
    setIsAllRejected(allDeactivated);
  }, [productData]);

  return (
    <Box sx={{position: 'relative', marginTop: '-8px'}}>
      {!productLoader ? (
        <>
          {!hide && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="end">
              <Typography
                sx={{
                  fontSize: '15.7px !important',
                  marginBottom: '0px !important',
                  marginTop: '20px !important',
                }}
                className={`${classes.RfHeading}  `}
                variant="h6">
                {strings.zho.productDetails}
              </Typography>
            </Stack>
          )}
          <Table
            sx={{
              borderCollapse: 'separate',
              borderSpacing: '0px 8px',
              width: !hide ? '100%' : '60%',
            }}
            aria-label="simple table">
            <TableHead>
              <TableRow sx={{transform: 'translatey(16px)'}}>
                <TableCell
                  sx={{background: 'white', border: '1px solid #E2E2E2'}}
                  className={`${classes.defaultText} ${classes.noBorderBottom} ${classes.borderTopLeft} `}></TableCell>
                <TableCell
                  sx={{fontSize: '11.7px'}}
                  align="center"
                  colSpan={2}
                  className={` ${classes.toggleProDetailsTableTopHeadings} ${classes.proDetailsLeftBorder} `}>
                  {strings.zho.totalTradeDiscount}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{fontSize: '11.7px'}}
                  colSpan={2}
                  className={`${classes.toggleProDetailsTableTopHeadings} ${classes.proDetailsLeftBorder}`}>
                  {strings.zho.hoAdmin.dashboard.modalPopup.supplyQty}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{fontSize: '11.7px'}}
                  colSpan={2}
                  className={`${classes.toggleProDetailsTableTopHeadings} ${classes.proDetailsLeftBorder} ${classes.borderTopRight}`}>
                  {strings.zho.hoAdmin.dashboard.modalPopup.availedDiscount}
                </TableCell>
              </TableRow>

              <TableRow
                sx={{transform: 'translatey(8px)'}}
                className={`${classes.proDeatilsThead}`}>
                <TableCell
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: '10.7px',
                  }}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.productTableLeftBorder} ${classes.proDetailsHeading} ${classes.cellPadding}`}>
                  {strings.zho.selectedproducts}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{fontSize: '10.7px'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                  {strings.zho.qty}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{fontSize: '10.7px'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom}  ${classes.cellPadding}`}>
                  {strings.zho.percent}
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{fontSize: '10.7px'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}></TableCell>

                <TableCell
                  align="center"
                  sx={{fontSize: '10.7px'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                  {strings.zho.qty}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{fontSize: '10.7px'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.productTableRightBorder}  ${classes.cellPadding}`}>
                  {strings.zho.percent}
                </TableCell>
              </TableRow>
            </TableHead>
            {!hide ? (
              <TableBody className={`${classes.productDetailsTableBody}`}>
                {!isAllRejected ? (
                  productData?.products
                    ?.filter((product: any) => !product.isRejected)
                    ?.map((data: any, index: number) => {
                      const isLastRow =
                        index === productData?.products.length - 1;
                      return (
                        <TableRow key={index}>
                          <TableCell
                            sx={{padding: '14px !important'}}
                            className={`${classes.proDetailsCompDivider} ${
                              classes.orderProductTableData
                            }   ${classes.productTableLeftBorder} ${
                              classes.selectedProductsWidth
                            } ${classes.borderBottomLeft} ${
                              classes.cellPadding
                            } ${index === 0 ? '' : classes.borderTopLeft} ${data?.isHighlighted&&classes.errorBorder}`}>
                            {data ? data?.productName : '---'}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder} ${classes.cellPadding} ${data?.isHighlighted&&classes.errorBorder}`}>
                            {data ? data?.totalTradeQuantity : '---'}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData}  ${classes.cellPadding} ${data?.isHighlighted&&classes.errorBorder}`}>
                            {data ? data?.totalTradePercentage : '---'}
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={2}
                            className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder} ${classes.cellPadding} ${data?.isHighlighted&&classes.errorBorder}`}>
                            {data ? data?.supplyQuantity : '---'}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder}  ${classes.cellPadding} ${data?.isHighlighted&&classes.errorBorder}`}>
                            {data ? data?.supplyAvailedQty : '---'}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.proDetailsCompDivider} ${
                              classes.orderProductTableData
                            } ${classes.productTableRightBorder} ${
                              classes.borderBottomRight
                            } ${index === 0 ? '' : classes.borderTopRight}
             ${classes.cellPadding} ${data?.isHighlighted&&classes.errorBorder}`}>
                            {data ? data?.availedDiscount : '---'}
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell
                      className={classes.noProductTableBodyBorder}
                      align="center"
                      colSpan={7}>
                      There is no product !
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody className={`${classes.productDetailsTableBody}`}>
                {!isAllRejected ? (
                  orderInnerFormData?.map((data: any, index: number) => {
                    const isLastRow = index === orderInnerFormData.length - 1;
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{padding: '14px !important'}}
                          className={`${classes.proDetailsCompDivider} ${
                            classes.orderProductTableData
                          }   ${classes.productTableLeftBorder} ${
                            classes.selectedProductsWidth
                          } ${classes.borderBottomLeft} ${
                            classes.cellPadding
                          } ${index === 0 ? '' : classes.borderTopLeft}`}>
                          {data ? data?.productName : '---'}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                          {data ? data?.totalTradeQuantity : '---'}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData}  ${classes.cellPadding}`}>
                          {data ? data?.totalTradePercentage : '---'}
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={2}
                          className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                          {data ? data?.supplyQuantity : '---'}
                        </TableCell>

                        <TableCell
                          align="center"
                          className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder}  ${classes.cellPadding}`}>
                          {data ? data?.supplyAvailedQty : '---'}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.proDetailsCompDivider} ${
                            classes.orderProductTableData
                          } ${classes.productTableRightBorder} ${
                            classes.borderBottomRight
                          } ${index === 0 ? '' : classes.borderTopRight}
                           ${classes.cellPadding}`}>
                          {data ? data?.availedDiscount : '---'}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      className={classes.noProductTableBodyBorder}
                      align="center"
                      colSpan={7}>
                      There is no product !
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          {/* supply Pod */}
          {/* {!hide || !supplyPod ? null : <OrderSupplyPod />} */}
          {!hide && productData.supplyPOD !== null && (
            <OrderSupplyPod
              OpenModal={handleToggleModal}
              supplyPod={productData}
            />
          )}
          {/* supply Pod */}
          {!hide ||
            (approvalStatus === 0 && (
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  data-testid="reject-form"
                  variant="outlined"
                  size="large"
                  className={classes.paperButton}>
                  {strings.reject}
                </Button>
                <Button
                  data-testid="approve-form"
                  variant="contained"
                  className={classes.applyButton}>
                  {strings.approve}
                </Button>
              </Stack>
            ))}
          {}
        </>
      ) : (
        <Table sx={{minHeight: '188.877px'}}>
          <Box
            className={classes.loader}
            sx={[{display: 'flex', justifyContent: 'center', mt: 3}]}>
            <CircularProgress />
          </Box>
        </Table>
      )}
      {/* rejected product order */}
      {isRejectedOrder &&
        (!productLoader ? (
          <>
            <Typography
              sx={{
                fontSize: '15.7px !important',
                marginBottom: '0px !important',
                marginTop: '15px !important',
              }}
              className={`${classes.RfHeading}  `}
              variant="h6">
              {strings.zho.hoAdmin.dashboard.modalPopup.rejectedProducts}
            </Typography>
            <Table
              sx={{
                borderCollapse: 'separate',
                borderSpacing: '0px 8px',
                width: !hide ? '100%' : '60%',
              }}
              aria-label="simple table">
              <TableHead>
                <TableRow sx={{transform: 'translatey(16px)'}}>
                  <TableCell
                    sx={{background: 'white', border: '1px solid #E2E2E2'}}
                    className={`${classes.defaultText} ${classes.noBorderBottom} ${classes.borderTopLeft} `}></TableCell>
                  <TableCell
                    sx={{fontSize: '11.7px'}}
                    align="center"
                    colSpan={2}
                    className={` ${classes.toggleProDetailsTableTopHeadings} ${classes.proDetailsLeftBorder} `}>
                    {strings.zho.totalTradeDiscount}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{fontSize: '11.7px'}}
                    colSpan={2}
                    className={`${classes.toggleProDetailsTableTopHeadings} ${classes.proDetailsLeftBorder}`}>
                    {strings.zho.hoAdmin.dashboard.modalPopup.supplyQty}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{fontSize: '11.7px'}}
                    colSpan={2}
                    className={`${classes.toggleProDetailsTableTopHeadings} ${classes.proDetailsLeftBorder} ${classes.borderTopRight}`}>
                    {strings.zho.hoAdmin.dashboard.modalPopup.availedDiscount}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{transform: 'translatey(8px)'}}
                  className={`${classes.proDeatilsThead}`}>
                  <TableCell
                    sx={{
                      textTransform: 'uppercase',
                      fontSize: '10.7px',
                    }}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.productTableLeftBorder} ${classes.proDetailsHeading} ${classes.cellPadding}`}>
                    {strings.zho.selectedproducts}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{fontSize: '10.7px'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                    {strings.zho.qty}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{fontSize: '10.7px'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom}  ${classes.cellPadding}`}>
                    {strings.zho.percent}
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    sx={{fontSize: '10.7px'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}></TableCell>

                  <TableCell
                    align="center"
                    sx={{fontSize: '10.7px'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                    {strings.zho.qty}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{fontSize: '10.7px'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.productTableRightBorder}  ${classes.cellPadding}`}>
                    {strings.zho.percent}
                  </TableCell>
                </TableRow>
              </TableHead>
              {!hide ? (
                <>
                  <TableBody className={`${classes.productDetailsTableBody}`}>
                    {productData?.products
                      ?.filter((product: any) => product.isRejected)
                      ?.map((data: any, index: number) => {
                        const isLastRow =
                          index === productData?.products.length - 1;
                        return (
                          <TableRow key={index}>
                            <TableCell
                              sx={{padding: '14px !important'}}
                              className={`${classes.proDetailsCompDivider} ${
                                classes.orderProductTableData
                              }   ${classes.productTableLeftBorder} ${
                                classes.selectedProductsWidth
                              } ${classes.borderBottomLeft} ${
                                classes.cellPadding
                              } ${index === 0 ? '' : classes.borderTopLeft}`}>
                              {data ? data?.productName : '---'}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                              {data ? data?.totalTradeQuantity : '---'}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData}  ${classes.cellPadding}`}>
                              {data ? data?.totalTradePercentage : '---'}
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={2}
                              className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                              {data ? data?.supplyQuantity : '---'}
                            </TableCell>

                            <TableCell
                              align="center"
                              className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder}  ${classes.cellPadding}`}>
                              {data ? data?.supplyAvailedQty : '---'}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={`${classes.proDetailsCompDivider} ${
                                classes.orderProductTableData
                              } ${classes.productTableRightBorder} ${
                                classes.borderBottomRight
                              } ${index === 0 ? '' : classes.borderTopRight}
                         ${classes.cellPadding}`}>
                              {data ? data?.availedDiscount : '---'}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </>
              ) : (
                <TableBody className={`${classes.productDetailsTableBody}`}>
                  {orderInnerFormData?.map((data: any, index: number) => {
                    const isLastRow = index === orderInnerFormData.length - 1;
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{padding: '14px !important'}}
                          className={`${classes.proDetailsCompDivider} ${
                            classes.orderProductTableData
                          }   ${classes.productTableLeftBorder} ${
                            classes.selectedProductsWidth
                          } ${classes.borderBottomLeft} ${
                            classes.cellPadding
                          } ${index === 0 ? '' : classes.borderTopLeft}`}>
                          {data ? data?.productName : '---'}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                          {data ? data?.totalTradeQuantity : '---'}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData}  ${classes.cellPadding}`}>
                          {data ? data?.totalTradePercentage : '---'}
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={2}
                          className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                          {data ? data?.supplyQuantity : '---'}
                        </TableCell>

                        <TableCell
                          align="center"
                          className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder}  ${classes.cellPadding}`}>
                          {data ? data?.supplyAvailedQty : '---'}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.proDetailsCompDivider} ${
                            classes.orderProductTableData
                          } ${classes.productTableRightBorder} ${
                            classes.borderBottomRight
                          } ${index === 0 ? '' : classes.borderTopRight}
                     ${classes.cellPadding}`}>
                          {data ? data?.availedDiscount : '---'}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </>
        ) : (
          <Box
            className={classes.loader}
            sx={[{display: 'flex', justifyContent: 'center', mt: 3}]}>
            <CircularProgress />
          </Box>
        ))}

      {/* order Remarks */}
      {!hide &&
        statusToggleNo === 3 &&
        location.pathname === '/ho-admin' &&
        approvalData.rejectedRemarks !== null &&
        approvalData.rejectedRemarks !== '' && (
          <>
            <Typography
              sx={{
                marginBottom: '16px !important',
                marginTop: '10px',
                fontSize: '15.7px !important',
              }}
              className={`${classes.RfHeading}  `}
              variant="h6">
              {strings.moduleSpecificUtilities.remarks}
            </Typography>
            <Box
              sx={{
                marginBottom: '16px',
              }}>
              <Box
                sx={{
                  border: '1px solid #e2e2e2',
                  borderRadius: '10px',
                  width: '100%',
                  padding: '0.8rem',
                }}>
                <Box>
                  <Typography
                    display="inline-block"
                    sx={{
                      marginBottom: '0px !important',
                    }}
                    className={classes.defaultLightText}
                    variant="subtitle2">
                    {strings.zho.hoAdmin.dashboard.modalPopup.HoadminRemarks}
                  </Typography>
                  <Typography
                    display="inline-block"
                    sx={{
                      marginBottom: '0px !important',
                      marginLeft: '2rem',
                      textTransform: 'capitalize !important',
                    }}
                    className={`${classes.priDefaultFontSize} ${classes.noWrap} `}
                    variant="subtitle2">
                    {approvalData?.rejectedRemarks?.length !== ''
                      ? approvalData?.rejectedRemarks
                      : '---'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      {/* order Remarks */}

      {/* order Remarks */}
      {!hide &&
        statusToggleNo === 5 &&
        location.pathname === '/fc-admin' &&
        approvalData.rejectedRemarks !== null &&
        approvalData.rejectedRemarks !== '' && (
          <>
            <Typography
              sx={{
                marginBottom: '16px !important',
                marginTop: '10px',
                fontSize: '15.7px !important',
              }}
              className={`${classes.RfHeading}  `}
              variant="h6">
              {strings.moduleSpecificUtilities.remarks}
            </Typography>
            <Box
              sx={{
                marginBottom: '16px',
              }}>
              <Box
                sx={{
                  border: '1px solid #e2e2e2',
                  borderRadius: '10px',
                  width: '100%',
                  padding: '0.8rem',
                }}>
                <Box>
                  <Typography
                    display="inline-block"
                    sx={{
                      marginBottom: '0px !important',
                    }}
                    className={classes.defaultLightText}
                    variant="subtitle2">
                    {strings.zho.hoAdmin.dashboard.modalPopup.HoadminRemarks}
                  </Typography>
                  <Typography
                    display="inline-block"
                    sx={{
                      marginBottom: '0px !important',
                      marginLeft: '2rem',
                      textTransform: 'capitalize !important',
                    }}
                    className={`${classes.priDefaultFontSize} ${classes.noWrap} `}
                    variant="subtitle2">
                    {approvalData?.rejectedRemarks?.length !== ''
                      ? approvalData?.rejectedRemarks
                      : '---'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      {/* order Remarks */}

      {/*  POD remarks */}
      {!hide &&
        statusToggleNo === 2 &&
        tab == 1 &&
        approvalData.podRejectedRemarks !== null &&
        approvalData.podRejectedRemarks !== '' && (
          <>
            <Typography
              sx={{
                marginBottom: '16px !important',
                marginTop: '10px',
                fontSize: '15.7px !important',
              }}
              className={`${classes.RfHeading}  `}
              variant="h6">
              {strings.moduleSpecificUtilities.remarks}
            </Typography>
            <Box
              sx={{
                marginBottom: '16px',
              }}>
              <Box
                sx={{
                  border: '1px solid #e2e2e2',
                  borderRadius: '10px',
                  width: '100%',
                  padding: '0.8rem',
                }}>
                <Box>
                  <Typography
                    display="inline-block"
                    sx={{
                      marginBottom: '0px !important',
                    }}
                    className={classes.defaultLightText}
                    variant="subtitle2">
                    {
                      strings.zho.hoAdmin.dashboard.modalPopup
                        .podRejectedRemarks
                    }
                  </Typography>
                  <Typography
                    display="inline-block"
                    sx={{
                      marginBottom: '0px !important',
                      marginLeft: '2rem',
                      textTransform: 'capitalize !important',
                    }}
                    className={`${classes.priDefaultFontSize} ${classes.noWrap} `}
                    variant="subtitle2">
                    {approvalData?.podRejectedRemarks?.length !== ''
                      ? approvalData?.podRejectedRemarks
                      : '---'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      {/* 
     POD remarks */}
    </Box>
  );
};

export default OrderProductDetails;
