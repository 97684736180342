import * as React from 'react';
import {AgGridReact} from 'ag-grid-react';
import {useMemo} from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {useSelector} from 'react-redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {Box} from '@mui/system';
import {Typography} from '@mui/material';
import {renderDateCell} from '@app/screens/configuration-management/components/expense-type-setting-grid';
import '../approve-expenses/styles/gridStyle.css';
import {strings} from '@app/common/strings';

export const ApproveExpenseExceptionSummaryGrid = () => {
  const exceptionSummaryColumns: any = [
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      cellRenderer: (params: any) => {
        return renderDateCell(params?.data?.date);
      },
    },

    {field: 'expenseCategory', headerName: 'Expense Type', width: 150},

    {field: 'standard', headerName: 'Standard', width: 160},
    {field: 'claimed', headerName: 'Claimed', width: 140},
    {field: 'expenseType', headerName: 'Remarks', width: 200},
    {
      field: 'managerApprovedAmount',
      headerName: 'Manager Approved Amount',
      width: 170,
    },
    {
      headerName: 'Manager Remarks',

      field: 'managerReason',
      width: 250,
    },
  ];

  const exceptionSummaryRows = useSelector(
    approveExpenseStateSelector.getExceptionSummaryData(),
  );

  const getRowId = useMemo(() => {
    return (params: any) => {
      return params?.data?.id;
    };
  }, []);

  const columnTypes = React.useMemo(() => {
    return {
      valueColumn: {
        editable: true,
        valueParser: 'newValue',
      },
    };
  }, []);

  return (
    <>
      {exceptionSummaryRows?.length === 0 && (
        <Box
          sx={{
            border: 2,
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 10,
              mb: 10,
              mr: 10,
              ml: 10,
            }}>
            <Typography>{strings.noRecordFound}</Typography>
          </Box>
        </Box>
      )}
      {exceptionSummaryRows?.length > 0 && (
        <div className="ag-theme-alpine" style={{height: 460, width: '100%'}}>
          <AgGridReact
            rowData={exceptionSummaryRows}
            columnDefs={exceptionSummaryColumns}
            getRowId={getRowId}
            columnTypes={columnTypes}
            editType={'fullRow'}
            rowHeight={50}
            headerHeight={50}
            defaultColDef={{
              sortable: true,
              cellDataType: false,
              resizable: true,
              suppressMovable: true,
              wrapText: true,
              autoHeight: true,
            }}
          />
        </div>
      )}
    </>
  );
};
