import {PLAN_AND_MEET_PAGE_SIZE} from '@app/common/constants';
import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IPlanAndMeetInitialState {
  planAndMeetStatusList: IPlanAndMeetStatus[];
  loader: boolean;
  roles: any[];
  monthSelected: string;
  searchText: string;
  gridOptions: any;
  totalRows: number;
  totalGSPUnlockEligibleCount: number;
  totalGSPUnlockNotEligibleCount: number;
  totalWebUnlockEligibleCount: number;
  totalWebUnlockNotEligibleCount: number;
  STPEditResponse: any;
  MTPEditResponse: any;
  DCREditResponse: any;
  recommendations: any;
  filters: any;
  preferredFilters: any;
  mtpUnlockReasons: IMTPUnlockReasons[];
  unlockSTPMTPResponse: any;
  gspUnlockResponse: any;
  selectedRows: Array<IPlanAndMeetStatus>;
  isAllSelected: boolean;
  deselectedRows: Array<IPlanAndMeetStatus>;
  bulkUpdateSummaryList: any;
  bulkUpdateSummaryGridOptions: any;
  bulkGSPUnlockLoader: any;
  bulkActionSelected: any;
  webAccessStatus: any;
  webAccessResponse: any;
  isAtHierarchyLevel: boolean;
  allHierarchyRowsSelected: any;
  allHierarchyData: any;
  unfilledDCRResponse: any;
  allStaffPositionIds: Array<number>;
  realmUploadResponse: any;
}
export interface IMTPUnlockReasons {
  id: number;
  isFineApplicable: boolean;
  reason: string;
}
export interface IPlanAndMeetStatus {
  id: number;
  empId: number;
  staffPositionId: number;
  empName: string;
  roleId: number;
  divisionId: number;
  divisionName: string;
  managerName: string;
  stpStatusId: number;
  isSTPLocked: boolean;
  mtpStatusId: number;
  isMTPLocked: boolean;
  dcrStatus: boolean;
  isDCRLocked: boolean;
  isSTPEditable: boolean;
  isMTPEditable: boolean;
  isDCREditable: boolean;
  isRealmUploadEnable: boolean;
  mtpSortOrder: number;
  hasMTPRecommendation: boolean;
  isGspYearlyLocked: boolean;
  primaryHQName: string;
  mtpUnlockEndDate: string | null;
  stpUnlockEndDate: string | null;
  hierarchyList?: Array<IPlanAndMeetStatus>;
  approverRoleId: number;
}

export const planAndMeetInitialState: IPlanAndMeetInitialState = {
  planAndMeetStatusList: [],
  loader: false,
  roles: [],
  monthSelected: '',
  searchText: '',
  gridOptions: {
    pageNo: 0,
    pageSize: PLAN_AND_MEET_PAGE_SIZE,
  },
  totalRows: 0,
  totalGSPUnlockEligibleCount: 0,
  totalGSPUnlockNotEligibleCount: 0,
  totalWebUnlockEligibleCount: 0,
  totalWebUnlockNotEligibleCount: 0,
  STPEditResponse: null,
  MTPEditResponse: null,
  DCREditResponse: null,
  recommendations: null,
  filters: {
    dcrStatus: null,
    stpStatus: null,
    mtpStatus: null,
    lockUnlockStatus: null,
    zones: null,
    regions: null,
    headQuarters: null,
    divisions: null,
  },
  preferredFilters: null,
  mtpUnlockReasons: [],
  unlockSTPMTPResponse: null,
  gspUnlockResponse: null,
  selectedRows: [],
  isAllSelected: false,
  deselectedRows: [],
  bulkUpdateSummaryList: [],
  bulkUpdateSummaryGridOptions: {},
  bulkGSPUnlockLoader: null,
  bulkActionSelected: null,
  webAccessStatus: null,
  webAccessResponse: null,
  allHierarchyData: [],
  allHierarchyRowsSelected: [],
  isAtHierarchyLevel: false,
  unfilledDCRResponse: null,
  allStaffPositionIds: [],
  realmUploadResponse: null,
};

export const fetchPlanAndMeetListCreator = createAction(
  'PLAN_AND_MEET_STATUS_HANDLER/FETCH_PLAN_AND_MEET_LIST',
);
export const fetchPlanAndMeetListCreatorTypeName =
  fetchPlanAndMeetListCreator().type;

export const updateMTPCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/UPDATE_MTP_STATUS',
);
export const updateMTPTypeName = updateMTPCreator({}).type;

export const editSTPStatusCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/EDIT_STP_STATUS',
);
export const editSTPStatusCreatorTypeName = editSTPStatusCreator({}).type;

export const fetchMTPRecommendationsCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/FETCH_RECOMMENDATIONS',
);
export const fetchMTPRecommendationsCreatorTypeName =
  fetchMTPRecommendationsCreator({}).type;

export const fetchHqsCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/FETCH_HQS',
);
export const fetchHqsCreatorTypeName = fetchHqsCreator(null).type;
export const filterBasedDownloadExcelCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/DOWNLOAD_EXCEL',
);
export const filterBasedDownloadExcelCreatorTypeName =
  filterBasedDownloadExcelCreator({}).type;

export const fetchPreferredFiltersCreator = createAction(
  'PLAN_AND_MEET_STATUS_HANDLER/FETCH_PREFERRED_FILTERS',
);
export const fetchPreferredFiltersCreatorTypeName =
  fetchPreferredFiltersCreator().type;

export const fetchMTPUnlockReasonsCreator = createAction(
  'PLAN_AND_MEET_STATUS_HANDLER/FETCH_MTP_UNLOCK_REASONS',
);
export const fetchMTPUnlockReasonsCreatorTypeName =
  fetchMTPUnlockReasonsCreator().type;

export const unlockSTPMTPCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/UNLOCK_STP_MTP',
);
export const unlockSTPMTPCreatorTypeName = unlockSTPMTPCreator(null).type;
export const enableDCRCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/ENABLE_DCR',
);
export const enableDCRCreatorTypeName = enableDCRCreator(null).type;

export const fetchHierarchyCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/HIERARCHY',
);
export const fetchHierarchyCreatorTypeName = fetchHierarchyCreator(null).type;

export const fetchAllHierarchyCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/ALL_HIERARCHY',
);
export const fetchAllHierarchyCreatorTypeName =
  fetchAllHierarchyCreator(null).type;

export const HierarchyBasedDownloadExcelCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/DOWNLOAD_EXCEL_HIERARCHY_BASED',
);
export const unlockGSPCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/UNLOCK_GSP',
);
export const unlockGSPCreatorTypeName = unlockGSPCreator(null).type;
export const HierarchyBasedDownloadExcelCreatorTypeName =
  HierarchyBasedDownloadExcelCreator({}).type;

export const bulkGSPUpdateCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/BULK_GSP_UPDATE',
);
export const bulkGSPUpdateCreatorTypeName = bulkGSPUpdateCreator({}).type;

export const webAccessUnlockCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/WEB_ACCESS_UNLOCK',
);
export const webAccessUnlockCreatorTypeName = webAccessUnlockCreator({}).type;

export const webAccessStatusCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/WEB_ACCESS_STATUS',
);
export const webAccessStatusCreatorTypeName = webAccessStatusCreator({}).type;

export const stpBulkUpdateCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/BULK_STP_UPDATE_STATUS',
);
export const stpBulkUpdateCreatorTypeName = stpBulkUpdateCreator({}).type;

export const mtpBulkUpdateCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/BULK_MTP_UPDATE_STATUS',
);
export const mtpBulkUpdateCreatorTypeName = mtpBulkUpdateCreator({}).type;

export const dcrBulkUpdateCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/BULK_DCR_UPDATE_STATUS',
);
export const dcrBulkUpdateCreatorTypeName = dcrBulkUpdateCreator({}).type;

export const enableUnfilledDCRCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/ENABLE_UNFILLED_DCR',
);
export const enableUnfilledDCRCreatorTypeName = enableUnfilledDCRCreator(
  {},
).type;

export const stpBulkUnlockCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/BULK_STP_UNLOCK_STATUS',
);
export const stpBulkUnlockCreatorTypeName = stpBulkUnlockCreator({}).type;

export const mtpBulkUnlockCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/BULK_MTP_UNLOCK_STATUS',
);
export const mtpBulkUnlockCreatorTypeName = mtpBulkUnlockCreator({}).type;

export const enableRealmUploadCreator = createAction<any>(
  'PLAN_AND_MEET_STATUS_HANDLER/ENABLE_REALM_UPLOAD',
);
export const enableRealmUploadCreatorTypeName = enableRealmUploadCreator(
  {},
).type;

const planAndMeetStatusStateHandler = createSlice({
  name: 'PLAN_AND_MEET_STATUS_HANDLER',
  initialState: planAndMeetInitialState,
  reducers: {
    setPlanAndMeetStatusList: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        planAndMeetStatusList: payload,
      };
    },
    setPlanAndMeetLoader: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        loader: payload,
      };
    },
    setPlanAndMeetSelectedRoles: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        roles: payload,
      };
    },
    setPlanAndMeetSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action.payload,
      };
    },
    setPlanAndMeetMonthSelected: (prevState, action) => {
      return {
        ...prevState,
        monthSelected: action.payload,
      };
    },
    setPlanAndMeetGridOptions: (prevState, action) => {
      return {
        ...prevState,
        gridOptions: action.payload,
      };
    },
    setTotalRowsInGrid: (prevState, action) => {
      return {
        ...prevState,
        totalRows: action.payload,
      };
    },
    setTotalGSPUnlockEligibleCount: (prevState, action) => {
      return {
        ...prevState,
        totalGSPUnlockEligibleCount: action.payload,
      };
    },
    setTotalGSPUnlockNotEligibleCount: (prevState, action) => {
      return {
        ...prevState,
        totalGSPUnlockNotEligibleCount: action.payload,
      };
    },
    setTotalWebUnlockEligibleCount: (prevState, action) => {
      return {
        ...prevState,
        totalWebUnlockEligibleCount: action.payload,
      };
    },
    setTotalWebUnlockNotEligibleCount: (prevState, action) => {
      return {
        ...prevState,
        totalWebUnlockNotEligibleCount: action.payload,
      };
    },
    resetPlanAndMeetState: () => {
      return {
        ...planAndMeetInitialState,
      };
    },
    setSTPEditResponse: (prevState, action) => {
      return {
        ...prevState,
        STPEditResponse: action.payload,
      };
    },
    setMTPEditResponse: (prevState, action) => {
      return {
        ...prevState,
        MTPEditResponse: action.payload,
      };
    },
    setGSPUnlockResponse: (prevState, action) => {
      return {
        ...prevState,
        gspUnlockResponse: action.payload,
      };
    },
    setWebAccessResponse: (prevState, action) => {
      return {
        ...prevState,
        webAccessResponse: action.payload,
      };
    },
    setDCREditResponse: (prevState, action) => {
      return {
        ...prevState,
        DCREditResponse: action.payload,
      };
    },
    setRecommendations: (prevState, action) => {
      return {
        ...prevState,
        recommendations: action.payload,
      };
    },
    setFilters: (prevState, action) => {
      return {
        ...prevState,
        filters: action.payload,
      };
    },
    setPreferredFilters: (prevState, action) => {
      return {
        ...prevState,
        preferredFilters: action.payload,
      };
    },
    setMTPUnlockReasons: (prevState, action) => {
      return {
        ...prevState,
        mtpUnlockReasons: action.payload,
      };
    },
    setSTPMTPUnlockResponse: (prevState, action) => {
      return {
        ...prevState,
        unlockSTPMTPResponse: action.payload,
      };
    },
    setSelectedRows: (prevState, action) => {
      return {
        ...prevState,
        selectedRows: action.payload,
      };
    },
    setIsAllSelected: (prevState, action) => {
      return {
        ...prevState,
        isAllSelected: action.payload,
      };
    },
    setDeselectedRow: (prevState, action) => {
      return {
        ...prevState,
        deselectedRows: action.payload,
      };
    },
    setBulkUpdateSummaryList: (prevState, action) => {
      return {
        ...prevState,
        bulkUpdateSummaryList: action.payload,
      };
    },
    setBulkUpdateSummaryGridOptions: (prevState, action) => {
      return {
        ...prevState,
        bulkUpdateSummaryGridOptions: action.payload,
      };
    },
    setBulkGSPUnlockLoader: (prevState, action) => {
      return {
        ...prevState,
        bulkGSPUnlockLoader: action.payload,
      };
    },
    setBulkActionSelected: (prevState, action) => {
      return {
        ...prevState,
        bulkActionSelected: action.payload,
      };
    },
    setWebAccessStatus: (prevState, action) => {
      return {
        ...prevState,
        webAccessStatus: action.payload,
      };
    },
    setAllHierarchyData: (prevState, action) => {
      return {
        ...prevState,
        allHierarchyData: action.payload,
      };
    },
    setAllHierarchyRowsSelected: (prevState, action) => {
      return {
        ...prevState,
        allHierarchyRowsSelected: action.payload,
      };
    },
    setIsAtHierarchyLevel: (prevState, action) => {
      return {
        ...prevState,
        isAtHierarchyLevel: action.payload,
      };
    },
    setUnfilledDCRResponse: (prevState, action) => {
      return {
        ...prevState,
        unfilledDCRResponse: action.payload,
      };
    },
    setAllStaffPositionIds: (prevState, action) => {
      return {
        ...prevState,
        allStaffPositionIds: action.payload,
      };
    },
    setRealmUploadResponse: (prevState, action) => {
      return {
        ...prevState,
        realmUploadResponse: action.payload,
      };
    },
  },
});

export const planAndMeetStatusStateActions =
  planAndMeetStatusStateHandler.actions;
export const planAndMeetStatusStateReducer =
  planAndMeetStatusStateHandler.reducer;
