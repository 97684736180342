import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import Stack from '@mui/material/Stack';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import React, {useCallback, useEffect, useState} from 'react';
import {DropdownStyle} from '@app/components/widgets/TextFieldStyle';
import {expenseTAMasterStateActions} from '@app/screens/master-data-management/pages/expense-ta-master/redux';
import {AddExpenseValidationTAMasterCreator} from '@app/screens/master-data-management/pages/expense-ta-master/redux/slice';
import {expenseTAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-ta-master/redux/selectors';
import {AntSwitch} from '@app/components/widgets/AntSwitch/AntSwitch';
import {expenseDAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-da-master/redux/selectors';
import {DatePickerStyle} from '@app/components/widgets/datepickerStyle';
import {muiltipleSelect} from '@app/components/widgets/placeholder/multiselect-placeholder';
import {
  expenseDAMasterStateActions,
  fetchExpenseDAMasterDesignationByDivisionCreator,
  fetchHqByEdividionDesginationDAMasterCreator,
} from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

import {popUpStyle} from '@app/components/widgets/popupCss/popUpStyles';
import {DialogProps} from '@app/App';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useStyles} from '@app/screens/Styles/style';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {strings} from '@app/common/strings';

export const AddExpenseTAMaster = () => {
  const dispatch = useDispatch();

  const [expenseTAMasterStatus, setExpenseTAMasterStatus] =
    React.useState(true);
  const [expenseTAMasterDivision, setExpenseTAMasterDivision] = useState<any>(
    [],
  );
  const [expenseTAMasterDesignation, setExpenseTAMasterDesignation] =
    useState<any>([]);
  const [expenseTAMasterHq, setExpenseTAMasterHq] = useState<any>([]);
  const [expenseTAMasterTaPerKms, setExpenseTAMasterTaPerKms] =
    useState<string>('');
  const [expenseTAMasterStartDates, setExpenseTAMasterStartDates] =
    useState<Date | null>(null);
  const [expenseTAMasterEndDates, setExpenseTAMasterEndDates] =
    useState<Date | null>(null);
  const [expenseTAMasterValidation, setExpenseTAMasterValidation] =
    React.useState(true);
  const [expenseTAMasterMaxDate, setExpenseTAMasterMaxDate] =
    useState<string>('');
  const [open, setOpen] = React.useState(false);
  const [openDesignation, setOpenDesignation] = React.useState(false);
  const [reset, setReset] = React.useState(0);
  const classes = useStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const expenseTAMasterDesignations = useSelector(
    expenseDAMasterStateSelector.getDesignationArr(),
  );
  const expenseTAMasterHQDropdown = useSelector(
    expenseDAMasterStateSelector.getHqByDivisionDesignation(),
  );
  const addExpenseTAMasterPopupVisible = useSelector(
    expenseTAMasterStateSelector.getaddexpenseTAMasterPopupVisible(),
  );
  const expenseTAMasterDivisionDropDown = useSelector(
    approveExpenseStateSelector?.getDivisionData(),
  );
  const expenseTAMasterHandleChangeHQ = useCallback(
    function (_event: any, value: any) {
      setExpenseTAMasterHq(value);
    },
    [expenseTAMasterHq],
  );

  const expenseTAMasterHandleChangeDivision = useCallback(
    function (_event: any, value: any) {
      setExpenseTAMasterDivision(value);
    },
    [expenseTAMasterDivision],
  );

  const expenseTAMasterHandleChangeDesignation = useCallback(
    function (_event: any, value: any) {
      setExpenseTAMasterDesignation(value);
    },
    [expenseTAMasterDesignation],
  );

  const handleChange = useCallback(
    (event: {
      target: {checked: boolean | ((prevState: boolean) => boolean)};
    }) => {
      setExpenseTAMasterStatus(event.target.checked);
    },
    [expenseTAMasterStatus],
  );

  const handleChangetaStartDate = useCallback(
    (event: any) => {
      setExpenseTAMasterStartDates(event);
      setExpenseTAMasterMaxDate(
        new Date(
          new Date(event)?.getFullYear() + 1,
          new Date(event)?.getMonth(),
          new Date(event)?.getDate(),
        ).toString(),
      );
    },
    [expenseTAMasterStartDates],
  );
  const handleChangetaEndDate = useCallback(
    (event: any) => {
      setExpenseTAMasterEndDates(event);
    },
    [expenseTAMasterEndDates],
  );
  const onResetClick = useCallback(() => {
    setExpenseTAMasterDivision([]);
    setExpenseTAMasterDesignation([]);
    setExpenseTAMasterHq([]);
    setExpenseTAMasterEndDates(null);
    setExpenseTAMasterStartDates(null);
    setExpenseTAMasterStatus(true);
    setExpenseTAMasterTaPerKms('');
    setExpenseTAMasterValidation(true);
    setReset(reset + 1);
    dispatch(expenseDAMasterStateActions.setDesignationArr([]));
    dispatch(expenseDAMasterStateActions.setHqByDivisionDesignation([]));
    dispatch(
      expenseTAMasterStateActions.setExpenseTAMasterNewDataValidation([]),
    );
  }, []);

  const validationTAMaster = () => {
    if (
      expenseTAMasterDivision?.length > 0 &&
      expenseTAMasterDesignation?.length > 0 &&
      expenseTAMasterHq?.length > 0 &&
      expenseTAMasterTaPerKms &&
      expenseTAMasterStartDates
    ) {
      setExpenseTAMasterValidation(false);
    } else {
      setExpenseTAMasterValidation(true);
    }
  };
  const onSaveClick = useCallback(() => {
    const expenseTAMaster = {
      divisionIds: setvalues(expenseTAMasterDivision, 'value'),
      designationIds: setvalues(expenseTAMasterDesignation, 'designationId'),
      hqIds: setvalues(expenseTAMasterHq, 'headQuaterId'),
      taPerKM: expenseTAMasterTaPerKms,
      startDate: expenseTAMasterStartDates,
      endDate: expenseTAMasterEndDates,
      isActive: expenseTAMasterStatus,
    };
    dispatch(AddExpenseValidationTAMasterCreator(expenseTAMaster));
  }, [
    expenseTAMasterDivision,
    expenseTAMasterDesignation,
    expenseTAMasterHq,
    expenseTAMasterTaPerKms,
    expenseTAMasterEndDates,
    expenseTAMasterStartDates,
    expenseTAMasterStatus,
  ]);
  const handleChangetaPerKm = useCallback(
    (event: any) => {
      const {value} = event.target;
      const re = /^\d*(\.\d{0,2})?$/; //  accepts only decimal values with 2 places
      if (!isNaN(value)) {
        if (value === '' || re.test(value)) {
          setExpenseTAMasterTaPerKms(value);
        }
      }
    },
    [expenseTAMasterTaPerKms],
  );
  const trigger = () => {
    handleChangetaStartDate(new Date());
    handleChangetaEndDate(new Date());
    closeTAMasterAdd('backdrop');
    OncloseTAMasterAdd();
    OncloseDivisionTAMasterAdd();
    OnOpeneDivisionTAMasterAdd();
    OncloseDesiTAMasterAdd();
    OnOpeneDesiTAMasterAdd();
  };

  const closeTAMasterAdd = useCallback((reason: string) => {
    if (reason && reason === 'backdropClick') return;
    dispatch(
      expenseTAMasterStateActions.setaddExpenseTAMasterPopupVisible(false),
    );
    onResetClick();
  }, []);
  const OncloseTAMasterAdd = useCallback(() => {
    dispatch(
      expenseTAMasterStateActions.setaddExpenseTAMasterPopupVisible(false),
    );
    onResetClick();
  }, []);

  const OncloseDivisionTAMasterAdd = useCallback(() => {
    setOpen(false);
  }, []);
  const setvalues = (value: any, id: any) =>
    value?.map((val: any) => val?.['' + id + '']);
  const OnOpeneDivisionTAMasterAdd = useCallback(() => setOpen(true), []);
  const OncloseDesiTAMasterAdd = useCallback(() => {
    setOpenDesignation(false);
  }, []);
  const OnOpeneDesiTAMasterAdd = useCallback(
    () => setOpenDesignation(true),
    [],
  );
  const preventDefaultHandleTAMasterAdd = useCallback(
    e => e.preventDefault(),
    [],
  );
  const renderInputTAMasterAdd = useCallback(function (params: any) {
    return (
      <TextField
        size="small"
        {...params}
        sx={DatePickerStyle.datepick}
        helperText={null}
        onKeyDown={preventDefaultHandleTAMasterAdd}
        style={{maxwidth: 270, marginLeft: 5}}
      />
    );
  }, []);
  const renderInputdiv = useCallback(function (params1: any) {
    return (
      <TextField
        {...params1}
        size="small"
        placeholder={strings.placeholder.division}
      />
    );
  }, []);
  const renderInputdes = useCallback(function (params1: any) {
    return (
      <TextField
        {...params1}
        size="small"
        placeholder={strings.placeholder.designation}
      />
    );
  }, []);
  const renderInputhq = useCallback(function (params1: any) {
    return (
      <TextField
        {...params1}
        size="small"
        placeholder={strings.placeholder.hq}
      />
    );
  }, []);
  useEffect(() => {
    validationTAMaster();
  }, [
    expenseTAMasterDivision,
    expenseTAMasterDesignation,
    expenseTAMasterHq,
    expenseTAMasterTaPerKms,
    expenseTAMasterStartDates,
  ]);
  useEffect(() => {
    if (expenseTAMasterDivision !== undefined) {
      setExpenseTAMasterDesignation([]);
      dispatch(
        fetchExpenseDAMasterDesignationByDivisionCreator(
          muiltipleSelect(setvalues(expenseTAMasterDivision, 'value')),
        ),
      );
    }
  }, [expenseTAMasterDivision]);
  useEffect(() => {
    let ids = {
      divisionIds: setvalues(expenseTAMasterDivision, 'value'),
      designationIds: setvalues(expenseTAMasterDesignation, 'designationId'),
    };
    if (ids.divisionIds.length !== 0 && ids.designationIds.length !== 0) {
      setExpenseTAMasterHq([]);
      dispatch(fetchHqByEdividionDesginationDAMasterCreator(ids));
    }
  }, [expenseTAMasterDesignation]);
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={addExpenseTAMasterPopupVisible}
        onClose={closeTAMasterAdd}>
        <DialogTitle>
          <button
            data-testid="triggeringButton"
            className={'hiddenButton'}
            onClick={trigger}
          />
          <Box className={classes.justifyContentbtflex}>
            <Box>
              <Typography sx={popUpStyle.addcss}>Add</Typography>
              <Typography sx={popUpStyle.headingcss}>
                {strings.masterDataManagement.expenseTaMaster}
              </Typography>
            </Box>
            <Box>
              <Button
                data-testid="setaddExpenseTAMasterPopupVisible"
                onClick={OncloseTAMasterAdd}>
                <ClearOutlinedIcon sx={popUpStyle.closeIcon} />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <div style={popUpStyle.dialogContent}>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {strings.division}
              </Typography>

              <Autocomplete
                multiple
                key={reset}
                id="checkboxes-tags-demo"
                options={expenseTAMasterDivisionDropDown}
                limitTags={2}
                disableCloseOnSelect
                size="small"
                open={open}
                value={expenseTAMasterDivision}
                data-testid="expenseTAMasterHandleChangeDivision"
                onClose={OncloseDivisionTAMasterAdd}
                onOpen={OnOpeneDivisionTAMasterAdd}
                onChange={expenseTAMasterHandleChangeDivision}
                getOptionLabel={(option: any) => option.label}
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={renderInputdiv}
              />
            </FormControl>

            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {strings.designation}
              </Typography>

              <Autocomplete
                multiple
                key={reset}
                id="checkboxes-tags-demo1"
                options={expenseTAMasterDesignations}
                data-testid="expenseTAMasterHandleChangeDesignation"
                limitTags={2}
                disableCloseOnSelect
                value={expenseTAMasterDesignation}
                size="small"
                onChange={expenseTAMasterHandleChangeDesignation}
                open={openDesignation}
                onClose={OncloseDesiTAMasterAdd}
                onOpen={OnOpeneDesiTAMasterAdd}
                getOptionLabel={(option: any) => option?.designationName}
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option?.designationName}
                  </li>
                )}
                renderInput={renderInputdes}
              />
            </FormControl>
          </div>
          <div style={popUpStyle.twocolumnlayout}>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>{strings.hq}</Typography>
              <Autocomplete
                multiple
                key={reset}
                id="checkboxes-tags-demo2"
                options={expenseTAMasterHQDropdown}
                limitTags={1}
                value={expenseTAMasterHq}
                disableCloseOnSelect
                size="small"
                onChange={expenseTAMasterHandleChangeHQ}
                getOptionLabel={(option: any) => option?.headQuaterName}
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option?.headQuaterName}
                  </li>
                )}
                renderInput={renderInputhq}
              />
            </FormControl>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {strings.taPerKm}
              </Typography>
              <TextField
                size="small"
                id="fullWidth"
                InputProps={{
                  inputProps: {
                    inputMode: 'numeric',
                    pattern: '[0-9]+$',
                    'data-testid': 'handleChangetaPerKm',
                  },
                }}
                sx={DropdownStyle.textroot}
                value={expenseTAMasterTaPerKms}
                onChange={handleChangetaPerKm}
              />
            </FormControl>
          </div>
          <div>
            <div style={popUpStyle.twocolumnlayout}>
              <FormControl fullWidth sx={popUpStyle.marginBottom15}>
                <Typography sx={popUpStyle.labelSizing}>
                  {strings.effectiveForm}
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    mask="__/__/____"
                    inputFormat="DD/MM/YYYY"
                    label={strings.label.startDate}
                    minDate={new Date(new Date().setDate(1))}
                    maxDate={expenseTAMasterEndDates}
                    value={expenseTAMasterStartDates}
                    onChange={handleChangetaStartDate}
                    PopperProps={DialogProps}
                    renderInput={renderInputTAMasterAdd}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl
                fullWidth
                sx={[popUpStyle.marginBottom15, {paddingLeft: '5px'}]}>
                <Typography sx={popUpStyle.labelSizing}>
                  {strings.validUpto}
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    mask="__/__/____"
                    disabled={true}
                    maxDate={new Date(expenseTAMasterMaxDate)}
                    minDate={expenseTAMasterStartDates}
                    inputFormat="DD/MM/YYYY"
                    value={expenseTAMasterEndDates}
                    onChange={handleChangetaEndDate}
                    PopperProps={DialogProps}
                    label={strings.label.endDate}
                    renderInput={renderInputTAMasterAdd}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            {!expenseTAMasterStatus && (
              <div>
                <FormGroup>
                  <Typography sx={popUpStyle.labelSizing}>
                    {strings.status}
                  </Typography>
                  <Stack
                    direction="row"
                    paddingLeft={1}
                    spacing={2}
                    alignItems="center">
                    <Typography sx={popUpStyle.labelSizingAntSwitch}>
                      {strings.inActive}
                    </Typography>
                    <AntSwitch
                      checked={expenseTAMasterStatus}
                      onChange={handleChange}
                      inputProps={{'aria-label': 'ant design'}}
                    />
                    <Typography sx={popUpStyle.labelSizingAntSwitch}>
                      {strings.active}
                    </Typography>
                  </Stack>
                </FormGroup>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Box className={classes.justifyContentcenterflex}>
            <Button
              variant="contained"
              sx={popUpStyle.resetButton}
              onClick={onResetClick}
              data-testid="onResetClick">
              {strings.reset}
            </Button>

            <Button
              variant="contained"
              sx={popUpStyle.saveButton}
              onClick={onSaveClick}
              disabled={expenseTAMasterValidation}
              data-testid="onSaveClick">
              {strings.save}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};
