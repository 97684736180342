import theme from '@app/themes';
import {gridStyles} from '@app/screens/zho/components/widgets/DataGrid/styles';

export const ErrorTableStyles = {
  wrapper: {
    maxHeight: 'calc(100vh - 213px)',
    overflowY: 'auto',
  },

  padding5: {
    padding: '5px',
  },
  cellContent: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiIconButton-root ': {
      background: 'transparent',
      gap: 4,
    },
    '& .MuiSvgIcon-root': {
      width: 15,
      color: '#332b77',
    },
  },

  tableCell: {
    border: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12.7,
    color: theme.colors.grey[200],
  },
  tableHeader: {
    fontWeight: 600,
    color: theme.colors.blue[1400],
  },
  actionCell: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  centeredCell: {
    textAlign: 'center',
  },
  cellInput: {
    color: theme.colors.blue[1400],
    borderRadius: '6px',
    backgroundColor: '#322B7C08',
    borderColor: theme.colors.primary,
    width: '80px',
    fontSize: '12.7px',
    '& .MuiInputBase-input': {
      padding: '16px 10px !important',
    },
  },
  disabledCellInput: {
    backgroundColor: theme.colors.grey[1300],
  },
  cellPropInput: {
    width: '80px',
    height: '35px',
    fontSize: '12.7px',
  },
  actionButton: {
    width: 150,
    height: 40,
    borderRadius: 1,
    textTransform: 'none',
  },
  downloadTemplateText: {
    color: '#3273F2',
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '12.7px',
  },
  modalSection: {
    padding: '20px',
  },
  modalHeader: {
    borderBottom: '1px solid #E3E3E3',
    color: theme.colors.grey[200],
    fontSize: 15.7,
  },
  modalFooter: {
    borderTop: '1px solid #E3E3E3',
    display: 'flex',
    gap: 1,
  },
  supportedFormatText: {
    color: '#9D9D9D',
    fontWeight: 400,
    fontSize: '12.7px',
    paddingBottom: '10px',
    paddingTop: '10px',
    borderBottom: '1px solid #E3E3E3',
  },
  dragFileText: {
    display: 'flex',
    gap: 1,
  },

  fileDetailsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px',
    backgroundColor: '#EAEAEA8C',
    borderRadius: '8px',
    marginTop: '10px',
  },
  fileDetails: {
    borderLeft: '0.46px solid #B8B8B8',
    paddingLeft: '5px',
    marginLeft: '5px',
  },
  fileName: {
    fontWeight: 500,
    color: '#484848',
    fontSize: '12.7px',
    lineHeight: '15px',
  },
  fileSize: {
    fontWeight: 400,
    color: '#707070',
    fontSize: '12.7px',
    lineHeight: '15px',
  },
  fileUploadDate: {
    fontSize: '12.7px',
    fontWeight: 400,
    color: theme.colors.primary,
    marginTop: '10px',
  },
  listingInput: {
    paddingLeft: '0px !important',
    textAlign: 'center',
    '& .MuiInputBase-input': {
      padding: '0px !important',
      textAlign: 'center',
    },
  },
  listingTableHeader: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',

    fontFamily: 'Poppins',
    fontSize: 12.7,
    fontStyle: 'normal',
    lineHeight: '21.3px',
    letterSpacing: 0,
    textAlign: 'left',
    color: '#322b7c',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    outline: 'none !important',
  },

  errorTable: {
    border: 'none',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '12.7px',
      color: theme.colors.primary,
      fontWeight: 600,
    },
    '& .MuiDataGrid-cellContent': {
      fontSize: '12.7px !important',
      color: theme.colors.grey[200],
    },
    ...gridStyles,
  },
  selectedProductName: {
    padding: '10px',
    borderRadius: '4px',
    boxShadow: '0px 2px 17.3px 0px #0000000F',
  },
  requiredHeader: {
    '::after': {
      color: '#da3131',
      content: '"*"',
    },
  },
};
